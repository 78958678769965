.container-loginComp {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  width: 100% !important;
  min-height: 100vh !important;
  padding: 0 !important;
  position: relative !important;
  z-index: 999 !important;
  font-family: "Raleway", sans-serif !important;
  color: #ffffff !important;
  /* overflow-x: hidden !important; */
  background-color: #050d15 !important;
}

.container p,
.container h1,
.container h2,
.container span,
.container button {
  font-family: "Raleway", sans-serif !important;
}

.nav-container {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.nav-container::before {
  position: absolute;
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #272e3450;
  backdrop-filter: blur(10px);
}

.nav-wrapper {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  position: relative
}

.logo2 {
  width: 250px;
}

.auth-wrapper {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
}

.setup-wrapper{
  margin: 0;
  /* position: relative; */
}

.setup-wrapper span{
  display: inline-block;
}

.setupText {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 10px;
}

.setupInfoTextWrapper {
  position: absolute;
  width: 100%;
  max-width: 460px;
  top: 100px;
  left: 10px;
  border-radius: 20px;
  border: 1px solid #ffffff05;
  background-color: #050D15;
  padding: 14px 24px;
  font-weight: 200;
  display: flex;
  align-self: flex-end;
  justify-content: 'center';
  align-items: 'center';
  transition: 0.5s all ease-in-out;
}

.setupInfoText {
  font-weight: 400;
  line-height: 25px;
  width: 100%;
}

.setupLine {
  height: 15px;
  border: 0.5px solid white;
  margin-right: 5px;
}

.signupText {
  margin: 0;
}

.signUpBtn-gradient-border {
  /* Set button background color */
  background-color: transparent;

  /* Apply linear gradient as border */
  border: 2px solid;
  border-image: linear-gradient(to right, rgba(98, 0, 234, 1), rgba(38, 197, 243, 1)) 1;
}

.btnWrapper {
  width: 120px;
  height: 34px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #6200ea, #26c5f3); 
  padding: 1px; 
}

.outlinedBtn {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #101821;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: 0;
}

.content-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width:1000px;
  align-self: center;
  padding: 180px 15px 80px;
}

.pgIcon {
  margin-bottom: -16px;
}

.form-content {
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading-container {
  margin: 50px 0 30px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.heading-wrapper {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.back-btn {
  padding: 5px;
  border-radius: 50%;
  background-color: #ffffff60;
  border: none;
  position: absolute;
  /* left: -13px; */
}

.back-btn-icon {
  font-size: 1rem;
}

.heading-loginComp {
  font-weight: 900 !important;
  font-family: "Raleway", sans-serif !important;
  line-height: 40px !important;
  font-size: 1.6rem !important;
}

.heading-auth {
  text-align: center;
  align-self: center;
  width: 100%;
}

.info-text {
  font-size: 1.1rem !important;
  text-align: center;
  font-weight: 500 !important;
}

.form-wrapper {
  width: 100%;
  margin-top:20px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 515px;
  background-image: linear-gradient(to bottom, #ffffff10, #050d1520);
  padding: 50px 10px;
  border-radius: 20px;
  backdrop-filter: blur(8px);
}

.submitBtn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  width: 100%;
  max-width: 413px;
  font-weight: 800;
  padding: 14px 24px;
  color: #ffffff;
  font-size: 16px;
  border: 0;
  border-radius: 20px;
  margin-top: 20px;
  background: linear-gradient(to right, #6201ea, #26c5f3);
  transition: backgroundColor 0.3s ease-in-out, background 0.3s ease-in-out;
}

.leaveButton {
  width: 120px;
  max-width: 120px;
  font-weight: 800;
  padding: 14px 24px;
  color: #CBD5E1;
  font-size: 16px;
  border: 0;
  border-radius: 20px;
  margin-top: 20px;
  background: #334155;
  transition: backgroundColor 0.3s ease-in-out, background 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaveButton img {
  width: 20px;
  height: 20px;
}

.NextButton {
  width: 273px;
  max-width: 273px;
  font-weight: 800;
  padding: 14px 24px;
  color: #FFFFFF;
  font-size: 16px;
  border: 0;
  border-radius: 20px;
  margin-top: 20px;
  background: #72EFDD;
  transition: backgroundColor 0.3s ease-in-out, background 0.3s ease-in-out;
  cursor: pointer;
}
.zindexBtn {
z-index: 2;
}

.signupbtn-wrapper {
  width: 120px;
  height: 34px;
  border-radius: 20px;
  display: inline-block;
  margin: 20px 0;
  position: relative;
}

.signupbtn-wrapper::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 34px;
  inset: 0;
  padding: 1px;
  border-radius: 20px;
  background: linear-gradient(to right, #6200ea, #26c5f3);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
          mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}

.popup-error {
  width: 80%;
  max-width: 450px;
  position: absolute;
  top: 230px;
  right: 35px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  border-radius: 20px;
  padding: 8px 16px;
  border-left-width: 4px;
  border-left-style: solid;
  z-index: 2;
}

.popupError-text {
  transform: translateX(20px);
  font-weight: 300 !important;
  font-size: 0.9rem !important;
  width: 95%;
  margin-left: -10px !important;
}

.nextBtnWrapper {
  width: 100%;
  display: flex;
  margin-top:25px;
  justify-content: center;
  align-items: center;
  gap: 10px
}

.buttonBox{
  position: relative;
}

.tooltipBox {
  display: flex;
    justify-content: space-between;
    border: 1px solid #FFFFFF0D;
    padding: 15px 10px;
    width: 280px;
    align-items: start;
    position: absolute;
    right: 0;
    bottom: 25px;
    background-color: #33415580;
    z-index: 1;
    border-radius: 20px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
}

.tooltipBox img {
  cursor: pointer;
}

.tooltipBox p {
  margin-left: 10px;
}

.disableBtn :hover{
  display: block;
}
/* Media Queries */
@media screen and (max-width: 500px) {
  .setupText {
    /* margin-top: 10px; */
    margin-left: 5px;
    /* font-size: 1rem;
    font-weight: 500;
    margin-right: 10px; */
  }
  .nav-wrapper {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 360px) {
  .navWrapper, .nav-wrapper {
    padding: 20px;
  }
  .popup-error {
    width: 80%;
  }
  .setup-wrapper{
    margin: 10px 10px;
  }
}

@media screen and (min-width: 400px) {
  .navWrapper, .nav-wrapper {
    padding: 20px 30px;
  }
}

@media screen and (min-width: 500px) {
  .popup-error {
    width: 90%;
  }
  .nav-wrapper-setup {
    flex-direction: row;
  }
  .setupInfoTextWrapper {
    left: auto;
    right: 20px;
  }
}

@media screen and (min-width: 600px) {
  .form-content {
    width: 100%;
  }
  .form-wrapper {
    margin-top:20px;
    padding: 50px 10px;
  }
  .back-btn {
    padding: 5px;
    left: 20px;
  }
  .back-btn-icon {
    font-size: 1.25rem;
  }
  .heading {
    font-size: 2.145rem !important;
  }
  .popup-error {
    width: 100%;
    /* right: 40px; */
  }
}

@media screen and (min-width: 685px) {
  .nav-wrapper {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
  .auth-wrapper {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .back-btn {
    left: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .navWrapper, .nav-wrapper {
    padding: 20px 40px;
  }
  .logo {
    width: auto;
  }
  .back-btn {
    /* left: 150px;  */
  }
}

@media screen and (min-width: 1100px) {
  .back-btn {
    /* left: 200px;  */
  }
} 

@media screen and (min-width: 1800px) {
  .back-btn {
    /* left: 250px;  */
  }
} 
