.student-detail {
    background: rgb(18,28,40);
    width: 75%;
    margin: 0 auto 1%;
    border-radius: 20px;
    border: 1px solid transparent;
    padding: 2%;
    }

.time-line-circle {
    width: 24;
        height: 24;
        border-radius: 20px;
        border-color: red;


}