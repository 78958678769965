@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

  .highlightMainContainer {
  background: rgb(18,28,40);
  width: 75%;
  margin: 0 auto 5%;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 2%;
  }
  .btn-login-wrappers{
    height: 44px !important;
    border-radius: 20px;
    display: inline-block;
    position: relative;
  }
  .btn-login-wrappers::before {
    content: "";
    position: absolute;
    width: 180px;
    height: 44px !important;
    z-index: -1;
    inset: 0;
    padding: 2px;
    border-radius: 20px;
    background: linear-gradient(90deg, #6200EA 0%, #26C5F3 100%);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  
  
  }
  @media screen  and (max-width: 990px) {
    .datahandle1 {
      font-size:20px !important;
    }
    .taskselection{
      padding: 3% 50px 3% !important;
    }
   
  }
  @media screen  and (max-width: 1100px) {
    .datahandle {
      display:block !important;
      
    }
    .headerBox{
      height:175px !important;
      width:300px !important
    }
    .datahandle1{
      font-size:30px !important;
    }
  }
  .MuiTableRow-root {
    height: 52px !important;
  }
  
  .MuiTableCell-root {
    height: 52px !important;
    padding: 0 !important;
  }
  
  .highlightMainContainer .overall_card {
    margin: -12px !important
  }
  .highlightMainContainer .overall_card > div > div {
    max-width: none !important;
    min-width: 0px !important;
  }
  .donut-data {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 10px;
    color: #fff;
    font-family: Poppins !important;
    font-weight: 400;
  }
  
  
 
.donut-hole-3 {
  width: 34px !important;
  height: 34px !important;
  background: #1E293B;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
}


  .highlightMainContainer > div + div {
    margin-right: -10px !important;
  }
 
  @media screen and (max-width:1440px){
    .highlightMainContainer{
      width: 100%;
      max-width: 1154px;
    }
   
  } 
  
.student-detail {
    background: rgb(18,28,40);
    width: 75%;
    margin: 0 auto 1%;
    border-radius: 20px;
    border: 1px solid transparent;
    padding: 2%;
    }

.time-line-circle {
    width: 24;
        height: 24;
        border-radius: 20px;
        border-color: red;


}

  .highlightMainContainer {
  background: rgb(18,28,40);
  width: 75%;
  margin: 0 auto 5%;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 2%;
  }
 .inputText{
  position: relative;
    width: 340px;
    border-radius: 24px
 }


 @media screen  and (max-width: 1111px) {
  .inputText {
    width: auto !important;
  }
}
  @media screen  and (max-width: 990px) {
    .datahandle1 {
      font-size:20px !important;
    }
    .taskselection{
      padding: 3% 50px 3% !important;
    }
   
  }
  @media screen  and (max-width: 1100px) {
    .datahandle {
      display:block !important;
      
    }
    .headerBox{
      height:175px !important;
      width:300px !important
    }
    .datahandle1{
      font-size:30px !important;
    }
  }
  .highlightMainContainer .overall_card {
    margin: -12px !important
  }
  .highlightMainContainer .overall_card > div > div {
    max-width: none !important;
    min-width: 0px !important;
  }
  .donut-data {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 10px;
    color: #fff;
    font-family: Poppins, sans-serif!important;
    font-weight: 400;
  }
  
  
 
.donut-hole-3 {
  width: 34px !important;
  height: 34px !important;
  background: #1E293B;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
}


  .highlightMainContainer > div + div {
    margin-right: -10px !important;
  }
  .deleteTxt {
      font-family: 'Raleway', sans-serif;
      font-weight: 800;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 5%;
      color: '#F87171';
    
  }
 
  @media screen and (max-width:1440px){
    .highlightMainContainer{
      width: 100%;
      max-width: 1154px;
    }
   
  } 
.btn-login-wrapper{
  height: 34px;
  border-radius: 20px;
  display: inline-block;
  position: relative;
}
.btn-login-wrapper::before {
  content: "";
  position: absolute;
  width: 180px;
  height: 34px;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 20px;
  background: linear-gradient(90deg, #6200EA 0%, #26C5F3 100%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;


}
.popupErrorStyle{
  top: 140px;
}

.container-loginComp {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  width: 100% !important;
  min-height: 100vh !important;
  padding: 0 !important;
  position: relative !important;
  z-index: 999 !important;
  font-family: "Raleway", sans-serif !important;
  color: #ffffff !important;
  /* overflow-x: hidden !important; */
  background-color: #050d15 !important;
}

.container p,
.container h1,
.container h2,
.container span,
.container button {
  font-family: "Raleway", sans-serif !important;
}

.nav-container {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.nav-container::before {
  position: absolute;
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #272e3450;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.nav-wrapper {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  position: relative
}

.logo2 {
  width: 250px;
}

.auth-wrapper {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
}

.setup-wrapper{
  margin: 0;
  /* position: relative; */
}

.setup-wrapper span{
  display: inline-block;
}

.setupText {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 10px;
}

.setupInfoTextWrapper {
  position: absolute;
  width: 100%;
  max-width: 460px;
  top: 100px;
  left: 10px;
  border-radius: 20px;
  border: 1px solid #ffffff05;
  background-color: #050D15;
  padding: 14px 24px;
  font-weight: 200;
  display: flex;
  align-self: flex-end;
  justify-content: 'center';
  align-items: 'center';
  transition: 0.5s all ease-in-out;
}

.setupInfoText {
  font-weight: 400;
  line-height: 25px;
  width: 100%;
}

.setupLine {
  height: 15px;
  border: 0.5px solid white;
  margin-right: 5px;
}

.signupText {
  margin: 0;
}

.signUpBtn-gradient-border {
  /* Set button background color */
  background-color: transparent;

  /* Apply linear gradient as border */
  border: 2px solid;
  -o-border-image: linear-gradient(to right, rgba(98, 0, 234, 1), rgba(38, 197, 243, 1)) 1;
     border-image: linear-gradient(to right, rgba(98, 0, 234, 1), rgba(38, 197, 243, 1)) 1;
}

.btnWrapper {
  width: 120px;
  height: 34px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #6200ea, #26c5f3); 
  padding: 1px; 
}

.outlinedBtn {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #101821;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: 0;
}

.content-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width:1000px;
  align-self: center;
  padding: 180px 15px 80px;
}

.pgIcon {
  margin-bottom: -16px;
}

.form-content {
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading-container {
  margin: 50px 0 30px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.heading-wrapper {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.back-btn {
  padding: 5px;
  border-radius: 50%;
  background-color: #ffffff60;
  border: none;
  position: absolute;
  /* left: -13px; */
}

.back-btn-icon {
  font-size: 1rem;
}

.heading-loginComp {
  font-weight: 900 !important;
  font-family: "Raleway", sans-serif !important;
  line-height: 40px !important;
  font-size: 1.6rem !important;
}

.heading-auth {
  text-align: center;
  align-self: center;
  width: 100%;
}

.info-text {
  font-size: 1.1rem !important;
  text-align: center;
  font-weight: 500 !important;
}

.form-wrapper {
  width: 100%;
  margin-top:20px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 515px;
  background-image: linear-gradient(to bottom, #ffffff10, #050d1520);
  padding: 50px 10px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

.submitBtn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  width: 100%;
  max-width: 413px;
  font-weight: 800;
  padding: 14px 24px;
  color: #ffffff;
  font-size: 16px;
  border: 0;
  border-radius: 20px;
  margin-top: 20px;
  background: linear-gradient(to right, #6201ea, #26c5f3);
  transition: backgroundColor 0.3s ease-in-out, background 0.3s ease-in-out;
}

.leaveButton {
  width: 120px;
  max-width: 120px;
  font-weight: 800;
  padding: 14px 24px;
  color: #CBD5E1;
  font-size: 16px;
  border: 0;
  border-radius: 20px;
  margin-top: 20px;
  background: #334155;
  transition: backgroundColor 0.3s ease-in-out, background 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaveButton img {
  width: 20px;
  height: 20px;
}

.NextButton {
  width: 273px;
  max-width: 273px;
  font-weight: 800;
  padding: 14px 24px;
  color: #FFFFFF;
  font-size: 16px;
  border: 0;
  border-radius: 20px;
  margin-top: 20px;
  background: #72EFDD;
  transition: backgroundColor 0.3s ease-in-out, background 0.3s ease-in-out;
  cursor: pointer;
}
.zindexBtn {
z-index: 2;
}

.signupbtn-wrapper {
  width: 120px;
  height: 34px;
  border-radius: 20px;
  display: inline-block;
  margin: 20px 0;
  position: relative;
}

.signupbtn-wrapper::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 34px;
  inset: 0;
  padding: 1px;
  border-radius: 20px;
  background: linear-gradient(to right, #6200ea, #26c5f3);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
          mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}

.popup-error {
  width: 80%;
  max-width: 450px;
  position: absolute;
  top: 230px;
  right: 35px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  border-radius: 20px;
  padding: 8px 16px;
  border-left-width: 4px;
  border-left-style: solid;
  z-index: 2;
}

.popupError-text {
  transform: translateX(20px);
  font-weight: 300 !important;
  font-size: 0.9rem !important;
  width: 95%;
  margin-left: -10px !important;
}

.nextBtnWrapper {
  width: 100%;
  display: flex;
  margin-top:25px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px
}

.buttonBox{
  position: relative;
}

.tooltipBox {
  display: flex;
    justify-content: space-between;
    border: 1px solid #FFFFFF0D;
    padding: 15px 10px;
    width: 280px;
    align-items: start;
    position: absolute;
    right: 0;
    bottom: 25px;
    background-color: #33415580;
    z-index: 1;
    border-radius: 20px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
}

.tooltipBox img {
  cursor: pointer;
}

.tooltipBox p {
  margin-left: 10px;
}

.disableBtn :hover{
  display: block;
}
/* Media Queries */
@media screen and (max-width: 500px) {
  .setupText {
    /* margin-top: 10px; */
    margin-left: 5px;
    /* font-size: 1rem;
    font-weight: 500;
    margin-right: 10px; */
  }
  .nav-wrapper {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 360px) {
  .navWrapper, .nav-wrapper {
    padding: 20px;
  }
  .popup-error {
    width: 80%;
  }
  .setup-wrapper{
    margin: 10px 10px;
  }
}

@media screen and (min-width: 400px) {
  .navWrapper, .nav-wrapper {
    padding: 20px 30px;
  }
}

@media screen and (min-width: 500px) {
  .popup-error {
    width: 90%;
  }
  .nav-wrapper-setup {
    flex-direction: row;
  }
  .setupInfoTextWrapper {
    left: auto;
    right: 20px;
  }
}

@media screen and (min-width: 600px) {
  .form-content {
    width: 100%;
  }
  .form-wrapper {
    margin-top:20px;
    padding: 50px 10px;
  }
  .back-btn {
    padding: 5px;
    left: 20px;
  }
  .back-btn-icon {
    font-size: 1.25rem;
  }
  .heading {
    font-size: 2.145rem !important;
  }
  .popup-error {
    width: 100%;
    /* right: 40px; */
  }
}

@media screen and (min-width: 685px) {
  .nav-wrapper {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
  .auth-wrapper {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .back-btn {
    left: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .navWrapper, .nav-wrapper {
    padding: 20px 40px;
  }
  .logo {
    width: auto;
  }
  .back-btn {
    /* left: 150px;  */
  }
}

@media screen and (min-width: 1100px) {
  .back-btn {
    /* left: 200px;  */
  }
} 

@media screen and (min-width: 1800px) {
  .back-btn {
    /* left: 250px;  */
  }
} 

.line {
  height: 20px;
width: 1px;
display: inline-block;
background-color: #fff;
transform: translateY(4px);
}

.loginFormContainer {
  width: 100%;
  max-width: 413px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-wrapper:last-of-type {
  margin-bottom: 0;
}

.label-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  font-weight: 700;
  padding: 3px 10px 0;
  color: #ffffff40;
  transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out,
    color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    cursor: text; 
}

.input-field {
  width: 100%;
  align-self: center;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  padding: 14px 31px 10px;
  border: none;
  outline: none;
  border-radius: 24px;
  background-color: #050d1560;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

/* Focus styles */
.input-field:focus {
  outline: none;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff50;
  background-color: transparent;
  /* background-color: #1e252c; */
}

.input-field:not(:focus):valid {
  border: 0.5px solid #ffffff;
  background-color: #1e252c;
}

.input-field:-moz-placeholder-shown {
  border: 0.5px solid #484c50;
  background-color: #1e252c;
}

.input-field:placeholder-shown {
  border: 0.5px solid #484c50;
  background-color: #1e252c;
}

.input-field:focus + .label-text,
.input-field:not(:focus):valid + .label-text,
.input-field:autofill .label-text, 
.input-field:-webkit-autofill .label-text,
.input-field:placeholder-shown + .label-text {
  transform: translateY(-34px) translateX(10px);
  font-size: 12px;
  color: #ffffff;
}

.input-field:not(:focus):valid + .label-text,
.input-field:autofill + .label-text, 
.input-field:-webkit-autofill + .label-text,
.input-field:placeholder-shown + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #1e252c 50%);
}

.login-input1:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #182e31 50%);
}

.login-input2:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #081418 50%);
}

.forgot-paswrd:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #081018 50%);
}

.new-paswrd1:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #213954 50%);
}

.new-paswrd2:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #101c29 50%);
}

.input-field:-moz-placeholder-shown + .label-text {
  color: #ffffff40;
}

.input-field:placeholder-shown + .label-text {
  color: #ffffff40;
}

@keyframes autofill {
  0%,
  100% {
    color: #fff;
    background: transparent;
  }
}

.input-field:-webkit-autofill {
  -webkit-animation-delay: 1s !important;
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both !important;
}

.input-field:autofill {
  animation-delay: 1s !important;
  animation-name: autofill !important;
  animation-fill-mode: both !important;
}

.loginActions {
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Checkbox styles */
.checkbox-wrapper {
  width: auto;
  align-self: center;
  display: flex;
  align-items: center;
}

.checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 8px;
  margin-top: -3px;
  background-color: #050d1550;
  border: 0.5px solid #ffffff50;
}

/* On mouse-over, add a grey background color */
.checkbox-label:hover input ~ .checkmark {
  background-color: #72efdd50;
  border: none;
}

/* When the checkbox is checked, add a blue background */
.checkbox-label input:checked ~ .checkmark {
  background-color: #72efdd;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-label .checkmark:after {
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #1e252c;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/*  Create password page styles */
.passwordIconWrapper {
  position: absolute;
  top: 11px !important;
  right: 14px !important;
}

.eyeIcon {
  color: #a9a5a5;
  font-size: 1.4rem;
  transition: color 0.3s ease-in-out;
}

.input:focus ~ .eyeIcon {
  color: #ffffff;
}

.validation-container {
  margin-left: 40px;
}

.validation-wrapper {
  margin: 0 0 10px;
  display: flex;
  align-items: center;
}

.validation-container .validation-wrapper:last-child {
  margin: 0;
}

.cirleIcon,
.checkIcon,
.dashIcon .validationText,
.notValid,
.isValid {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #ffffff;
}

.notValid {
  color: #f87171;
}

.isValid {
  color: #72efdd;
}

/* Creat country page styles */
.inputWrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-image: url('../../assets/skipArrow.svg'); */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}

.phoneInputWrapper {
  position: absolute;
  top: 12px;
  left: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 108px;
}
.flag {
  width: 30px;
  height: 20px;
  border-radius: 8px;
  overflow: hidden;
}
.phoneInputWrapper span {
  color: #ffffff;
}
.phoneInput {
  padding-left: 140px;
  padding-right: 40px;
}
.divider {
  border: 0.5px solid #ffffff50;
  height: 15px;
  margin: 0 10px;
}

.phoneIconWrapper {
  position: absolute;
  top: 11px;
  right: 10px;
}

.phoneIcon {
  font-size: 1.3rem;
}

.typingWrapper {
  border: 1px solid #fef3c7;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing {
  font-size: 0.6rem;
  color: #fef3c7;
}

.validPhone {
  color: #72efdd;
}

.invalidPhone {
  color: #f87171;
}

.headingStyle {
  width: 100%;

}
.heading3style {
  background: -webkit-linear-gradient(180deg, #72efdd, #26c5f3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading2style {
  font-weight: 300 !important;
}

.forgotPswrdTxt {
  text-decoration: underline;
  cursor: default;
  max-width: 150px;
}

.popupErrorStyle{
  top: 140px;
}


/* Media Queries */
@media screen and (min-width: 400px) {
  .headingStyle {
    width: 100%;
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #294773 50%);
  }
  
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182942 50%);
  }
}
@media screen and (min-width: 600px) {
  .input-wrapper{
    width: 100%;
  }
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182e31 50%);
  }
  
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #081418 50%);
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #294a73 50%);
  }
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182942 50%);
  }
  .loginActions {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .popupErrorStyle {
    top: 134px;
    width: 95%;
    right: 50%;
    transform: translate(50%, 0);
  }
}

@media screen and (min-width: 800px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #203840 50%);
  }
}

@media screen and (min-width: 1024px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #29525a 50%);
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #2a4b74 50%);
  }
}

@media screen and (min-width: 1100px) {
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182931 50%);
  }
}

@media screen and (min-width: 1200px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #396b6b 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #213d42 50%);
  }
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #18293f 50%);
  }
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #213962 50%);
  }
}

@media screen and (min-width: 1300px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #315e60 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #295a5a 50%);
  }
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #18314a 50%);
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #213d5a 50%);
  }
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #28416a 50%);
  }
}

@media screen and (min-width: 1400px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #294e51 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #315e63 50%);
  }
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #29426b 50%);
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #183143 50%);
  }
}

@media screen and (min-width: 1500px) {
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #102129 50%);
  }
}
@media screen and (min-width: 1600px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #294e51 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #183639 50%);
  }
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182d42 50%);
  }
}

@media screen and (min-width: 1700px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #152026 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #15252b 50%);
  }
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #18314a 50%);
  }
}

@media screen and (min-width: 1800px) {
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #101921 50%);
  }
  .new-paswrd1:focus + .label-text, .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #101821 50%);
  }
}
.container-header {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  width: 100% !important;
  min-height: 70px !important;
  padding: 0 !important;
  position: relative !important;
  /* z-index: 999 !important; */
  font-family: "Raleway", sans-serif !important;
  color: #ffffff !important;
  /* overflow-x: hidden !important; */
  background-color: transparent !important;
}
strong {
  display: inline-block;
}
.preview-font span{
  display:contents !important;
}

.container p,
.container h1,
.container h2,
.container span,
.container button {
  font-family: "Raleway", sans-serif !important;
}

.headerAll {
  width: 100%;
  height: auto;
  /* position: fixed; */
  top: 0;
  left: 0;
  display: flex;
  /* justify-content: space-between; */
  z-index: 10;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  /* background: rgb(23, 33, 48); */
  
  /* background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)); */
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px;
  width: 28%;
  align-items: center;
  border: 1px solid gray;
  height: 186px;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  width: 70%;
  border: 1px solid gray;
  padding: 20px;
  height: 186px;
}

.headerAll::before {
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background-color: #272e3450; */
 
}

.mainQueSimulateContainer{
  display: flex;
  width: 100%;
  margin: 10px 0;
}
.headerMain{
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  z-index: 1;
  position: relative; 
  background-color: #0F161E; 
  
}

.logoTop {
  width: 145.93px;
  height: 67.75px;
  padding: 0px;
  -o-object-fit: contain;
     object-fit: contain;
}

.searchContainer {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
  width: 30%;
  justify-content: flex-end;
}
.simulateQueBtn {
padding: 2px;
overflow: hidden;
width: 100%;
height: 32px;
border-radius: 100px;
display: inline-block;
margin: 24px 14%;
position: relative;
}

.simulateQueBtn::before {
content: "";
position: absolute;
width: 70%;
height: 32px;
inset: 0;
padding: 2px;
border-radius: 100px;
background: linear-gradient(to right, #6200ea, #26c5f3);
-webkit-mask: 
   linear-gradient(#fff 0 0) content-box, 
   linear-gradient(#fff 0 0);
        mask: 
   linear-gradient(#fff 0 0) content-box, 
   linear-gradient(#fff 0 0);
-webkit-mask-composite: xor;
        mask-composite: exclude; 
}

.usrNameTxt {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background: none;
}
.header {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 46px;
  border-radius: 50px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
}
.showProfileLogout {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  width: 160px;
  height: 60px;
  margin-top:10px;
  border-radius: 50px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
}
.showProfile {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  width: auto;
  height: 46px;
  /* padding: 5px; */
  border-radius: 50px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
}
.subTextProfile{
  color: rgba(255, 255, 255, 0.3);
  font-size: 10px;
}
.profileLetter {
  background: rgba(255, 255, 255, 0.2);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.medBodySubText {
  font-size: 18px;
  font-weight: 600 !important;
  color: white;
  margin-top: 20px !important;
}


.textContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-left: 5px;
}

.searchBtn {
  background: rgba(255, 255, 255, 0.1);
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
}
.logoutBtnHeader {
  background: rgba(255, 255, 255, 0.1);
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 20px;
  clip-path: path("M30,0 C40,0,50,10,50,20 L50,40 C50,50,40,60,30,60 L20,60 C10,60,0,50,0,40 L0,20 C0,10,10,0,20,0 Z");

}
.exploreCard {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  grid-gap: 5%;
  gap: 5%
}
.CradBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;  
}
.QuestionsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  
}
.swipedata{
  width: 100%;
}
.cardMapContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #172130;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-bottom: 20px;
  overflow: clip;
  /* padding-left: 20px; */
}

.graphContainer {
  width: 100%;
  height: 320px;
  margin: 1% 0px 2%;
  border-radius: 20px;
  /* border: 1px solid transparent; Assuming you want to set a solid border */
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  overflow: hidden;
  padding: 25px;
  background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5));
  /* opacity: 0; Assuming you meant 'opacity: 0' rather than 'opacity: '0px'' */
}
.cardMainContainer {
  padding: 20px;
  background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5));
  width: 75%;
  margin: 0 auto 5%;
  border-radius: 20px;
  border: 1px solid transparent; /* Adjust border according to your needs */
  /* opacity: 0; Assuming you meant 'opacity: 0' rather than 'opacity: '0px'' */
}

.displayMenu {
  width: 56%;
}

.logoContainer {
  width: 14%;
}

.displayMenuText{
  width: 100px;
}
.instituteText {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
}
.cardMapContainer .cardTitle {
    font-size: 20px !important;
    font-weight: 900;
    color: #FFFFFF;
}
.knowledgeStatsContainer {
  width: 100%;
  height: auto;
  margin: 1% 0px 2%;
  border-radius: 20px;
  padding: 25px;
  background-color: #121c28;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  padding-bottom: 40px;
}
.knowledgeStatsContainer ::before {
  width: 100%;
  height: 100%;
  
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 10%;
  border-radius: 20px;
  border: 1px solid transparent;
  position: absolute;
}
.QueNewContainer{
  width: 75%;
  /* height: 228px; */
  margin: 5% auto 5%;
  border-radius: 20px;
  /* border: 1px solid transparent; Assuming you want to set a solid border */
  padding: 2%;
  background-color: #1E293B;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
}
.statsContainer {
  width: 75%;
  /* height: 228px; */
  margin: 5% auto 5%;
  border-radius: 20px;
  /* border: 1px solid transparent; Assuming you want to set a solid border */
  padding: 2%;
  background-image: url(/static/media/Radialbackground.2d95efd0.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #1E293B;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
}
.statsContainer ::before {
width: 100%;
height: 100%;
content: "";
top: 0;
left: 0;
z-index: 1;
opacity: 10%;
border-radius: 20px;
border: 1px solid transparent; /* Assuming you want to set a solid border */
background-image: url(/static/media/Radialbackground.2d95efd0.png);
background-repeat: no-repeat;
background-position: center center;
position: absolute;
}

.upcoming {
width: 320px;
height: 416px;
margin-left: 63px;
border-radius: 20px;
padding: 20px;
background-image: url(/static/media/Radialbackground.2d95efd0.png);
background-repeat: no-repeat;
background-position: center center;
background-color: #1E293B;
border: 1px solid rgba(255, 255, 255, 0.05);
position: relative;
}

.upcoming ::before{
content: "";
top: 0;
left: 0;
z-index: 1;
opacity: 10%;
background-image: url(/static/media/Radialbackground.2d95efd0.png);
background-repeat: no-repeat;
background-position: center center;
position: absolute;
}
.quePopUp {
display: flex;
align-items: center;
justify-content: center;
position: relative;
}
.popUpMain {
width: 501px;
  height: 136px;
  border-radius: 5px 20px 20px 20px;
  padding: 20px;
  background-color: rgba(5, 13, 21, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: absolute;
  display: none;
  left: 50%;
  top: 40px;
  z-index: 12;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.popUpMain p{
  font-family: Poppins, sans-serif!important;
}
.quePopUp:hover .popUpMain{
display: block;
}
.Glow {
width: 311px;
height: 311px;
left: calc(50% - 155px);
top: calc(50% - 155px);
position: absolute;
opacity: 58%;
}
.CircleContainer {
background-color: #0a1321;
width: -moz-max-content;
width: max-content;
/* width:393px; */
height: 393px;
position: relative;
border: 6px solid rgba(255, 255, 255, 0.05);
border-radius: 100%;
/* opacity: 0.58; */
aspect-ratio: 1/1;
}
.knowledgeLineContainer {
border-bottom:4px solid #E6D4D433; 
width:393px;
  height:151px;
  position: relative;
  border-radius:0 0 240px 50%/10px;
  box-shadow: rgba(174, 174, 174, 0.32) 0px 0px 0px 0px, rgba(236, 230, 230, 0.12) 0px 2px 0px;
    margin-top: 26%;
  
}

.medMainContainer {
/* background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)); */
background: rgb(18,28,40);
width: 75%;
margin: 0 auto 5%;
border-radius: 20px;
border: 1px solid transparent;
padding: 2%;
}

.overall_card {
  margin-left: 35% !important;
}

.medMainContainer .overall_card {
  margin: -12px !important
}
.medMainContainer .overall_card > div > div {
  max-width: none !important;
  min-width: 0px !important;
}
.medMainContainer button {
  border: none !important
}
.medMainContainer .CradBox .cardMapContainer > div { padding: 0px !important; }
.medMainContainer .CradBox .cardMapContainer > div > div > div > div > p {
  font-family: "Raleway", sans-serif!important;
}
.medMainContainer .CradBox .cardMapContainer > div:nth-child(2) > div {
  margin-top: 30px !important
}
.medMainContainer .CradBox .cardMapContainer > div:nth-child(2) > div > div > p {
  font-family: "Poppins", sans-serif!important;
}
.medMainContainer .CradBox .cardMapContainer > div:nth-child(2) > div:nth-child(2) > .course_desc > p {
  font-family: "Raleway", sans-serif!important;
}
.course-page .preview-font {
  font-family: "Poppins", sans-serif!important;
}
.medMainContainer .uncheck-cls {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.medMainContainer .checked-cls {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.medMainContainer11 {
  /* background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)); */
  /* background: rgb(18,28,40); */
  width: 90%;
  margin: 0 auto 5%;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 2%;
  }

.spanSimulateBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  background-color: #1E293B;
  border-radius: 100px;
  color: white;
  text-transform: capitalize;
}

.simulateBtn{
  width: 20%;
  height: 31px;
  padding: 2px;
}
.addNewTask {
width: 320px;
height: 416px;
margin-left: 63px;
border-radius: 20px;
padding: 20px;
background-color: #1E293B;
border: 1px solid rgba(255, 255, 255, 0.05);
position: relative;
}
.UpperContainer {
justify-content: space-between;
display: flex;
flex-wrap: initial !important;
width: 75% ;
margin: 0 auto;
}
.upperKnowledgeContainer {
/* justify-content: space-between; */
display: flex;
flex-wrap: initial !important;
width: 75% ;
margin: 0 auto;
}
.createdTask {
width: 100%;
background: #35107a;
height: 70px;
border-radius: 20px;
border: 0px solid rgba(255, 255, 255, 0.05);
margin-bottom: 12px;
padding:10px;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
position: relative;
}
.newTask {
  width: 320px;
  height: 416px;
  margin-left: 63px;
  border-radius: 20px;
  padding: 20px;
  background-image: url(/static/media/Radialbackground.2d95efd0.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #1E293B;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
}

.newTask ::before {
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 10%;
  background-image: url(/static/media/Radialbackground.2d95efd0.png);
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
}
.newTaskQu {
  width: auto;
  height: 416px;
  border-radius: 20px;
  padding: 20px;
  background-image: url(/static/media/Radialbackground.2d95efd0.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #172130;
  border: 3px dashed rgba(255, 255, 255, 0.05);
  position: relative;
  flex-basis: 31.33%;
  margin: 0 1%;
}
.quesbanks{
  flex-basis: 31.33%;
  margin: 0 1%;
}
.cardtext{
  font-family: Poppins, sans-serif!important;
}
.newTaskQu ::before {
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 10%;
  background-image: url(/static/media/Radialbackground.2d95efd0.png);
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
}
.react-swipeable-view-container{
  cursor: pointer;
}
.paginationdots{
  width: 100%;
}
.paginationdots button{
  min-width: auto;
  padding: 4px;
}
.positionFifth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 302px;
  right: 40px;
}
.positionFourth {
  position: absolute;
  top: 302px;
  left: 40px;
}
.positionThird {
  position: absolute;
  top: 110px;
  right: -40px;
}
.positionSecond {
  position: absolute;
  top: 112px;
  left:-20%
}
.positionKnowledgeSecond {
  position: absolute;
  top: 115px;
  left:-3%
}
.positionKnowledgeThird {
  position: absolute;
  top: 115px;
  right: -10px;
}
.secondBtnTitle {
  width: 135px;
  text-align: center ;
  color: #FFFFFF;
  margin-left: 10px;
}
.secondBtnKnowledgeTitle {
  width: 135px;
  text-align: center ;
  color: #FFFFFF;
 
}
.thirdBtnTitle {
  width: 100px;
  text-align: center ;
  color: #FFFFFF;
  margin-left: 20px;
}

.backShadowFirst {
  position: absolute;
  left: 50%;
  margin-left: -37px;
  /* background: "radial-gradient(51% 51% at 42.16% 40%, #FFFFFF 0%, #72EFDD 18%, #0F172A 100%)", */
}

.knowledgeCenter {
  top: -50px;
}

.catalogue {
  top: 100px;
}

/* .backShadowFirst::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 311px;
  height: 311px;
    background: linear-gradient(135deg, rgba(114, 239, 221, 0.2) 0%, rgba(38, 197, 243, 0.2) 100%);
} */

/* For the center circle with a more intense glow */
.center-circle {
animation: glow-animation 2s infinite alternate;
opacity: 40%;
}

.centerIcon{
  font-size: 14px !important;
  font-weight: 800 !important;
  text-align: center;
  margin-left: -30px !important;
  margin-top:20px !important
}
.leftIcon{
  font-size: 12px !important;
  text-align: center;
}
.rightIcon{
  font-size: 12px !important;
  text-align: center;
}
.leftIconSize{
  height: 97%;
  width: 98%;
  background-color: black;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 12px;
  border-radius: 50%;
}
.rightIconSize{
  height: 94%;
  width: 96%;
  background-color: black;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 12px;
  border-radius: 50%;
}
.centerIconSize{
  height: 30px;
  width: 30px;
}

/* Glow effect animation */
@keyframes glow-animation {
0% {
  box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px cyan, 0 0 25px cyan, 0 0 30px cyan, 0 0 35px cyan, 0 0 40px cyan;
}
100% {
  box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px cyan, 0 0 25px cyan, 0 0 30px cyan, 0 0 35px cyan, 0 0 40px cyan, 0 0 45px cyan;
}
}

.commonBox{
  width: 90px;
  height: 90px;
  background-color: rgba(5, 13, 21, 1);
  display: flex;
  border: 2px solid grey;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 50%;
  z-index: 100;
  position: relative;
}
.marketplaceBox{
  width: 90px;
  height: 90px;
  background-color: rgba(5, 13, 21, 1);
  display: flex;
  border: 2px solid grey;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 100;
  position: relative;
}

.smallBtn {
  width: 60px;
  height: 60px;
  background-color: rgba(5, 13, 21, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 50%;
  z-index: 100;
  position: relative; 
}  

.firstBtn {
  margin-left: 0px;
  border-width: 2px; /* Specific border width for the first button */
  margin-bottom: 10px;
}

.secondBtn {
  margin-left: 32px;
  margin-bottom: 10px;
  
}

.thirdBtn {
  margin-left: 25px;
  margin-bottom: 10px;
}
.welcomeTxt {
  color: white;
  font-weight: 500;
  /* border-bottom: 1px solid grey; */
  margin: 0 auto 30px;
  /* padding-bottom: 30px; */
  width: 75%;
}

.subMenuText {
  background: linear-gradient(89.86deg, #FFFFFF 0%, rgba(114, 239, 221, 0.5) 50%, rgba(38, 197, 243, 0.5) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900 !important;
}
.taskContainer {
  background-color:#050D15;
  padding: 3% 100px 3% ;
  border-radius: 0 0 50px 50px;
  position :relative;
}

.questionImg {
  position: absolute;
 bottom: -20px;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 40%;
  right: 60px;
  background: linear-gradient(90deg, #6200EA 0%, #26C5F3 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.calenderStyle {
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: center;
}

.medContainer {
  background: #172130;
  width: calc(100% - 20px);
  border-radius: 20px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 1px solid #FFFFFF0D;
  height: 150px;
  margin: 10px
}

.medWrapper {

}

.img-container{
  flex-basis: 30%;
}
.grapharea{
  flex-basis: 65%;
  margin-left: 5%;
  max-width: 65%;
}
.graphstart, .graphend{
  color: #fff;
  opacity: .23;
  font-size: 13px;

}
.chartgraph{
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
 
}
#gauge-chart5 text{
  display: none;
}
.graphvalue{
  display: flex;
  justify-content: center;
  font-family: Poppins, sans-serif!important;
}
.graphvalue span{
  font-family: Poppins, sans-serif!important;
  font-weight: 700;
}
.hourchart span{
  font-family: Poppins, sans-serif!important;
}
.hourchart{
  z-index: -1;
}
.graphtext, .graphtext span, .cardinfo{
  font-family: Poppins, sans-serif!important;
}
.mr-10{
 margin-bottom: -5px;

}
.graphvalue span{
  color: #fff;
  background-color: #39414b;
  font-size: 16px;
  border-radius: 100%;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-right: -1px;
}
#gauge-chart5 {
  width: 100%;
}
#gauge-chart5 svg{
max-width: 100%;
width: 100%;
}
#gauge-chart5 div:first-of-type{
  max-width: 100%;
}
.taskImg{
  width: 20px;
  height: 20px;
  margin-right: 10px; 
  margin-left: 5px;
}
.taskSection{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
 
}
.taskMainSection{
  height: 270px;
  overflow-y: auto;
  margin-bottom: 15px;
}
.p-2{
  padding: 2px;
}
.task-img{
  width: 20px;
}
.titleTask{
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.taskdeadline{
  font-family: Poppins, sans-serif !important;
  opacity: .8;

}
.greenCircle{
  background-color: #34D399;
  outline: 2px solid rgba(52, 211, 153, .3);
}
.yellowCircle{
  background-color: rgb(245, 158, 11);
  outline: 2px solid rgba(245, 158, 11, .3);
}
.redCircle{
  background-color: rgb(248, 113, 113);
  outline: 2px solid rgba(248, 113, 113, .3);
}
.greenCircle, .yellowCircle, .redCircle{
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  outline-offset: 1px;
}
.skipCircle{
  position: absolute;
  right: 20px;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 50%;
}
.task-title{
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-width: 90%;
}
.hourchart span{
  position: relative;
}
.hourchart span::after{
  content: "";
  width: calc(100% - 30px);
  height: 1px ;
  background-color: #8D8D8D;
  display: block;
  position: absolute;
  top: 50%;
  left: 30px;
  opacity: .3;
}
.cardMapContainer .enrollbtn{
  text-transform: capitalize;
  margin-top: 10px;
}
.enrollbtn{
  font-weight: 800!important;
}
.quebankbtns{
  font-weight: 800!important;
  letter-spacing: 0.7px!important;
  height: 32px;
  padding: 2px;
}
.cardMapContainer::-webkit-scrollbar {
  width: 10px;
}



.active_explore {
  font-size: larger;
  color: #fff;
  font-weight: bold;
  position: relative;
}
.no_active {
  font-size: large;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold
}

.active_explore::after {
  content: '';
  display: block;
  width: 100%; 
  height: 2px; 
  background: linear-gradient(to right, rgb(98, 0, 234) 0%, rgb(38, 197, 243) 100%); 
  position: absolute;
  top: 30px;
}

.disabled-image {
  pointer-events: none;
  opacity: 0.5; 
}
.explore-sort-items li{
  position: relative;
  display: block;
}
.explore-sort-items li:after{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  height: 2px;
  background-image: url(/static/media/border.08af8de8.svg);
  margin-left: -10px;
  
}

.explore_search {
  outline: none;
  border: none;
}
.explore_search:focus {
  outline: none;
  border: none;
}
.explore_search::-moz-placeholder {
  color: #fff
}
.explore_search::placeholder {
  color: #fff
}

.hide-scrollbar {
  scrollbar-width: none; 
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; 
}
.course_desc::-webkit-scrollbar, .taskMainSection::-webkit-scrollbar{
  background-color: transparent;
  width: 5px;
}

.course_desc::-webkit-scrollbar-thumb, .taskMainSection::-webkit-scrollbar-thumb {
  background: #ababab;
  width: 4px;
  border-radius: 20px;
}

.overall_card > div > div {
  max-width: 400px !important;
  min-width: 400px !important;
}
.cardimagedata {
  height: 210px !important;
}
.cardimagedata_card {
  height: 160px !important;
}
.overall_card::-webkit-scrollbar{
  background-color: transparent;
  width: 5px;
  height: 5px;
}

.overall_card::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 20px;
}

.overall_card_journey::-webkit-scrollbar{
  background-color: transparent;
  width: 5px;
  height: 5px;
}

.overall_card_journey::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 20px;
}
.cardinfo{
  opacity: .7;
  font-weight: 300;
}
.googlemeet{
  color: #fff;
}
.selectTimeRange{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}
.selectTimeRange .MuiSelect-select{
padding: 5px 20px;
font-size: 14px;
padding-right: 20px!important;
text-transform: none;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon , .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.knowledgeStatsContainer .MuiSvgIcon-root
{
  display: none!important;
}
.chartmsg{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  color: #fff;
  font-size: 12px;
  opacity: 0.8;
}

.filter_header {
  padding: 16px 12px;
  border-radius: 20px 20px 0px 0px;
  background-image: url(/static/media/view_rectangle.2b773d11.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.filter_background {
  padding: 24px 47px;
  border-radius: 0px 0px 20px 20px;
  background-image: url(/static/media/view_filter_body_bg.978278e7.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -20px;
}

.under_border {
  width: 100%;
  height: 2px;
  background-image: url(/static/media/underLine.ee9ad781.svg);
  background-repeat: no-repeat;
  background-position: center center;
  margin: 6px -7px;
}

.filter_background .row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.column {
  flex: 1 1 33.33%; 
  max-width: 33.33%; 
  box-sizing: border-box; 
  padding: 5px; 
}
.filterText {
  font-weight: 700;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.checkWrapper {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  background-color: rgba(255,255,255,0.1);
  padding: 6px 10px;
  border-radius: 16px;
  justify-content: center;
}
.cattext {
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.levelText {
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.filter_background > p {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.container_row {
  display: flex;
  flex-wrap: nowrap; 
  overflow-x: auto;
  scroll-snap-type: x mandatory; 
  padding: 10px;
}

.container_Column {
  min-width: 33.33%; 
  max-width: 33.33%; 
  flex: 0 0 33.33%; 
  scroll-snap-align: start; 
  margin-right: 10px;
}

.chartheader{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.totalgraph{
   position: relative;
  width: 100%;
  height: 139px;
}
.pagination-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 11;
  bottom: 17px;
  margin: 20px auto 0;
  width: 100%;
}

.dot {
  height: 7px;
  width: 7px;
  margin: 0 5px;
  background-color: #717171;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #bbb;
}
.contentgraph{
  display: flex;
  padding: 10px;
}
.leftgraph{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px;
  width: 40%;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.1);
  height: 186px;
}
.rightgraph{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 20px;
  width: 58%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  height: 186px;
  margin-left: 2%;
}
.donut-chart {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
}

.donut-hole {
  width: 80px;
  height: 80px;
  background: #1E293B;
  border-radius: 50%;
  top: 50%;
  left: 48%;
  font-size: 13px;
  transform: translate(-50%, -50%);
  z-index: 1;
  position: relative;
}

.donut-hole-2 {
  width: 39px;
  height: 39px;
  background: #1E293B;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
}

.donut-hole-3 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
}

.donut-data {
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 10px;
  color: #fff;
  font-family: Poppins, sans-serif!important;
  font-weight: 400;
}

.donut-slice {
  position: absolute;
  width: 100%;
  height: 100%;
  clip: rect(0, 150px, 150px, 75px);
  border-radius: 50%;
}
.piechartgraph{
  display: flex;
  width: 100%;
}
.piedata{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  margin-left: 10%;
}
.correctdata p{
  font-size: 14px;
  position: relative;
  color: #fff;
  font-family: Poppins, sans-serif!important;
}

.dotcorrect, .dotincorrect{
  width: 9px;
  height: 9px;
  border-radius: 100%;
  display: inline-block;
  left: -16px;
  top: 3px;
  position: absolute;
}
.dotcorrect{
  background-color: #72EFDD;
}
.dotincorrect{
  background: #5591F5;
}
.correctdata{
  text-align: right;
  margin: 10px;
}
.innersection {
  display: flex;
  margin-bottom: 4px;
}
.widthfill{
  border-radius: 10px;
}
.graphareabar{
  width: 50%;
  height: 20px;
  margin-left: 10px;
  padding: 4px 0;
}

.totalque span{
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}

.donutdata{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.errorsname{
  width: 50%;
  text-align: left;
  color: #fff;
position: relative;
display:flex;
}
.horizontalbar{
  margin-top: 15px;
}
.errorspan{
  color: #fff;
  width: auto;
  opacity: .8;
  font-size: 13px;
  font-family: Poppins, sans-serif!important;
  text-transform: capitalize;
}
.errorsname::after{
  content: "";
  background-color: #fff;
  height: 1px;
  width: 90%;
  opacity: .1;
  margin: 10px 5%;
}

label.MuiFormControlLabel-root{
  position: absolute;
  top: 10px;
  right: 114px;
}
.errornum{
  font-size: 15px;
  color: #fff;
  width: 35px;
  text-align: right;
}
.graphheader{
  color: white;
  font-size: 12px;
  opacity: 0.8;
  font-weight: 700;
  text-transform: uppercase;
}
.popularCat{
  width: 100%;
}
.catHeading{
  color : #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.sectionnumber{
  color:#72EFDD;
 display: flex;
 justify-content: center;
 align-items: center;
  border-radius: 10px;
  border: 1px solid #72EFDD;
  width: 40px;
  height: 20px;
}
.sectionnumber span{
  color:#72EFDD;
  font-family: Poppins, sans-serif!important;
  font-size: 12px;
}

.popularCat h6{
  color : rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}
.popularinner{
  display: flex;
  margin-top: 15px;
}
.populargraph{
  display: flex;
  padding: 20px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  height: 186px;
  border-radius: 20px;
  width: 100%;
}
.uparrow{
  height: 100%;
  margin-right: 15px;
  width: auto;
}
.popularimg, .popularimg2{
  position: absolute;
  width: 40px;
}
.popularimg{
  left: 0;
}
.popularimg2{
  left: 50%;
}
.rightbest{
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 130px;
}
.uparrow-sm{
  position: absolute;
  top: 10px;
  height: 10px;
  left: 7px;
}
.downarrow-sm{
  position: absolute;
  bottom: 10px;
  height: 10px;
  left: 7px;
}
.popular-heading{
  display: flex;
  width: 50%;
  margin-bottom: 13px;
  font-family: Poppins, sans-serif!important;
  position: relative;
  align-items: center;
}
.popular-heading p{
  color: #fff;
  font-size: 14px;
  opacity: .7;
  font-weight: 500;
  letter-spacing: .4;
  max-width: 190px;
}
.popular-heading img{
  width: 15px;
  margin-right: 15px;
  margin-left: 35px;
  height: 15px;
  -o-object-fit: cover;
     object-fit: cover;
}
.section-cat{
  width: 22px;
  height: 22px;
  background-color: #37465E;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif!important;
  position: absolute;
  right: 20px;
}
.section-cat span{
  color:#fff;
  font-size: 12px;
  font-family: Poppins, sans-serif!important;
}
.linechart{
  position: absolute;
  left: -10px;
  top: 40px;
}

.MuiPopover-root .MuiMenu-list, .MuiPopover-root .MuiMenuItem-root{
  background-color: #1C2632;
  color: #fff;
}
.MuiPopover-root ul.MuiList-root{
  padding: 0 0!important;
  background-color: #1C2632;;
}
.MuiPopover-root .MuiMenuItem-root{
  padding: 10px 25px;
  font-size: 14px;
  font-family: Poppins, sans-serif!important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  min-width: 130px;
}
.MuiOutlinedInput-notchedOutline, .css-igs3ac, .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
 border-color: transparent!important;
}
.MuiPaper-root.MuiPaper-rounded{
  border-radius: 20px;
  min-width: 130px!important;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background-color: transparent!important;
  color: transparent!important;
}
.MuiPopover-root .Mui-selected{
  font-weight: 600;
  background: linear-gradient(90deg, rgba(45, 0, 247, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%)!important;
}
.MuiMenuItem-root:hover{
  background: linear-gradient(90deg, rgba(45, 0, 247, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%)!important;
}
.medRightImg {
  transform: scaleY(-1);
  margin-top: -22px;
}

.font-family-p {
  font-family: Poppins, sans-serif!important;
}

.entityone, .entitytwo{
  position: absolute;
}
.entitytwo{
  right: 5px;
}
.entityone{
  right: calc(50% + 5px) 
}
.maingraphsection{
  cursor: grabbing;
  position: relative;
  z-index: 99;
  padding-top: 50px;
}

.statsheading{
  position: absolute;
  width: calc(100% - 50px);
}

.MuiSwitch-root {
  width: 66px!important;
  height: 33px!important;
  padding: 0px!important;
}

.MuiSwitch-thumb {
  width: 30px!important;
  height: 25px!important;
  background-color: #64748B!important;
}

.MuiSwitch-track{
  border-radius: 30px!important;
  background-color: rgba(255, 255, 255, 0.1)!important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #64748B!important;
}

.MuiSwitch-switchBase {
  top: -5px!important;
}

.MuiSwitch-input {
  left: 0!important;
  width: 100%!important;
}

.donutdata p{
  color: #fff;
  font-size: 13px;
}

.donutdata .totalque{
  color: #fff;
  font-weight: 800;
  font-size: 14px;
}
.hourDiv{
  z-index: -99;
}
.grapharea .content{
  background-image: none;
  background-color: transparent;
}
.topbestlist{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  height: 30px;
}
.topbestimg img{
  width: 25px;
}
.topbestlist p{
  color: #fff;
  max-width: 180px;
  font-size: 14px;
  opacity: .8;
  font-family: Poppins, sans-serif!important;
  line-height: 20px;
}
.topbestimg{
  list-style-type: none;

  width: 35px;
}
.topbestimg li{
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.topbestarea{
  display: flex;
}

.empty-explore {
  background-image: url(/static/media/skip_union.d7a9392f.png);
  background-repeat: 'no-repeat';
  Width: 256px;
  Height: 270px;
  position: relative;
}
.empty-explore-1 {
  background-image: url(/static/media/skip_union.d7a9392f.png);
  background-repeat: 'no-repeat';
  Width: 230px;
  Height: 270px;
  position: relative;
}
.centered-element {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headerData{
  display: flex;
  background-color: #050E15;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  color: #fff;
  padding: 40px 10%;
  align-items: center;
}
.subheader{
  margin-bottom: 50px;
}
.leftheader{
  width: 30%;
  display: flex;
  align-items: flex-end;
}
.leftheader h4{
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 300;
}
.leftheader p{
  font-size: 24px;
  font-weight: 900;
}
.rightheader{
  width: 70%;
}
.rightheader img{
  width: 16px;
  margin-right: 5px;
}
.journeyData{
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
.journeyData p{
  font-size: 13px;
  margin: 0 10px 10px;
  font-family: Poppins, sans-serif!important;
  font-weight: 400;
  letter-spacing: .5;
  display: flex
}
.journeytext{
  font-size: 14px;
  opacity: .8;
  text-align: right;
  width: 100%;
}
.journeybackload{
  width: 30px;
  justify-content: center;
  border: 0px solid #fff;
  border-radius: 50%;
  height: 30px;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.journeyback{
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 0px solid #fff;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}
.journeyback img{
  width: 100%;
  padding: 4px;
}
.cardParent{
  /* width: 100%; */
  /* overflow-x: hidden; */
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 120px;
}

.boxJourney{
  /* margin-left: 37.5%; */
  position: relative;
  width: 100%;
  height: 331px;
  padding: 0px;
}
.borderJourney {
  outline: 3px solid #72EFDD;
  outline-offset: 3px;
}
.comingsoon{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:99;
}
.comingsoon p{
  color: rgba(255, 255, 255);
  font-size: 22px;
  font-weight: 700;
}
.boxJourney:first-of-type .comingsoon{
  display: none;
}

.cardParent .getStartbtn{
  color: white;
  position: absolute;
  background: linear-gradient(90deg, rgb(98, 0, 234) 0%, rgb(38, 197, 243) 100%);
  border-radius: 20px;
  height: 32px;
  overflow: hidden;
  letter-spacing: 0.7;
  text-transform: capitalize;
  margin-bottom: 62px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  padding: 4px 40px;
  white-space: nowrap;
}
.cardParent .getStartbtn:first-of-type{
  display: block;
}
.boxJourney .percentCircle{
  display: none;
}
.boxJourney:first-of-type .percentCircle{
  display: inline-block;
}
.cursorpointer{
  cursor: pointer;
  margin-left: -10px !important;
}
.medMainContainer > div + div {
  margin-right: -10px !important;
}
.listMenu{
  background-color: rgba(5, 13, 21, 1);
  color: #fff;
}
.navHeader{
  background-color: transparent!important;
  width: 60px!important;
  position: absolute!important;
 right: auto;
 left: 10px;
 border-radius: 50%;
}

.MuiDrawer-paper.MuiPaper-root{
  position: relative;
  top: 0px;
  background-color: rgba(5, 13, 21, 1);
  color: #fff;
  height: auto;
  overflow-x: hidden;
  padding: 50px 0px 30px;
  border-bottom-right-radius: 100px;
  margin-bottom: 20px;
}
.scoreDrawer{
  position: relative;
}
.scoreDrawer .MuiDrawer-paper.MuiPaper-root{
  height: 100%;
  position: absolute;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
  max-height: inherit;
}
.MuiDrawer-root.MuiDrawer-docked .MuiIconButton-label{
color: #fff;
}

.unitareaDrawer .MuiDrawer-paper.MuiPaper-root{
  background-color: transparent;
  padding: 0px 0px 0px;
  border-color: transparent;
}

.unitareaDrawer .MuiDrawer-paper.MuiPaper-root .mainList{
  background-color: rgba(5, 13, 21, 1);
  padding: 50px 0px 50px;
  border-bottom-right-radius: 100px;
}
.navSubHead{
  max-width: calc(100% - 45px);
}
.coursename, .unitname{
  list-style-type: none;
  padding-left: 30px;
  text-wrap: wrap;
}
.coursename h5{
  color: #fff;
  opacity: .8;
  font-size: 20px;
  font-weight: 300;
  
}
.unitname h4{
  color: #fff;
  text-wrap:auto;
  
  font-size: 24px;
  font-weight: 900;
}
.sectionArea .MuiPaper-root.MuiPaper-rounded {
  color: #fff !important;
}
.sectionName{
  color: #fff;
  padding-left: 0px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.sidenavHeader{
  position: absolute;
  left: 25.2vw;
  border: 2px solid rgba(30, 41, 59, 0.61);
  border-radius: 50%;
  z-index: 1300;
  top: 130px;
  background-color: rgba(5, 13, 21, 1);
  color: #fff;
}
.sidenavHeader button, .closedNav button{
  color: #fff;
  padding: 9px;
}
.closedNav{
  position: absolute!important;
  border: 2px solid rgba(30, 41, 59, 0.61);
  border-radius: 50%;
  top: 130px;
  background-color: rgba(5, 13, 21, 1);
  color: #fff;
  left: 40px;
  z-index: 1300;
  height: auto;
  width: auto;
  min-height: 30px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0!important;
  padding-right: 0!important;
}
.closedNav button{
  margin-left: 0;
  margin-right: 0!important;
  padding: 9px;
  color: #fff;
}
main h2{
  color: #fff;
}
.scroll-container h6{
  color: #fff;
}
.lessonName{
  font-size: 15px;
  max-width: 18vw;
  white-space: pre-line;
}
.lessonName .MuiListItemText-root{
  margin-top: 0;
  margin-bottom: 0;
}
.lessonName span{
  font-size: 15px;
  margin-left: 15px;
  opacity: .8;
  color: #fff;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
}
.smallLesson{
  font-size: 14px;
  margin-left: 42px;
  max-width: 18vw;
  white-space: pre-line;
  font-weight: 300;
  position: relative;
}
.smallLesson::before{
position: absolute;
left: -33px;
content: "";
width: 7px;
height: 7px;
background-color: rgba(5, 13, 21, 1);
border-radius: 50%;
top: 11px;
border: 1px solid rgb(51, 65, 85);
}
.smallLesson div{
  padding-top: 2px;
  padding-bottom: 2px;
}
.smallLesson span{
  font-size: 14px;
  opacity: .6;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}
 ul .iconIndex{
 
  justify-content: center;
  align-items: center;
  min-width: 25px!important;
  width: 25px;
  height: 25px;
  border: 1px solid rgba(51, 65, 85, 1);
  border-radius: 50%;
 
}
ul .iconIndex span{
  font-family: Poppins, sans-serif!important;
  font-size: 12px;
  color: rgb(171, 182, 197);
}
.navHeading{
  display: flex;
  padding: 0 20px;
  margin: 0 0 20px;
}
.goBackbtn{
  align-self: flex-end;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 0px solid #fff;
  margin-bottom: 5px;
}
.goBackbtn2{
  align-self: flex-end;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 0px solid #fff;
  margin-left: 7%;
  position: absolute;
  top: 170px;
  z-index: 1300;
}
.gradientHR{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.025) 0%, rgba(114, 239, 221, 0.25) 50.03%, rgba(255, 255, 255, 0.025) 100.05%);
}
.mdjourney-container .wrongAnswer {
  border: 1px solid rgba(248, 113, 113, 1)!important; 
  transition: 0.0s all ease-in-out;
  box-shadow: 0 0 4px 1px rgba(248, 113, 113, .35);
}
.mdjourney-container .correctAnswer{
  border: 1px solid #fff!important;
  transition: 0.0s all ease-in-out;
  box-shadow: 0 0 4px 1px #ffffff60;
}
.mdjourney-container .rightAnswer {
  border: 1px solid rgba(33, 213, 142, 1)!important;/* Green border for correct answer */
  transition: 0.0s all ease-in-out;
  box-shadow: 0 0 4px 1px rgba(33, 213, 142, .35);
}
.mdjourney-container .wrongAnswer span, .mdjourney-container .wrongAnswer label{
  color: rgba(248, 113, 113, 1);
}
.mdjourney-container  .correctAnswer span, .mdjourney-container .correctAnswer label{
  color: #fff;
}
.mdjourney-container .rightAnswer span, .mdjourney-container .rightAnswer label{
  color: rgba(33, 213, 142, 1);
}
.wrongAnswer{
  background-image: url(/static/media/image_cross.dbef2168.svg);
  background-repeat: no-repeat;
  background-position: 97% 50%;
}
.rightAnswer{
  background-image: url(/static/media/image_check.c0856c5c.svg);
  background-repeat: no-repeat;
  background-position: 97% 50%;
}
.sectionArea{
  position: relative;
}
.sectionArea::before{
  content: "";
  width: 1px;
  height: calc(100% - 24px);
  position: absolute;
  background-color: rgba(51, 65, 85, 1);
  left: 12px;
  top: 41px;
}
.sectionArea .listLesson, .sectionArea .listLesson li {
  padding-top: 1px;
  padding-bottom: 1px;
}
.subHeading{
  max-width: calc( 100% - 50px);
}
.subHeading h4, .subHeading p{
  text-wrap: wrap;
}
.topicDropdown.MuiPaper-root.MuiPaper-rounded{
  border: 0px solid rgba(255, 255, 255, 0.05);
  box-shadow: none;
}
.sectionDropdown.MuiPaper-root.MuiPaper-rounded{
  border: 0px solid rgba(255, 255, 255, 0.05);
  box-shadow: none;
}
.topicDropdown .MuiButtonBase-root.MuiAccordionSummary-root{
  padding: 0 0;
}
.sectionDropdown .MuiAccordionDetails-root {
  padding: 0px 0px 0px;
  flex-direction: column;
}
.topicDropdown .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0px;
}
.sectionDropdown .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0px;
}
.topicDropdown .MuiAccordionSummary-content{
  margin: 0px 0px;
}
.sectionDropdown  .MuiAccordionSummary-content{
  margin: 0px 0px;
}
.sectionDropdown .MuiIconButton-root svg{
  font-size: 14px;
  margin: 0 6px;
}
.topicDropdown .MuiAccordionDetails-root {
  padding: 0px 0px 0px;
  flex-direction: column;
}
.dropDownsection{
  padding: 0px 20px;
  margin-top: 20px;
  text-wrap: auto;
}
.dropDownsection .MuiListItem-gutters {
  padding-left: 0px;
  padding-right: 0px;
}
.dropDownsection .MuiAccordionSummary-root.Mui-expanded {
  min-height: 30px;
}

.iconArrow .MuiAccordionSummary-expandIcon{
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 50%;
  margin: 0 auto;
  padding: 4px;
}
  
.iconArrow .MuiAccordionSummary-expandIcon svg{
  font-size: 15px;
}
.goBackbtn, .goBackbtn2{
  cursor: pointer;
}
.dropDownsectionClose{
  padding: 0px 17px;
  margin-top: 20px;
}
.dropDownsectionClose .MuiAccordionSummary-expandIcon, .dropDownsectionClose .smallLesson::before{
  display: none;
}
.sectionArea .listData{
  align-items: flex-start;
}
.mainheader.container-header{
  max-width: none;
}
.sectionArea.true ul .iconIndex{
  border: 1px solid rgba(114, 239, 221, 1);
  background-color: rgba(114, 239, 221, 0.2);
}
.sectionArea.true ul .iconIndex span{
 color: #fff;
}
.sectionArea.true::before {
  background-color: rgba(114, 239, 221, 1);
}
.sectionArea.true .smallLesson::before{
  border: 1px solid rgba(114, 239, 221, 1);
}
.iconIndex img{
  opacity: .4;
}
.iconIndex.true{
  border: 1px solid rgba(114, 239, 221, 1);
  background-color: rgba(114, 239, 221, 0.2);
}
.iconIndex.true img{
  opacity: 1;
} 
.MuiGrid-root.MuiGrid-item:has(.comingsoon){
  display: none;
}

.scoreDrawer .MuiDrawer-paper.MuiPaper-root::-webkit-scrollbar {
  width: 3px;
}
.scoreDrawer .MuiDrawer-paper.MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: #1E293B;
}
.footersmall{
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.social-links-container p{
  color: rgba(255, 255, 255, 0.5)!important;
}
/* Media Queries */
@media screen and (min-width: 380px) {
  .headerMain {
    padding: 20px;
  }
  .displayMenu {
    width: 100%;
  }
}

@media screen and (max-width: 926px) {
  .firstBtn {
    margin-left: 0px;
    border-width: 2px;
    margin-bottom: 20px;
    margin-top: 12px;
  }
  .secondBtn {
    margin-left: 25px;
    margin-bottom: 10px;
  }
  .centerIcon {
    font-size: 12px !important;
    font-weight: 800 !important;
    text-align: center;
    width: 92%;
    margin-left: -11px !important;
  }
}

@media screen and  (min-width: 200px) and (max-width: 300px){
.commonBox {
    width: 60px !important;
    height: 60px !important;
    border-radius: 40px;
}
.knowledgeLineContainer {
  width: 270px !important;
  margin-bottom: 50px !important;
}
.smallBtn {
  width: 40px;
  height: 40px;
}
.thirdBtn {
  margin-left: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.secondBtn {
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.leftIconSize{
  height: 38px;
  /* width: 20px; */
}
.rightIconSize{
  height: 36px;
  /* width: 20px; */
}
.leftIcon {
  font-size: 10px !important;
  text-align: center;
}
.rightIcon {
  font-size: 10px !important;
  text-align: center;
}
.centerIcon {
  font-size: 11px !important;
  font-weight: 700 !important;
  text-align: center;
  width: 92%;
}
.firstBtn {
  margin-left: 15px;
  border-width: 2px;
  margin-bottom: 21px;
  margin-top: 15px;
}
.centerIconSize{
  height: 20px;
  width: 20px;
}
}




@media screen and (min-width: 400px) {
  .headerMain {
    padding: 20px 30px;
  }
}

@media screen and (min-width: 500px) {
   .searchContainer{
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .heading {
    font-size: 2.145rem !important;
  }
}
@media screen and (min-width: 685px) {
  .headerMain {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
  .searchContainer {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .headerMain {
    padding: 20px 40px;
  }
  .logoContainer {
    width: auto;
  }
}

@media screen and (max-width: 550px) {
  .simulateBtn{
    width:80%
  }
  .statsContainer{
    width: 85%;
    padding: 5%;
  }
  .CircleContainer {
    width:315px;
    height: 315px;
  }
  .commonBox {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
  .positionSecond {
    top: 87px;
    left: -22%;
   
  }
  .secondBtnTitle {
    width: 215px;
    height: 55px;
  }
  .secondBtnKnowledgeTitle {
    width: 194px;
  }
  .thirdBtnTitle {
    width: 97px;
  }

  .positionthird {
    top: 87px;
  }
  .positionFourth {
    top: 252px;
    left: 29px;
  }
  .positionFifth {
    top: 252px;
  }
  .newTask {
    width: 100%;
  }
  .addNewTask {
    width: 100%;
  }
  .knowledgeStatsContainer{
    margin: 30% 1% 5% !important;
    height: auto !important;
  }
  .graphContainer{
    margin: 30% auto 20% !important;
    height: auto !important;
    padding: 20px;
  }
  .content{
    flex-wrap: wrap;
  }
  .left{
    width: 100% !important;
  }
  .right{
    width: 100% !important;
  }
  .knowledgeLineContainer{
    width: 325px;
    margin-bottom: 50px !important;
  }
  .mainQueSimulateContainer{
    margin: 10px 0;
    flex-wrap: wrap;

  }
  .newTaskQu{
    width: 100%;
    margin-left: 25px;
    margin-bottom: 20px;
    height: auto;
  }
  .cardMainContainer{
    width: 85%;
  }
  .medMainContainer{
    width: 85%;
    padding: 5%;
  }
  .QueNewContainer{
    width: 85%;
  }
}
.hover-white-text:hover {
  color: white !important;
}

@media screen and (max-width: 684px) {
  .taskContainer {
    padding: 14% 40px 3% ;
  }
  .welcomeTxt {
    text-align: center;

  }
  .cardTitle {
    font-size: 16px !important;
    font-weight: 900;
    color: #FFFFFF;
  }
  .medBodySubText {
    font-size: 14px !important;
    text-align: center;
  }

}
@media screen and (max-width: 767px) {
  .logoContainer {
    width: 100% !important;
    text-align: center;
  }
  .searchContainer{
    width: 100%;
    justify-content: center;
    margin-top: 10px !important;
  }
  .displayMenuText {
    width: auto;
    padding: 0px 5px
  }

  .taskContainer {
    padding: 20% 50px 3% ;
  }

  .addNewTask{
    margin-top: 50px;
    width: 100% !important;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .newTask{
    margin-top: 50px;
    width: 100% !important;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .upcoming {
    width: 100% !important;
    margin-left: 0px;
    margin-bottom: 50px;
  }
  .questionImg {
    right: 10px;
  }
  .cardMainContainer {
    padding: 10px;
  }
  .cardMapContainer {
    width: 100% !important;
    margin-bottom: 20px;
  }

 .medContainer {
    width: 100%;
    margin: 1px; 
    align-items: center;
    
  }
}
@media screen and (max-width: 950px) {
  .cardMapContainer {
    width: 100% !important;
  }
  .displayMenu {
    width: 100%;
  }
  .UpperContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap !important; 
    justify-content: center;
  }
  .upperKnowledgeContainer{
    display: flex;
    grid-gap:30px;
    gap:30px;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap !important; 
    justify-content: center !important;
    align-items: center !important;
  }
  .graphContainer{
    margin: 18% auto 2%;
  }
  .knowledgeStatsContainer{
    margin: 20% auto 2%;
  }
  .upcoming {
    margin-top: 20px;
    width: 70%;

  }
  .addNewTask {
    margin-top: 50px;
    width: 70%;
    margin-bottom: 20px;
  }
  .newTask{
    margin-top: 50px;
    width: 70%;
    margin-bottom: 20px;
  }
  .mainQueSimulateContainer{
    margin: 10px 0;

  }
}

@media screen and (max-width:1100px) {
  .UpperContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
  .upperKnowledgeContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap !important; 
    justify-content: center !important;
  }
  .upcoming {
    margin-top: 50px;
  }
}
@media screen and (max-width:1200px) {
  .UpperContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    justify-content: center;
    flex-wrap: wrap !important;
  }
  .upperKnowledgeContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    justify-content: center;
    flex-wrap: wrap !important;
    grid-gap: 40px;
    gap: 40px;
  }
  .upcoming {
    margin-top: 50px;
  }
  .cardMapContainer {
    width: 45% ;
    margin: 15px;
  }
  .CradBox {
    justify-content: center;
    flex-wrap: wrap;
  }
  .medContainer {
    width: 100%;
  }
}

@media screen and (max-width:1440px){
  .statsContainer, .UpperContainer, .upperKnowledgeContainer, .medMainContainer, .cardMainContainer, .QueNewContainer{
    width: 100%;
    max-width: 1154px;
  }
  .welcomeTxt, .welcomeBottom-border{
    width: 100%!important;
    max-width: 1154px;
  }
 
} 
@media screen and (max-width:1500px) {
  .upperKnowledgeContainer{
    justify-content: space-evenly;
  }
}
/* styles.css */
.button-with-gradient-border {
  /* Set button background color */
  background-color: transparent;

  /* Apply linear gradient as border */
  border: 2px solid;
  -o-border-image: linear-gradient(to right, rgba(98, 0, 234, 1), rgba(38, 197, 243, 1)) 1;
     border-image: linear-gradient(to right, rgba(98, 0, 234, 1), rgba(38, 197, 243, 1)) 1;
}
.welcomeBottom-border {
  width: 75%;
  display: block;
  height: 1px;
  margin: 0 auto 80px;
  /* padding-bottom: 30px; */
  margin-top: 10px;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.5) 100.05%);

/* 
  linear-gradient(90deg, #26C5F3, #26C5F315);  */
} 

@media screen and (min-width: 1600px){
  .sidenavHeader{
    left: 25.5vw;
  }
}
@media screen and (min-width: 2000px){
  .sidenavHeader{
    left: 25.9vw;
  }
}
@media screen and (min-width: 2500px){
  .sidenavHeader{
    left: 26.2vw;
  }
}
@media screen and (min-width:1500px){
  .cardimagedata{
    background-size: 110% auto!important;
  }

}

.logoutBtn img {
  filter: drop-shadow(0px 0px 5px rgba(100, 200, 200, 0.8));
}
.logout-container {
  width: 80px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: radial-gradient(circle, rgba(100, 400, 400, 0.1) 20%, transparent 80%);
  border-radius: 50%;
}

.logoutBtn {
  background: transparent;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.Quetion_bank_list_header {
    display: flex;
    justify-content: space-between;
    background: #d1cece;
    border-radius: 20px;
    padding: 10px 11px;
    align-items: center;
    margin: 0 0 8px 0;
}

.Quetion_bank_list_header_text {
    margin: 28px 0;
    font-size: 36px;
}

.Quetion_bank_list_header_text h4 {
    font-size: 18px;
    color: #fff;
    margin: 0;
}

.Quetion_bank_btn,
.Quetion_bank_btn:focus {
    margin-right: 15px;
    background-color: goldenrod;
    font-size: 14px;
    font-weight: 600;
    border: none;
    color: white;
    border-radius: 20px;
}

.gallery_select p {
    margin-right: 15px;
    background-color: goldenrod;
    font-size: 14px;
    font-weight: 600;
    border: none;
    color: white;
    border-radius: 20px;
    padding: 6px;
    cursor: pointer;
}

.Question_box {
    margin-bottom: 0px;
    padding: 10px 0 10px 10px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    color: black;
}

.Question_box_view {
    border: 1px solid rgb(196, 189, 189);
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.Options_view_box {
    margin-top: 20px;
    border: 1px solid rgb(196, 189, 189);
    padding: 8px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.Options_view {
    color: black;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.class-pag {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    list-style: none !important;
}

.modal-content {
    border: none !important;
}

.Answer_description_box {
    background: goldenrod;
    color: #fff;
    font-weight: 600;
    padding: 6px 14px 6px 14px;
    border-radius: 20px;
}

.Answer_description_box p {
    margin: 0;
}

input[type="file"] {
    display: none !important;
}

.Question_answer_box {
    margin: 25px 12px;
}

.description_heading {
    font-size: 18px;
    margin: 10px 0;
}

.container-loginComp {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column;
    width: 100% !important;
    min-height: 100vh !important;
    padding: 0 !important;
    position: relative !important;
    z-index: 999 !important;
    font-family: "Raleway", sans-serif !important;
    color: #ffffff !important;
  }

.sectionContainer {
    background: linear-gradient(180deg, rgba(85, 145, 245, 0) 0%, rgba(114, 239, 221, 0.25) 49%, rgba(85, 145, 245, 0.5) 100%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.questionNum{
    font-size: 20px;
    font-weight: 900;
    line-height: 40px;
}

.question{
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
}

.dialogContainer {
    width: 100%;
    height: 100%;
}

.dialogBox {
    width: 481px;
    height: 287px;
    background-color:#334155;
    padding: 30px
}

.dialogTextCon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px
}

.dialogHeading {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #fff;
    font-family: 'Raleway', sans-serif !important;
}

.textDec {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #fff;
    font-family: 'Raleway', sans-serif !important;
}

.textDec1 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #fff;
    font-family: 'Raleway', sans-serif !important;
    
}

.MuiPaper-elevation24 {
    box-shadow: none !important;
}

.btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
}

.leaveBtn {
    width: 190px;
    height: 32px;
    color: #94A3B8;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #050D1566;
    cursor: pointer;
    font-weight:bold;
    font-family: 'Raleway', sans-serif !important;
}

   
.cancelBtn {
    width: 190px;
    font-family: 'Raleway', sans-serif !important;
    font-weight:bold;
    height: 32px;
    color: rgba(114, 239, 221, 1);
    border-radius: 20px;
    background: rgba(114, 239, 221, 0.2);
    border-image-source: linear-gradient(180deg, rgba(5, 13, 21, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
}

.iconBox {
    display: flex;
    justify-content: space-between;
}

.leaveBorder {
    border: 1px solid rgba(114, 239, 221, 1);
    padding: 10px;
    border-radius: 50%;
}

.rightAnswer {
    border: 1px solid green !important;
}
.container {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  width: 100% !important;
  min-height: 100vh !important;
  padding: 0 !important;
  position: relative !important;
  z-index: 999 !important;
  font-family: "Raleway", sans-serif !important;
  color: #ffffff !important;
  overflow-x: hidden !important;
}

.container p,
.container h1,
.container h2,
.container span,
.container button {
  font-family: "Raleway", sans-serif !important;
}

.navContainer {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.navContainer::before {
  position: absolute;
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #272e3450;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.navWrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.logo {
  width: 250px;
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.facts,
.bgImg {
  background-color: #050d15;
  position: relative;
  min-height: 40vh;
}

.bgImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.factsBgImg {
  position: absolute;
  z-index: 1;
}

.factsWrapper {
  z-index: 2;
  padding: 150px 15px 40px;
}

.heading {
  font-weight: 900 !important;
  font-family: "Raleway", sans-serif !important;
  line-height: 45px !important;
}

.factsHeading {
  font-size: 1.3rem !important;
}

.infoText {
  font-size: 1rem !important;
  text-align: left;
  font-weight: 400 !important;
}

.factsInfoText {
  margin-top: 20px;
  font-weight: 400 !important;
}

.factsInfoText2 {
  margin-top: 25px;
  font-weight: 600 !important;
}

.content {
  flex-direction: column;
  background-color: #1e252c;
  position: relative;
  padding: 120px 0 120px;
  z-index: 2;
  min-height: 60vh;
}

.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 500px;
  color: #ffffff;
}

.backBtn {
  padding: 5px;
  border-radius: 50%;
  background-color: #373c49;
  border: none;
  position: absolute;
  top: 9px;
  left: 0;
}

.bacBtnIcon {
  font-size: 1rem;
}

.formContent {
  width: 100%;
  max-width: 415px;
  position: relative;
  padding: 0 15px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
}

.headingWrapper {
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

.heading1 {
  font-size: 1.3rem !important;
  margin: 0 !important;
}

.heading2 {
  font-weight: 300 !important;
}

.heading2,
.heading3 {
  font-size: 2rem !important;
  margin: 0 !important;
}

.colouredText {
  background: -webkit-linear-gradient(180deg, #b429f9, #26c5f3, #72efdd);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300 !important;
}

.errorWrapper {
  display: flex;
  align-items: center;
}

.errorText {
  font-size: 0.95rem !important;
  font-weight: 300 !important;
  color: #f87171 !important;
}

.popupContainer {
  position: absolute;
  top: 55px;
  align-self: flex-end;
  z-index: 2;
  left: 5%;
  right: 5%;
  width: 90%;
  display: flex;
  justify-content: center;
}

.popupError {
  width: 100%;
  max-width: 415px;
  display: flex;
  align-items: center;
  background-color: #f3ddb8;
  border-radius: 20px;
  padding: 8px 16px;
  border-left-width: 4px;
  border-left-style: solid;
}

.popupErrorText {
  transform: translateX(10px);
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  line-height: 16px !important;
  width: 80% !important;
}

.button {
  width: 100%;
  font-weight: 800;
  padding: 12px 30px;
  color: #ffffff;
  font-size: 16px;
  border: 0;
  border-radius: 20px;
  margin-top: 20px;
  background: linear-gradient(to right, #6201ea, #26c5f3);
}

.imgWrapper {
  width: 50%;
  background-color: #f5f6fb;
  border-bottom-left-radius: 60px;
  transform: translateX(60px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.img {
  width: 55%;
  position: absolute;
  right: -50px;
  top: 0;
}
.footer {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  background-color: #22007c;
  z-index: 10;
}

.validateEmailMsgContainer {
  width: 100%;
  max-width: 446px;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  background-color: #cdcfd0;
  color: black !important;
  margin: 170px 10px 90px;
}

.logoWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffffff;
}

.logoWrapper img {
  width: 30px;
}

.emailValidatedMsgContainer {
  background-color: #ffffff00;
  justify-content: center;
  margin: 0;
  padding: 120px 0 40px;
}

.welcomeImg {
  width: 50%;
  max-width: 400px;
}
.validateEmailMsgWrapper {
  width: calc(100% - 50px);
}

.validateEmailMsgheading {
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-bottom: 10px !important;
}

.validateEmailText {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  line-height: 20px !important;
}

/* Media Queries */
@media screen and (min-width: 400px) {
  .navWrapper {
    padding: 30px;
  }
  .factsWrapper {
    padding: 120px 30px 40px;
  }
  .formContent {
    padding: 0 30px;
  }
  .validateEmailMsgContainer {
    margin: 170px 30px 40px;
  }
  .emailValidatedMsgContainer {
    margin: 0;
  }
}

@media screen and (min-width: 600px) {
  .backBtn {
    left: -80px;
  }
  .heading1 {
    font-size: 1.8rem !important;
  transform: translateX(0);

  }
  .heading2, .heading3 {
  transform: translateX(0);

  }
  .popupContainer {
    top: 40px;
  }
  .validateEmailMsgContainer {
    padding: 20px;
    margin: 120px 0 40px;
  }
  .logoWrapper {
    width: 60px;
    height: 60px;
  }
  .logoWrapper img {
    width: auto;
  }
  .validateEmailMsgWrapper {
    width: calc(100% - 80px);
  }
  .validateEmailText {
    font-size: 1rem !important;
    line-height: 25px !important;
  }
}

@media screen and (min-width: 1024px) {
  .navWrapper {
    padding: 20px 40px;
  }

  .logo {
    width: auto;
  }

  .contentContainer {
    flex-direction: row;
  }

  .contentWrapper {
    width: 50%;
    min-height: 100vh;
  }
  .bgImg {
    width: 80%;
    min-height: 90vh;
  }

  .factsWrapper {
    margin-left: 50px;
    padding: 0;
  }

  .factsHeading {
    font-size: 1.8rem !important;
  }

  .infoText {
    font-size: 1.1rem !important;
  }

  .content {
    padding: 0;
  }

  .formContent {
    padding: 0;
  }

  .backBtn{
    left: -40px;
  }

  .heading1 {
    font-size: 2.45rem !important;
  }

  .heading2,
  .heading3 {
    font-size: 3.125rem !important;
  }

  .errorWrapper {
    display: flex;
    align-items: center;
  }

  .errorText {
    font-size: 0.95rem !important;
    font-weight: 400 !important;
    color: #f87171 !important;
  }

  .popupContainer {
    top: 90px;
   
  }

  .footer {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background-color: #22007c;
    z-index: 10;
  }

  .validateEmailMsgContainer {
    margin: 0;
  }

  .emailValidatedMsgContainer {
    padding: 0;
  }

  .welcomeImg {
    width: 100%;
  }
}

@media screen and (min-width: 1100px) {
  .backBtn{
    left: -60px;
  }
}

@media screen and (min-width: 1200px) {
  .backBtn{
    left: -80px;
  }
}
@media  screen and (max-width: 1600px){
  .formContainer{
    padding-top: 40px;
  }
}
@media  screen and (max-width: 1440px){
  .formContainer{
    padding-top: 50px;
  }
}
@media  screen and (max-width: 1366px){
  .formContainer .button {
    margin-top: 0px;
  }
  .headingWrapper .heading3{
    font-size: 2.9rem!important;
  }
  .headingWrapper{
    margin-bottom: 25px;
  }
  .formContainer{
    padding-top: 60px;
  }
  .factsBgImg{
    height: 100vh;
  }
  .popupErrorText{
    font-size: .85rem !important;
  }
  .popupError{
    width: 100%;
  }
  .headingWrapper  .heading1{
    font-size: 2.2rem!important;
  }
  .formWrapper .inputWrapper{
    margin-bottom: 17px;
  }
  .cirleIcon, .checkIcon, .dashIcon, .validationText, .notValid, .isValid{
    font-size: .9rem;
  }
  .input{
    font-size: 14px!important;
  }
}

@media  screen and (max-width: 1100px){
  .headingWrapper .heading3{
    font-size: 2.4rem!important;
    line-height: 40px!important;
  }
  .formWrapper .inputWrapper{
    margin-bottom: 10px;
  }
  .headingWrapper  .heading1{
    font-size: 2rem!important;
    line-height: 38px!important;
  }
  .cirleIcon, .checkIcon, .dashIcon, .validationText, .notValid, .isValid{
    font-size: .8rem!important;
  }
  .popupError{
    padding: 5px 15px;
  }
  .input{
    font-size: 13px!important;
  }
  .formContainer{
    padding-top: 80px;
  }
  .contentContainer{
    align-items: stretch;
  }
}
@media  screen and (max-height: 700px){
  .formContent{
    padding: 100px 0 80px;
    }
}
@media  screen and (max-height: 600px){
  .formContent{
  padding: 100px 0 70px;
  }
  .contentContainer{
    align-items: stretch;
  }

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
}

.landing-container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #050d15;
  color: #fff;
}

.navbar-container {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 50;
}

.navbar-container::before {
  position: absolute;
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #272e3450;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  width: 100%;
}

.navbar {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-self: center;
  flex-direction: column;
  z-index: 1;
  position: relative;
}

.logo {
  width: 180px;
  margin-left: 10px;
  z-index: 2
}

.navlinks-container,
.navlinks-container-close {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateX(0%);
  transition: all 0.3s ease-in-out;
}

.navlinks-container-close {
  transform: translateX(100%);
}

.navlinks-container::before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #272e3490;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

.navlinks-wrapper {
  z-index: 2;
  margin-top: 40px;
}

.nav-links {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #ffffff00;
  transition: border-bottom 0.3s ease-in-out;
}

.nav-links li {
  list-style-type: none;
}

.navlinks-container a:hover {
  border-bottom: 1px solid #ffffff50;
}

.btn-divider-wrapper {
  z-index: 2;
}

.btn-wrapper {
  width: 120px;
  height: 34px;
  border-radius: 20px;
  display: inline-block;
  margin: 20px 0;
  position: relative;
}

.btn-wrapper::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 34px;
  z-index: -1;
  inset: 0;
  padding: 1px;
  border-radius: 20px;
  background: linear-gradient(to right, #6200ea, #26c5f3);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;

}

.login-btn {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  position: relative;
  background-color: transparent;
}

.btn-wrapper2 {
  display: none;
}

.menu-icon-wrapper {
  position: absolute;
  right: 10px;
  margin-top: 4px;
}

.main {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  overflow-x: hidden;
}

.hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px;
}

.hero-bg {
  position: absolute;
  min-height: 100vh;
  top: -150px;
  right: -10px;
  z-index: 1;
}

.hero-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.img-cover {
  max-width: 50%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: calc(50% - 150px);
  margin-left: 150px;
}

.hero-img {
  max-height: 400px;
  height: auto;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: translateX(-30px);
}

.hero-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-text {
  font-weight: 900;
  color: #ffffff80;
  text-align: center;
  margin-top: -20px;
}

.hero-text em {
  color: #ffffff;
}

.join-btn {
  width: 100%;
  max-width: 524px;
  font-weight: 800;
  padding: 12px 30px;
  color: #fff;
  font-size: .8rem;
  border: 0;
  border-radius: 30px;
  margin: 50px 0;
  background: linear-gradient(90deg, #6201ea, #26c5f3);
}

.contact-btn {
  margin: 20px 0;
}

.hero-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 40px;
}

.gradient-line {
  display: block;
  background-image: linear-gradient(90deg, #ffffff00, #ffffff90, #ffffff00);
}

.hero-footer span {
  width: 100%;
  height: 1px;

}

.hero-footer p {
  text-align: center;
  padding: 30px 0;
  font-size: .9rem;
  font-weight: 400;
}



/* Media Queries */
@media screen and (min-width: 320px) {
  .logo {
    margin-left: 20px
  }

  .menu-icon-wrapper {
    right: 20px;
  }

  .hero {
    padding: 0 20px;
  }

  .hero-bg {
    right: -20px;
  }
}

@media screen and (min-width: 450px) {
  .logo {
    margin-left: 40px
  }

  .menu-icon-wrapper {
    right: 40px;
  }

  .hero-img {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 600px) {
  .logo {
    width: 250px;
    margin-left: 40px
  }

  .menu-icon-wrapper {
    margin-top: 10px;
  }

  .hero {
    padding: 0 30px;
  }

  .hero-bg {
    right: -30px;
  }

  .join-btn,
  .hero-footer p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    flex: 1 1;
  }

  .logo {
    width: 200px;
    margin-left: 0;
  }

  .navlinks-container,
  .navlinks-container-close {
    width: auto;
    position: static;
    min-height: auto;
    transform: translateX(0%);
    align-items: center;
    flex-direction: row;
  }

  .navlinks-container::before {
    display: none;
  }

  .navlinks-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    flex-direction: row;
    align-items: center;
    margin-top: 0
  }

  .nav-links {
    margin: 0 5px;
    width: 18%;
  }

  .nav-links a {
    margin: 0;
    font-size: .85rem;
    color: #ffffff90;
  }

  .btn-divider-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .btn-line {
    display: inline-block;
    height: 25px;
    width: 0.5px;
    border: 0.5px solid #ffffff30;
    margin: 0px 15px 0;
  }

  .menu-icon-wrapper {
    display: none;
    width: 100%;
    justify-content: space-between;
  }

  .main {
    padding: 100px 0;
  }

  .hero {
    align-self: center;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 40px;
  }

  .hero-bg {
    right: -40px;
  }

  .hero-top {
    width: 100%;
    flex-direction: row;
    margin-top: 50px;
  }

  .hero-img {
    width: 100%;
    max-height: 400px;
  }

  .hero-text-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 40px;
  }

  .hero-text {
    text-align: right;
    font-size: 2.5rem;
    margin-top: 0;
  }

  .hero-footer p {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1160px) {
  .navbar {
    padding: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .navbar {
    padding: 20px 40px;
  }

  .logo {
    width: 270px;
  }

  .nav-links a {
    font-size: .9rem;
  }
}

@media screen and (min-width: 1300px) {
  .hero-text {
    font-size: 3rem;
  }

}

@media screen and (min-width: 1320px) {
  .nav-links {
    width: auto;
  }
}

@media screen and (min-width: 1400px) {
  .hero-text-wrapper {
    width: 60%;
    margin-right: 100px;
  }

  .hero-text {
    font-size: 3rem;
  }

}

@media screen and (min-width: 1600px) {
  .hero-top {
    max-width: 1500px;
  }


  .hero-text-wrapper {
    max-width: 600px;
    margin-right: 150px;
  }
}
.section {
    width: 100%;
    max-width: 1500px;
    align-self: center;
    scroll-margin-top: 100px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    margin: 50px 0 100px;
    padding: 0 10px;
}
.about-us{
    margin-bottom: 0px;
}
.about-top,
.section-content,
.intro,
.info-text-wrapper,
.feature-intro-wrapper,
.feature-outline,
.feature-wrapper,
.feature-info,
.feature-outline2,
.feature-intro-wrapper2,
.feature3-info-wrapper,
.feature4-wrapper,
.feature4-outline {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title-btn {
    background-color: #1e252c;
    border-radius: 30px;
    width: 170px;
    border: 0;
    color: #ffffff90;
    align-self: flex-end;
    margin: 0 0 50px;
    text-align: center;
    padding: 8px 0;
    font-size: .9rem;
    box-shadow: 0 0 10px #000;
}

.about-heading {
    font-weight: 900;
    font-size: 1.5rem;
    font-style: italic;
    margin-bottom: 10px;
}

.gradient-text {
    background-clip: text;
    background-image: linear-gradient(90deg, #72efdd, #26c5f3, #b429f9 30%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
    font-size: 1.7rem;
    font-weight: 400;
}

.gradient-text span {
    text-transform: uppercase;
    font-weight: 300;
}

.glowing-text {
    align-self: flex-end;
    font-size: 1rem;
    text-align: right;
    text-shadow: 0 0 10px #fff;
    font-weight: 400;
    font-style: italic;
}

.vertical-divider {
    width: 0.5px;
    height: 70px;
    align-self: center;
    background-color: #ffffff30;
    margin: 20px 0;
}

.info-text-wrapper {
    align-self: center;
    align-items: center;
    justify-content: center;
}

.info-text {
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: 300;
}

.info-text span {
    font-weight: 700;
    font-size: 1.2rem;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff50;
}

.info-gradient1 {
    background-image: linear-gradient(90deg, #5591f550, #b429f950);
}

.info-gradient2 {
    background-image: linear-gradient(90deg, #b429f950, #5591f550);
}

.info-gradient3 {
    background-image: linear-gradient(180deg, #5591f550, #b429f950);
}

.phone-icon {
    align-self: flex-end;
    margin-top: 50px;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: -35px;
}

/* Features Styles */
.feature-heading {
    text-align: center;
    margin-bottom: 20px;
}

.feature-intro-wrapper {
    margin-top: -50px;
}

.feature-info-text {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 5px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 275;
}

.feature-info-text:nth-child(2) {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 900;
    font-family: "Raleway", sans-serif !important;
}

.feature-outline {
    background-image: url(/static/media/featuresBgImg.f47d9f0c.svg);
    background-repeat: no-repeat;
    background-position: center -90px;
    background-size: contain;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
}

.feature-wrapper {
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    max-width: 360px;
}

.icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    align-self: center;
    background-image: linear-gradient(180deg, #1a2129, #08101850);
    margin-bottom: 20px;
}

.feature-icon {
    width: 25px;
    height: 25px;
}

.feature-info {
    align-items: center;
    justify-content: center;
    max-width: 340px;
}

.feature-info h3 {
    font-size: 1rem;
    font-weight: 900;
    width: 170px;
    text-align: center;
}

.feature-info p {
    font-size: .9rem;
    font-weight: 300;
    text-align: center;
    margin-top: 10px;
    height: 85px;
}

.feature2-section {
    width: 100%;
    display: flex;
}

.feature2content {
    max-width: auto;
}

.feature-intro-container2 {
    background-image: url(/static/media/feature2Img.83958fc9.svg);
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.feature-intro-wrapper2 {
    align-items: flex-end;
}

.feature-info-text2 {
    text-align: right;
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 300;
}

.feature-info-text2:nth-child(2) {
    font-size: 1.4rem;
    font-weight: 900;
}

.feature-outline2 {
    align-items: center;
    justify-content: space-between;
}

.feature-wrapper2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.check-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.feature-wrapper2 p,
.feature-wrapper2 svg {
    color: #72efdd;
}

.feature-wrapper2 svg {
    box-shadow: 0 10px 10px #72efdd;
}

.feature3-section {
    margin: 70px 0 70px;
}

.feature3-heading {
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: -30px;
}

.feature3-info-wrapper p {
    text-align: center;
    font-weight: 300;
}

.feature3-info-wrapper p:nth-child(1) {
    font-weight: 900;
    font-size: 1.5rem;
    margin: -30px 0 10px;
}

.feature3-info-wrapper p:nth-child(2) {
    max-width: 600px;
    align-self: center;
}

.small-info-text {
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 10px;
}

.small-info-text:last-child {
    font-weight: 600;
    width: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #b429f9 30%, #ffffff, #5591f5 70%);
}

.feature4-section {
    position: relative;
    margin: 0 0 150px;
}

.feature4-bg {
    position: absolute;
    left: -10px;
}

.feature4-text h2 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 28px;
    text-align: center;
}

.feature4-outline {
    justify-content: center;
    align-self: center;
    align-items: center;
}

.feature4 {
    display: flex;
    align-items: flex-end;
    margin: 20px 0;
    width: 240px;
}

.feature-icons {
    width: 90px;
    margin-right: 10px;
}

.feature4 p {
    font-size: .8rem;
    font-weight: 500;
    margin-bottom: 3px;
}


/* Media Queries */
@media screen and (min-width: 320px) {
    .section {
        padding: 0 20px;
    }

}

@media screen and (min-width: 360px) {
    .feature3-heading {
        margin-bottom: -40px;
    }
}

@media screen and (min-width: 600px) {
    .section {
        padding: 0 30px;
    }

    .gradient-text {
        font-size: 2.5rem;
    }

    .glowing-text {
        font-size: 1.1rem;
        max-width: 520px;
        align-self: flex-start;
        margin-left: 170px;
    }

    .info-text {
        font-size: 1.1rem;
    }

    .info-text span {
        font-size: 1.4rem;
    }

    .feature-info-text {
        font-size: 1.2rem;
    }

    .feature-info-text:nth-child(2) {
        font-size: 1.5rem;
    }

    .feature-intro-container2,
    .feature-outline2 {
        padding: 0 30px;
    }

    .feature-outline2 {
        flex-direction: row;
        padding: 20px;
    }

    .feature-info-text2 {
        font-size: 1.2rem;
    }

    .feature-info-text2:nth-child(2) {
        font-size: 1.6rem;
    }

    .feature-info-text2:nth-child(3) {
        max-width: 500px;
    }

    .feature3-heading {
        margin-bottom: -100px;
    }

    .adaptive-img {
        width: 500px;
        height: 400px;
        align-self: center;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -100px 0 10px;
        font-size: 2rem;
    }
}

@media screen and (min-width: 650px) {
    .feature-info-text2 {
        font-size: 1.4rem;
    }

    .feature-info-text2:nth-child(2) {
        font-size: 2rem;
    }
}

@media screen and (min-width: 768px) {
    .feature-info-text2 {
        font-size: 1.4rem;
    }

    .feature-info-text2:nth-child(2) {
        font-size: 2rem;
    }

    .feature-info-text2:nth-child(3) {
        max-width: 550px;
    }
    .feature4-section {
        justify-content: center;
    }
    .feature4-wrapper, .feature4-section {
        flex-direction: row;
        align-items: center;
    }
    .feature4-wrapper{
        max-width: 1000px;
        align-self: center;
        justify-content: space-between;
    }
    .feature4-text {
        max-width: 450px;
    }
    .feature4-text h2 {
        text-align: left;
    }
    .feature4-outline {
        width: auto;
    }
    .feature4{
        margin: 10px 0;
    }
}

@media screen and (min-width: 900px) {
    .gradient-text {
        font-size: 3rem;
    }

    .glowing-text {
        font-size: 1.2rem;
    }

    .info-text {
        font-size: 1.2rem;
    }

    .info-text span {
        font-size: 1.6rem;
    }

    .feature3-section {
        margin: 120px 0 70px;
    }

    .feature3-heading {
        margin-bottom: -200px;
    }

    .adaptive-img {
        width: 800px;
        height: 700px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -200px 0 10px;
    }
}

@media screen and (min-width: 1024px) {
    .section {
        padding: 0 40px;
        scroll-margin-top: 140px;
    }

    .section-content {
        max-width: 838px;
        align-self: center;
    }

    .info-text,
    .info-text span {
        letter-spacing: 1px;
    }

    .feature-content {
        max-width: 1100px;
    }

    .features-img {
        width: 70%;
        align-self: center;
    }

    .feature-heading {
        font-size: 1.1rem;
    }

    .feature-intro-wrapper {
        margin-top: -50px;
    }

    .feature-info-text {
        font-size: 1.875rem;
    }

    .feature-info-text:nth-child(2) {
        font-size: 3.75rem;
    }

    .feature-outline {
        flex-direction: row;
        justify-content: space-between;
    }

    .feature2-content {
        max-width: 100%;
    }

    .feature-intro-wrapper2 {
        max-width: 1400px;
    }

    .feature-intro-container2,
    .feature-outline2 {
        padding: 0 40px;
    }

    .feature-info-text2:nth-child(2) {
        font-size: 2.5rem;
    }

    .feature-outline2 {
        margin-top: 40px;
    }

    .feature3-section {
        margin: 200px 0 70px;
    }

    .feature3-heading {
        margin-bottom: -230px;
        font-size: 1.3rem;
    }

    .adaptive-img {
        width: 1000px;
        height: 800px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -230px 0 10px;
        font-size: 2.5rem;
    }

    .feature3-info-wrapper p:nth-child(2) {
        font-size: 1.2rem;
    }
    .feature4-text h2 {
        font-size: 1.5rem;
        line-height: 40px;
        width: 560px;
    }
    .feature4{
        width: 300px;
    }
    .feature-icons {
        width: 110px;
    }
    .feature4 p{
        font-size: .9rem;
    }
}

@media screen and (min-width: 1100px) {
    .feature-intro-container2 {
        height: 500px;
    }

    .feature-outline2 {
        max-width: 1150px;
        align-self: center;
        margin-top: 60px;
    }

    .feature3-heading {
        margin-bottom: -170px;
        font-size: 1.3rem;
    }

    .adaptive-img {
        width: 100%;
        height: auto;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -180px 0 10px;
    }
}

@media screen and (min-width: 1200px) {
    .feature3-heading {
        margin-bottom: -200px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -200px 0 10px;
    }
   
    
}

@media screen and (min-width: 1300px) {
    .feature3-heading {
        margin-bottom: -220px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -220px 0 10px;
    }

}

@media screen and (min-width: 1400px) {
    .feature3-heading {
        margin-bottom: -230px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -240px 0 10px;
    }
    .phone-icon{
        margin-right: -60px;
    }
}

@media screen and (min-width: 1500px) {
    .feature3-heading {
        margin-bottom: -250px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -260px 0 10px;
    }
    .feature4-bg{
        left: -100px;
    }
}
@media screen and (min-width: 1670px) {
    .feature4-bg{
        left: -25%;
    }
}
.school-section {
    margin: 0;
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
    position: relative;
}

.section-wrapper,
.school-heading,
.partners-wrapper,
.partner-group,
.partner-info,
.partner-group-wrapper,
.testimonial-container,
.testimonials-container,
.testimonials-wrapper,
.testimonial-wrapper,
.testimonial,
.pricing-content-container,
.category-card-container,
.card-top,
.card-bottom,
.cat-features-wrapper,
.pricing-container,
.landing-page-footer,
.footer-top,
.footer-links-container,
.footer-links-wrapper,
.motto,
.social-links-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.partners-info,
.partner-group-wrapper,
.partner-btn-wrapper,
.school-heading,
.testimonials-container {
    padding: 0 10px;
}
.partners-info .gradient-text{
    font-size: inherit;
}
.section-container {
    background-image: url(/static/media/schoolBg.5d88d445.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-basis: 100%;
}

.school-btn {
    position: relative;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.section-wrapper {
    z-index: 2;
    padding: 80px 0 50px;
}

.school-heading h2 {
    text-align: center;
}

.school-heading h2:nth-child(1) {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2.5rem;
    letter-spacing: 5px;
}

.school-heading h2:nth-child(2) {
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    text-shadow: 0 0 3px #fff;
    margin: 10px 0 40px;
}

.partners-info {
    font-size: 1rem;
    line-height: 35px;
}

.partner-gradient-text {
    font-size: 1rem;
    font-weight: 600;
    -webkit-text-fill-color: #ffffff60;
    background-image: linear-gradient(90deg, #72efdd, #26c5f3, #b429f950);
}

.partners-wrapper {
    justify-content: space-between;
    margin: 30px 0;
    position: relative;
}

.partner-group {
    flex-direction: row;
    justify-content: space-between;
}

.pulse-bg {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.partner-group-wrapper {
    padding: 0 10px;
}

.partner {
    width: 100px;
    height: 50px;
}

.partner-info {
    margin: 25px 0;
}

.number {
    font-family: "Poppins", sans-serif !important;
    font-size: 3.5rem;
    font-weight: 500;
    text-shadow: 0 0 5px #fff;
}

.partner-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.student-container {
    background-image: url(/static/media/studentBg.0c42ad52.svg);
}

.student-wrapper {
    background-image: linear-gradient(180deg, #2583cb34, #2341a367);
}

.student-gradient-text {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #72efdd, #26c5f3 40%);
}

.curvesection {
    position: relative;
    background-image: url(/static/media/gradientbg.53c6bfa9.svg);
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: ellipse(120% 50% at 50% 50%);
    background-position: 50% 50%;
    display: flex;
    flex-wrap: wrap;
}

.curvesection #school {
    order: 1;
    padding-top: 80px;
}

.curvesection #student {
    order: 2;
}

.curvesection #government_agency {
    order: 3;
    padding-bottom: 30px;
}

.govt-container {
    background-image: url(/static/media/governmentBg.0fa04909.svg);
}

.govt-wrapper {
    background-image: linear-gradient(180deg, #2341a367, #22007c50);
}

.govt-gradient-text {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #72efdd, #26c5f3 40%);
}

.testimonial-section {
    margin: 130px 0;
}

.testimonials-container {
    z-index: 2;
}

.testimonial-info h2,
.testimonial-info p {
    text-align: center;
}

.testimonial-info h2 {
    font-weight: 900;
    font-size: 2rem;
    line-height: 50px;
}

.testimonial-info p {
    margin: 20px 0 50px;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 25px;
}

.testimonial-wrapper {
    max-width: 600px;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: relative;
    padding: 30px;
    margin: 20px 0;
    border-radius: 20px;
    background-color: #21272d95;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}

.testimonial-wrapper::before {
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}

.testimonial {
    align-items: flex-start;
}

.quote {
    width: 20px;
    height: 20px;
    margin: 20px 0 5px;
}

.testimonial-text {
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: 300;
    line-height: 20px;
}

.testifier-name,
.testifier-title {
    font-size: 1rem;
}

.testifier-name {
    font-weight: 900;
}

.testifier-title {
    font-weight: 200;
}

.pricing-content-container {
    max-width: 1400px;
    align-self: center;
    padding-top: 80px;
}

.pricing-section {
    max-width: 100%;
    position: relative;
    background-image: linear-gradient(180deg, #22007c08, #0f172a50, #22007c08);
}
.highlight-text{
    background-image: linear-gradient(130deg, #72efdd, #26c5f3, #b429f9 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.pricing-content {
    z-index: 2;
    max-width: 100%;
}

.pricing-heading,
.pricing-heading2 {
    font-size: 1.3rem;
    text-align: center;
}

.pricing-heading {
    font-weight: 400;
}

.pricing-heading2 {
    font-weight: 700;
    margin-top: 20px;
}

.pricing-category-select {
    width: 100%;
    max-width: 400px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    background-color: #050d15;
    border: 1px solid #32276b;
    border-radius: 30px;
    padding: 0 10px;
    margin: 30px 0;
}

.pricing-btn-wrapper,
.pricing-btn-wrapper2 {
    width: auto;
    height: 30px;
    border-radius: 20px;
    background-image: linear-gradient(to right, #6200ea, #26c5f3);
    padding: 1px;
    display: inline-block;
    margin: 5px 0;
    z-index: 2;
    transition: all 0.3s ease-in-out;
}

.pricing-btn-wrapper2 {
    background-image: linear-gradient(to right, #6200ea00, #26c5f300);
}

.pricing-btn {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #050d15;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 0 10px;
    border: 0;
    transition: all 0.5s ease-in-out;
}
.cat-Advanced .pricecatAdvanced .card-top, .categoryprice:nth-child(2) .pricecatAdvanced .card-top{
    background-color: #590085;
}
.cat-Advanced .pricecatAdvanced .card-bottom, .categoryprice:nth-child(2) .pricecatAdvanced .card-bottom{
    background-color: rgb(67, 17, 91);
}

.categoryprice.order1 .pricecatAdvanced .card-top, .categoryprice.order3 .pricecatAdvanced .card-top,
.categoryprice.order1 .pricecatAdvanced .card-bottom, .categoryprice.order3 .pricecatAdvanced .card-bottom{
    background-color: rgba(0, 0, 0, 0.3);
}
.cat-Advanced .pricecatAdvanced .card-icon-wrapper, .categoryprice:nth-child(2) .pricecatAdvanced .card-icon-wrapper {
    width: 80px;
    height: 80px;
    margin-top: -70px;
}
.categoryprice.order1 .pricecatAdvanced .card-icon-wrapper, .categoryprice.order3 .pricecatAdvanced .card-icon-wrapper {
    width: 70px;
    height: 70px;
    margin-top: -75px;
}
.cat-Essential{
    order: 1;
    max-width: 322px;
    flex-basis: 30%;
}
.cat-Advanced{
    order: 2;
    margin-top: -140px;
    max-width: 328px;
    flex-basis: 31%;
}
.cat-Professional{
    order: 3;
    max-width: 318px;
    flex-basis: 30%;
}
.categoryprice.order2 .pricecatEssential .card-top {
    background-color: rgb(58, 184, 135);
}

.categoryprice.order2 .pricecatAdvanced .card-top {
    background-color: #590085;
}

.categoryprice.order2 .pricecatProfessional .card-top {
    background-color: #170080;
}

.categoryprice.order2 .pricecatEssential .card-bottom {
    background-color: rgb(50, 113, 88);
}

.categoryprice.order2 .pricecatAdvanced .card-bottom {
    background-color: rgb(67, 17, 91);
}

.categoryprice.order2 .pricecatProfessional .card-bottom {
    background-color: #120062;
    padding: 30px 40px;
}

.categoryprice.order2 .card-icon-wrapper {
    width: 80px;
    height: 80px;
    margin-top: -70px;
}

.category-card-container {
    margin-top: 120px;
    max-width: 100%;
}

.card-wrapper,
.card-wrapper-active {
    width: 100%;
    height: 580px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 50px;
    transition: all 0s ease-in-out;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card-wrapper-active {
    border: 1px solid #43115b00;
}

.card-wrapper::before,
.card-wrapper-active::before {
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 1px solid #43115b00;
}

.card-wrapper-active-school,
.card-wrapper-active-student,
.card-wrapper-active-govt {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pricecatEssential,
.pricecatAdvanced,
.pricecatProfessional {
    border-radius: 20px;
    width: 100%;
}

.pricecatEssential, .categoryprice.order1 .pricecatEssential, .categoryprice.order3 .pricecatEssential {
    border: 1px solid rgba(114,239, 221,.2);
    background-color: rgba(0, 0, 0, 0.3);
}
.pricecatAdvanced, .pricecatAdvanced, .categoryprice.order1 .pricecatAdvanced, .categoryprice.order3 .pricecatAdvanced {
    border: 1px solid rgba(161,38, 222, .2);
    background-color: rgba(0, 0, 0, 0.3);
}
.pricecatProfessional, .categoryprice.order1 .pricecatProfessional, .categoryprice.order3 .pricecatProfessional {
    border: 1px solid rgba(45, 0, 247, .2);
    background-color: rgba(0, 0, 0, 0.3);
}
.pricecatEssential .cat-name {
    border: 2px solid #72efdd;
    color: #72efdd;
}

.pricecatAdvanced .cat-name {
    border: 2px solid #a126de;
    color: #a126de;
}

.pricecatProfessional .cat-name {
    border: 2px solid #2d00f7;
    color: #2d00f7;
}

.card-top {
    padding: 30px 0;
    border-radius: 20px 20px 0 0;
}

.card-icon-wrapper,
.card-icon-wrapper-active {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -70px;
    transition: all 0s ease-in-out;
}

.pricecatEssential .card-icon {
    width: 30px;
    height: 30px;
    background-image: url(/static/media/lock.0a499bea.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.pricecatAdvanced .card-icon {
    width: 30px;
    height: 30px;
    background-image: url(/static/media/star.f5db9343.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.pricecatProfessional .card-icon {
    width: 30px;
    height: 30px;
    background-image: url(/static/media/diamond.0617c645.svg);
    background-repeat: no-repeat;
    background-size: contain;
}
.cat-Advanced .card-icon, .order2 .card-icon{
    width: 40px;
    height: 40px;
}
.order1 .card-icon, .order3 .card-icon{
    width: 30px;
    height: 30px;
}

.pricecatEssential .card-icon-wrapper {
    background-color: #72efdd;
    box-shadow: 0 10px 23px 3px #72efdd5c;
}
.cat-Advanced .horizontal-line{
display: none;
}
.pricecatEssential .horizontal-line {
    background-color: rgba(114, 239, 221, 0.25);
}

.order1 .pricecatAdvanced .horizontal-line, .order3 .pricecatAdvanced .horizontal-line  {
    background-color: rgba(45, 0, 247, 0.25);
    display: block;
}

.pricecatProfessional .horizontal-line {
    background-color: rgba(45, 0, 247, 0.25);
}

 .categoryprice.order2 .horizontal-line {
    display: none;
}

.pricecatAdvanced .card-icon-wrapper {
    background-color: #a126de;
    box-shadow: 0 10px 23px 3px #a126de7a;
}

.pricecatProfessional .card-icon-wrapper {
    background-color: rgb(45, 0, 247);
    box-shadow: 0 10px 23px 3px #2d00f79c;
}

.pricecatEssential .cat-feature-icon {
    color: rgb(114, 239, 221);
}

.pricecatAdvanced .cat-feature-icon {
    color: rgb(180, 41, 249);
}

.pricecatProfessional .cat-feature-icon {
    color: rgb(45, 0, 247);
}

.categoryprice.order1 {
    order: 1;
    margin-top: 0px;
    max-width: 322px;
    flex-basis: 30%;
}

.categoryprice.order2 {
    order: 2;
    margin-top: -140px;
    max-width: 328px;
    flex-basis: 31%;
   
}

.categoryprice.order3 {
    order: 3;
    margin-top: 0px;
    max-width: 318px;
    flex-basis: 30%;
}

.cat-Essential .cat-features, .cat-Professional .cat-features, .order1 .cat-features, .order3 .cat-features{
    font-size: 14px;
    opacity: .7;
}
.order2 .cat-features{
    font-size: 16px;
    opacity: 1;
}
.ideal {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin: 20px 20px 0;
}
.cat-Essential .ideal, .cat-Professional .ideal, .order1 .ideal, .order3 .ideal{
   opacity: .6;
   margin: 15px 20px 0;
   font-size: 14px;
}
.order2 .ideal{
    opacity: 1;
    margin: 20px 20px 0px;
    font-size: 16px;
}
.cat-Essential .cat-price, .cat-Professional .cat-price, .order1 .cat-price, .order3 .cat-price{
    opacity: .8;
    font-size: 1.4rem;
 }
 .order2 .cat-price{
    opacity: 1;
    font-size: 2rem;
 }
 .cat-Essential .cat-name, .cat-Professional .cat-name, .order1 .cat-name, .order3 .cat-name,
 .order1.cat-Advanced .cat-name ,  .order3.cat-Advanced .cat-name{
   font-size: .7rem;
 }
 .order2 .cat-name, .cat-Advanced .cat-name{
    font-size: .9rem;
 }

 .order2 .card-bottom, .cat-Advanced .card-bottom{
    padding: 30px 30px;
 }
 .order1.cat-Advanced .card-bottom, .order3.cat-Advanced .card-bottom{
    padding: 20px 20px;
 }
.cat-name {
    padding: 3px 10px;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 0.9rem;
    margin: 20px 0;
}

.price {
    display: flex;
    align-items: flex-start;
}

.cat-price {
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    font-size: 2rem;
    margin: 0 30px;
    text-align: center;
}

.price p:nth-child(2) {
    font-family: "Poppins", sans-serif !important;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-top: 9px;
    letter-spacing: 1px;
}



.horizontal-line {
    width: 80%;
    height: 1px;
    margin: 0 10%;
    display: inline-block;
}

.card-bottom {
    padding: 20px 20px;
    border-radius: 0 0 20px 20px;
    position: relative;
}

.card-bottom-active::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #00000030;
}

.cat-features-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    margin: 7px 0;
}
.cat-Advanced .cat-features-wrapper, .order2 .cat-features-wrapper {
    margin: 10px 0;
}
.order1.cat-Advanced .cat-features-wrapper, .order3.cat-Advanced .cat-features-wrapper{
    margin: 7px 0;
}
.cat-feature-icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.cat-features {
    width: 80%;
    font-size: 16px;
}

.landing-page-footer {
    background-color: #050d15;
    border-radius: 50px 50px 0 0;
    border-top: 1px solid white;
    padding: 30px 0;
    height: auto;
    position: static;
}

.footer-top {
    padding: 0 30px 0;
    justify-content: space-between;
}

.footer-links-container {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    max-width: 700px;
}

.footer-links-wrapper {
    margin: 20px 0;
    max-width: 230px;
}

.links-heading {
    width: 100%;
    font-size: 1.2rem;
    color: #5591f5;
    margin-bottom: 10px;
    text-align: center;
}

.footer-link,
.footer-link a {
    width: 100%;
    align-self: center;
    color: #ccdefc;
    font-size: 1rem;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

.footer-link {
    list-style-type: none;
}

.footer-link a {
    text-decoration: none;
}

.motto {
    max-width: 350px;
}

.footer-logo {
    margin: 0;
    width: 240px;
    margin-bottom: 30px;
}

.motto p {
    line-height: 30px;
    font-size: 1.1rem;
    text-align: center;
}

.footer-divider {
    display: block;
    margin: 50px 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, #ffffff05, #72efdd50, #ffffff05);
}

.social-links-container {
    justify-content: space-between;
    padding: 0 20px;
}

.social-links {
    width: 100%;
    max-width: 300px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footnote {
    text-align: center;
    margin-bottom: 30px;
}
.pricing-bg {
    width: 70%;
    max-width: 70%;
}
.cat-Essential.order2 .pricebgtype{
    position: absolute;
    width: 1000px;
    height: 1000px;
    background-image: url(/static/media/pricingBg.c85a0889.svg);
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-top: -220px;
    left: -250px;
}

.cat-Advanced .pricebgtype, .cat-Advanced.order2 .pricebgtype{
    position: absolute;
    width: 1000px;
    height: 1000px;
    background-image: url(/static/media/pricingBg2.fa4b3d33.svg);
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-top: -220px;
    left: -250px;
}

.cat-Professional.order2 .pricebgtype{
    position: absolute;
    width: 1000px;
    height: 1000px;
    background-image: url(/static/media/pricingBg3.c8a8b230.svg);
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-top: -220px;
    left: -250px;
}
.cat-Advanced.order1 .pricebgtype,  .cat-Advanced.order3 .pricebgtype{
    background-image: none;
}
/* Media Queries */
@media screen and (min-width: 375px) {

    .partners-info,
    .partner-group-wrapper,
    .partner-btn-wrapper,
    .school-heading,
    .testimonials-container {
        padding: 0 20px;
    }

    .school-btn {
        right: 20px;
    }
}

@media screen and (min-width: 450px) {

    .partners-info,
    .partner-group-wrapper,
    .partner-btn-wrapper,
    .school-heading,
    .testimonials-container {
        padding: 0 30px;
    }

    .school-btn {
        right: 30px;
    }
}

@media screen and (min-width: 600px) {
    .testimonial-wrapper {
        flex-direction: row;
        padding: 30px;
    }

    .quote {
        margin: 0 0 5px;
    }

    .testimonial-text {
        max-width: 90%;
    }

    .social-links-container {
        flex-direction: row;
        align-items: center;
    }

    .social-links {
        margin-top: 0;
    }

    .footnote {
        margin: 0;
    }
}

@media screen and (min-width: 768px) {
    .partners-info {
        max-width: 900px;
    }

    .partners-wrapper,
    .partner-group-wrapper {
        flex-direction: row;
    }

    .partners-wrapper {
        justify-content: center;
    }

    .partner-group-wrapper {
        max-width: 1100px;
        align-self: center;
    }
}

@media screen and (min-width: 1024px) {
    .school-btn {
        right: 40px;
    }

    .school-heading h2:nth-child(1) {
        font-size: 4rem;
    }

    .school-heading h2:nth-child(2) {
        font-size: 1.4rem;
        margin: 20px 0 50px;
    }

    .partners-info,
    .partner-group-wrapper,
    .partner-btn-wrapper,
    .school-heading,
    .testimonials-container {
        padding: 0 40px;
    }

    .partners-info {
        font-size: 1.3rem;
        line-height: 45px;
        text-align: justify;
    }

    .partner-group {
        max-width: 30%;
    }

    .testimonials-container {
        max-width: 1500px;
        align-self: center;
    }

    .testimonials-wrapper {
        flex-direction: row;
        justify-content: space-between;
    }

    .testimonial-info {
        max-width: 500px;
    }

    .testimonial-info h2,
    .testimonial-info p {
        text-align: left;
    }

    .testimonial-info h2 {
        font-size: 2.6rem;
        line-height: 70px;
    }

    .testimonial-info p {
        font-size: 1.3rem;
        line-height: 30px;
    }

    .testimonial-container {
        align-items: flex-end;
    }

    .quote {
        width: 28px;
        height: 28px;
    }

    .category-card-container {
        max-width: 1100px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 120px;
    }

    .card-wrapper {
        display: flex;
        width: 30%;
    }

    .pricing-btn {
        font-size: 1rem;
    }

    .pricing-heading,
    .pricing-heading2 {
        font-size: 1.6rem;
    }

    .pricing-heading2 {
        margin: 10px 30px;
    }

    .pricing-bg {
        width: 70%;
        max-width: 50%;
    }

    .footer-top {
        max-width: 80%;
    }

    .social-links-container {
        max-width: 80%;
    }
}

@media screen and (min-width: 1100px) {
    .partner-group {
        max-width: 26%;
    }

    .testimonial-bg {
        margin-top: 150px;
    }

    .testimonial-info h2 {
        font-size: 3rem;
    }

    .testimonial-info p {
        font-size: 1.4rem;
    }
    .cat-price{
        margin: 0 20px;
    }
    .ideal{
        margin: 20px 20px 0;
    }

}

@media screen and (min-width: 1120px) {

    /* .landing-page-footer{
        padding: 100px 0;
    } */
    .footer-top {
        flex-direction: row;
        max-width: 80%;
        align-self: center;
    }

    .footer-links-wrapper {
        align-items: flex-start;
    }

    .links-heading,
    .footer-link,
    .footer-link a {
        text-align: left;
    }

    .footer-logo {
        align-self: flex-end;
    }

    .motto p {
        text-align: right;
    }
}

@media screen and (min-width: 1520px) {
    .school-btn {
        right: 150px;
    }
}

@media screen and (min-width: 1600px) {
    .school-btn {
        right: 250px;
    }
}
.faqBg {
    margin: auto;
    background-image: url(/static/media/faqBg.f2491cfc.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.faq-content-container h2 {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 40px;
}

.faq-content-container,
.faq-wrapper,
.faq-content-wrapper,
.faq-content,
.faq-question-wrapper,
.faq-question,
.contact-container,
.contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.faq-content-container {
    align-items: flex-start;
}

.faq-content-wrapper {
    height: 472px;
}

.faq-content {
    margin: 20px 0;
}

.faq-question {
    flex-direction: row;
    justify-content: space-between;
}

.faq-question p:nth-child(1) {
    width: 90%;
    font-weight: 600;
}

.faq-arrow,
.faq-arrow-active {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #212a31;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.faq-arrow-active {
    background-image: linear-gradient(90deg, #6200EA, #26C5F3);
    box-shadow: 0 0 5px 1px #6200EA;
}

.gradient-border {
    width: 100%;
    display: block;
    height: 1px;
    margin-top: 10px;
    background-image: linear-gradient(90deg, #26C5F3, #26C5F315);
}

.faq-answer,
.faq-answer-active {
    width: 95%;
    align-self: flex-end;
    font-weight: 300;
    font-size: 1rem;
    line-height: 20px;
    padding: 20px 0 0;
    transition: all 0.5s ease-in-out;
}

.faq-answer {
    display: none;
}

.faqNumber {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70px;
}

.faq-pageNo,
.faq-pageNo-active {
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff70;
    font-family: "Poppins", sans-serif;
    cursor: default;
}

.faq-pageNo-active {
    color: #ffffff;
    border-bottom: 1px solid #26C5F3;
}

.contact-container {
    align-items: flex-start;
    margin: 50px 0;
    scroll-margin-top: 150px;
}

.contact-heading h2:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 700;
}

.contact-heading h2:nth-child(2) {
    font-size: 2rem;
    font-weight: 900;
}

.contact-form {
    width: 100%;
    margin: 30px 0;
    max-width: 413px;
    align-self: flex-start;
}

.contact-input-wrapper {
    width: 100%;
}

.contact-input-wrapper:not(:last-of-type) {
    margin: 0 0 20px 0;
}

.contact-label {
    width: 100%;
    display: block;
    font-weight: 700;
}

.contact-input {
    width: 100%;
    color: #fff;
    background-color: white;
    background-color: #050d1550;
    outline: 0;
    padding: 14px 20px;
    border: .5px solid #ffffff08;
    border-radius: 20px;
    margin-top: 20px;
    font-weight: 500;
    font-size: 0.875rem;
    transition: all -.5s ease-in-out;
}

.contact-input::-moz-placeholder {
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-weight: 200;
    line-height: 30px;
    color: #ffffff;
}

.contact-input::placeholder {
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-weight: 200;
    line-height: 30px;
    color: #ffffff;
}

.contact-input:focus {
    border: .9px solid #ffffff;
}

.error-message,
.error-message-active {
    color: #F87171;
    display: none;
    opacity: 0;
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
    font-size: 14px;
}

.error-message-active {
    display: block;
    opacity: 1;
}

.error-label-active {
    color: #F87171;
}

.error-style-active {
    border: .5px solid #F87171;
}
.error-label-active .contact-input{
    border: .5px solid #F87171;
}


@media screen and (min-width: 768px) {

    .faq-content-container h2:nth-child(1),
    .contact-heading h2:nth-child(2) {
        font-size: 2.3rem;
    }
}

@media screen and (min-width: 1024px) {
    .faqBg {
        width: 50%;
    }

    .faq-wrapper {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin: 50px 0;
    }

    .faq-content-container,
    .contact-container {
        width: 50%;
    }

    .faq-content-container h2:nth-child(1),
    .contact-heading h2:nth-child(2) {
        font-size: 2.5rem;
    }

    .contact-container {
        margin: 0;
        max-width: 413px;
    }

    .contact-form {
        max-width: 100%;
    }
}

@media screen and (min-width: 1100px) {

    .faq-content-container h2:nth-child(1),
    .contact-heading h2:nth-child(2) {
        font-size: 3rem;
    }
}
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-raleway: Raleway;
  --font-poppins: Poppins;
  --text-sm-font-normal: Inter;

  /* font sizes */
  --font-size-5xl: 24px;
  --font-size-xl: 20px;
  --font-size-xs: 12px;
  --font-size-lgi: 19px;
  --font-size-sm: 14px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --text-sm-font-normal-size: 18px;

  /* Colors */
  --neutrals-cool-gray-800: #1e293b;
  --mec-primary-rich-black: #050d15;
  --color-gray-100: rgba(30, 41, 59, 0.5);
  --color-gray-200: rgba(255, 255, 255, 0.08);
  --color-gray-300: rgba(255, 255, 255, 0.8);
  --color-gray-400: rgba(255, 255, 255, 0.1);
  --color-gray-500: rgba(255, 255, 255, 0.7);
  --color-gray-600: rgba(5, 13, 21, 0.5);
  --color-gray-700: rgba(255, 255, 255, 0.4);
  --color-gray-800: rgba(5, 13, 21, 0.4);
  --color-gray-900: rgba(255, 255, 255, 0.3);
  --color-gray-1000: rgba(255, 255, 255, 0.05);
  --mec-primary-mec-light-gradient: #6a00f4;
  --basic-white: #fff;
  --mec-primary-cornflower-blue: #5591f5;
  --neutrals-cool-gray-600: #475569;
  --color-darkslategray-100: rgba(56, 64, 71, 0.4);
  --color-darkslategray-200: rgba(56, 64, 71, 0.09);
  --neutrals-cool-gray-500: #64748b;
  --mec-primary-mec-dark-gradient1: #6200ea;
  --primary-purple-50: #f6f0ff;

  /* Gaps */
  --gap-31xl: 50px;
  --gap-28xl: 47px;
  --gap-23xl: 42px;
  --gap-9xl: 28px;
  --gap-7xl: 26px;
  --gap-6xl: 25px;
  --gap-4xl: 23px;
  --gap-3xl: 22px;
  --gap-2xl: 21px;
  --gap-xl: 20px;
  --gap-lgi: 19px;
  --gap-mini: 15px;
  --gap-2xs: 11px;
  --gap-5xs: 8px;
  --gap-4xs: 9px;

  /* Paddings */
  --padding-36xl: 55px;
  --padding-23xl: 42px;
  --padding-22xl: 41px;
  --padding-17xl: 36px;
  --padding-18xl: 37px;
  --padding-11xl: 30px;
  --padding-9xl: 28px;
  --padding-8xl: 27px;
  --padding-6xl: 25px;
  --padding-5xl: 24px;
  --padding-4xl: 23px;
  --padding-2xl: 21px;
  --padding-xl: 20px;
  --padding-mid: 17px;
  --padding-mini: 15px;
  --padding-sm: 14px;
  --padding-smi: 13px;
  --padding-xs: 12px;
  --padding-2xs: 11px;
  --padding-3xs: 10px;
  --padding-4xs: 9px;
  --padding-5xs: 8px;
  --padding-6xs: 7px;
  --padding-7xs: 6px;
  --padding-8xs: 5px;
  --padding-9xs: 4px;
  --padding-10xs: 3px;
  --padding-10xs-5: 2.5px;
  --padding-11xs: 2px;
  --padding-12xs: 1px;

  /* Border radiuses */
  --br-330xl: 349px;
  --br-41xl: 60px;
  --br-31xl: 50px;
  --br-5xl: 24px;
  --br-xl: 20px;
  --br-5xs: 8px;

  /* Effects */
  --mec-boxes: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

/*.skip-substract-icon {
  width: 100%;
  height: 1844px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  /*bottom: 478.2px;*/
  /*bottom: 241.2px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 3;
}*/
.grid-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr); 
  width: 100%;
}

.grid-header {
  display: contents;
}

.grid-header-cell {
  padding: 10px;
  color:#64748B;
font-size: 14px;
font-weight: 600;
line-height: 18px;
text-align: center;

}

.grid-row {
  display: contents;
  position: relative;
}
.grid-row.first-row .grid-time-cell,
.grid-row.first-row .grid-cell {
    margin-top: 10px; /* Set margin-top for the first row's columns */
}
.horizontal-line.first-row-line {
  margin-top: 10px; /* Set margin-top for the first row's horizontal line */
}
.grid-time-cell {
  padding: 10px;
  color:#64748B;
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;  
font-family: Poppins, sans-serif !important;
}

.grid-cell.last-cell {
  border-right: none; /* Remove right border for the last cell */
}
.grid-cell {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 45px;
  width:80px
}
.time-label {
  position: relative;
  z-index: 1; 
}

.horizontal-line {
  grid-column: 1 / -1; 
  height: 1px; 
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 84;
  margin-top: -46px;
  width: 87.5%;
}
.horizontal-line2{
  grid-column: 1 / -1; 
  height: 1px; 
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 95;
  margin-top: 0px;
  width: 85%;
}
.calenderweekdata{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.task-element-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.task-element-priority {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.task-element-deadline {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  height: 10px;
  opacity: 0.5px;
  color: #FFFFFF;
}
.task-element-title {
  height: 10px;
  opacity: 0.8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--font-raleway);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;
}
.task-element-icon {
  background: #FFFFFF33;
  width: 22px;
  height: 22px;
  border-radius: 8px;
  opacity: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task-elements-group-right {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 50px;
  gap: 50px;
}
.task-elements-group-left {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 15px;
  gap: 15px;
}
.task-element {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  padding: 15px;
}
.task-card {
  width: 100%;
  height: 36px;
  grid-gap: 0px;
  gap: 0px;
  /* opacity: 0px; */
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.task-card-container {
  width: 100%;
  height: 36px;
  border-radius: 20px;
  /* opacity: 0px; */
  background: #1E293B80;
  box-shadow: 0px 4px 8px 0px #00000040;
  display: flex;
  flex-direction: row;
}
.tasks-list-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;
  justify-content: center;*/
  /*gap: 10px;*/
  grid-gap: 15px;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
}
.tasks-list-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 15px;
  gap: 15px;
  height: 9px;
  opacity: 0.6px;
  font-family: var(--font-raleway);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #FFFFFF;

}
.tasks-list-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--font-raleway);
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #FFFFFF;
}
.tasks-list-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  /*justify-content: center;*/
  grid-gap: 50px;
  gap: 50px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 15px;
}
.month-tasks-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*gap: 20px;*/
  /*top: -285px;*/
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
}
.week-tasks-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*gap: 20px;*/
  /*top: -285px;*/
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
}
.today-tasks-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*gap: 20px;*/
  /*top: -285px;*/
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
}
.tasks-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*gap: 20px;*/
  /*top: -285px;*/
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
}
.meeting-color {
  border-radius: var(--br-xl);
  background-color: rgba(52, 211, 153, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.lecture-color {
  border-radius: var(--br-xl);
  background-color: rgba(38, 197, 243, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.task-color {
  border-radius: var(--br-xl);
  background-color: rgba(106, 0, 244, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.event-task-time img {
  display: flex;
}
.event-task-time {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 5px;
  gap: 5px;
}
.event-icons {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}
.event-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
}
.event-title {
  font-family: var(--font-raleway);
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.event-location {
  font-family: var(--font-raleway);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.event-timeframe {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1px;
  gap: 1px;
}
.event-timeframe span {
  font-size: 10px;
  font-weight: 400;
  font-family: Poppins, sans-serif!important;
  text-align: center;
  line-height: 12px;
}
.event-timeframe .gap{
  line-height: 5px;
}
.v-line {
  width: 3px;
  height: 57px;
  grid-gap: 0px;
  gap: 0px;
  opacity: 0px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  left: 1px;
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)), #1e293b;
}
.event-card {
  /*background-color: rgba(52, 211, 153, 0.4);*/
  /*box-shadow: 0px 8px 32px 0px #0000000F;*/
  /*box-shadow: 0px 4px 8px 0px #00000008;*/
  width: 100%;
  height: 57px;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.no-events {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}
.upcomingmeetings-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.upcomingmeetings-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.tomorrow-group-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 40px;
  gap: 40px;
}
.skip-substract-icon {
  width: 100%;
  /* height: 2000px; */
  top: 540px;
  grid-gap: 0px;
  gap: 0px;
  position: absolute;
  margin: 0 !important;
  /* opacity: 0px; */
  /* border-radius: 60px 60px 0px 0px; */
  /* background: rgba(30, 41, 59, 1); */
}
.footer-wrapper {
  width: 100%;
  height: 300px; 
  background: rgb(24,41,57);
  position: absolute;
  top: 2100px;
  
}

.group-logo-icon {
  height: 37.8px;
  width: 145.9px;
  position: relative;
}
.group-left,
.group-left-wrapper {
  display: flex;
  justify-content: flex-start;
}
.group-left {
  flex: 1 1;
  flex-direction: row;
  align-items: center;
}
.group-left-wrapper {
  height: 67px;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
}
.textlabel-text,
.textlabel-text1 {
  position: relative;
  display: inline-block;
}
.textlabel-text {
  letter-spacing: 0.05em;
  font-weight: 300;
  min-width: 45px;
}
.textlabel-text1 {
  letter-spacing: 0.64px;
  line-height: 16px;
  min-width: 65px;
  white-space: nowrap;
}
.textlabel-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs-5) 0 0;
  color: var(--basic-white);
}
.textlabel-text2 {
  position: relative;
  letter-spacing: 0.05em;
  line-height: 16px;
  font-weight: 300;
  display: inline-block;
  min-width: 89px;
  white-space: nowrap;
}
.textlabel-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs-5) 0 0;
}
.textlabel-text3 {
  position: relative;
  letter-spacing: 0.05em;
  line-height: 16px;
  font-weight: 300;
  display: inline-block;
  min-width: 90px;
  white-space: nowrap;
}
.textlabel-text-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs-5) 0 0;
}
.skip-vector-icon {
  height: 24px;
  width: 0;
  position: relative;
  border-radius: var(--br-xl);
  -o-object-fit: contain;
     object-fit: contain;
  display: none;
}
.group-center {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 19.5px 0;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
}
.group-center-wrapper {
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.view-stroke {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  width: 100%;
  height: 100%;
}
.textlabel-text4 {
  position: absolute;
  top: 18px;
  left: 40px;
  letter-spacing: 0.05em;
  font-weight: 300;
  display: none;
  align-items: center;
  width: 90px;
  height: 10px;
  opacity: 0.8;
}
.skip-expand-more-icon {
  position: absolute;
  top: 13px;
  left: 13px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
.group-user {
  height: 46px;
  width: 46px;
  position: relative;
}
.view-stroke1 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
}
.textlabel-text5 {
  flex: 1 1;
  position: relative;
  line-height: 22px;
}
.avatar-mec {
  width: 32px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0;
  box-sizing: border-box;
  z-index: 1;
}
.textlabel-text6,
.textlabel-text7 {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 300;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-text6 {
  height: 10px;
  min-width: 90px;
  white-space: nowrap;
}
.textlabel-text7 {
  align-self: stretch;
  height: 8px;
  font-size: 10px;
}
.textlabel-text-parent {
  align-self: stretch;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}
.avatar-mec-parent,
.frame-wrapper,
.textlabel-text-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper {
  flex: 1 1;
  flex-direction: column;
  padding: var(--padding-9xs) 0 0;
  text-align: left;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
}
.avatar-mec-parent {
  width: 130px;
  flex-direction: row;
  grid-gap: var(--gap-5xs);
  gap: var(--gap-5xs);
}
.skip-expand-more-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
}
.skip-expand-more-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xs) 0 0;
}
.group-right,
.group-user1,
.navbar-mec {
  display: flex;
  flex-direction: row;
  /*align-items: flex-start;*/
  align-items: center;
  box-sizing: border-box;
}
.group-user1 {
  width: 220px;
  justify-content: space-between;
  padding: var(--padding-6xs) var(--padding-5xs);
  position: relative;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
  text-align: center;
  font-family: var(--font-raleway);
}
.group-right,
.navbar-mec {
  max-width: 100%;
}
.group-right {
  width: 719px;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: var(--padding-sm) 0;
  grid-gap: 10px;
  gap: 10px;
  color: var(--basic-white);
}
.navbar-mec {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(70px);
          backdrop-filter: blur(70px);
  background-color: rgba(255, 255, 255, 0.04);
  overflow: hidden;
  /*justify-content: flex-start;*/
  justify-content: center;
  padding: var(--padding-5xs) 50px;
  grid-gap: var(--gap-31xl);
  gap: var(--gap-31xl);
  width: 100%;
  height: 100%;
}
.view-ellipse {
  position: absolute;
  top: 63px;
  left: 355px;
  /*border-radius: 50%;
  background-color: var(--basic-white);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  width: 5px;
  height: 5px;
  transform: rotate(180deg);
  transform-origin: 0 0;
  z-index: 1;
}
.navbar-mec-parent {
  align-self: stretch;
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 99;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
  font-family: var(--font-poppins);
}
.textlabel-title {
  position: relative;
  line-height: 70px;
  font-weight: 500;
  height: 17px;
  display: flex; /*inline-block;*/
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  /*min-width: 100px;*/
}
.view-vector-icon {
  height: 2px;
  width: 1154px;
  position: relative;
  margin: 0 !important;
  /*right: -1054px;*/
  bottom: 16px;
  border-radius: var(--br-330xl);
  z-index: 1;
  flex-shrink: 0;
}
.frame-wrapper2,
.textlabel-title-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-title-parent {
  flex-direction: row;
  position: relative;
}
.frame-wrapper2 {
  flex-direction: column;
  padding: var(--padding-12xs) 0 0;
}
.skip-arrow-right-icon {
  height: 20px;
  width: 20px;
  position: relative;
}
.textlabel-title1 {
  align-self: stretch;
  height: 17px;
  position: relative;
  line-height: 70px;
  font-weight: 900;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title-wrapper {
  flex: 1 1;
  flex-direction: column;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
  min-width: 226px;
}
.frame-parent2,
.frame-wrapper1,
.textlabel-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent2 {
  align-self: stretch;
  flex-direction: row;
  grid-gap: 9.5px;
  gap: 9.5px;
}
.frame-wrapper1 {
  width: 487px;
  flex-direction: column;
  padding: var(--padding-7xs) 0 0;
  box-sizing: border-box;
}
.skip-bmec {
  width: 171px;
  font-family: var(--font-raleway);
  font-weight: 800;
  font-size: var(--font-size-sm);
  color: var(--basic-white);
}
.frame-parent1 {
  width: 1154px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  max-width: 100%;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-5xl);
}
.group-header {
  align-self: stretch;
}
.textlabel- {
  flex: 1 1;
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 30px;
}
.group-weekday {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-1 {
  flex: 1 1;
  position: relative;
  line-height: 14px;
}
.group-weekday1 {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-2 {
  flex: 1 1;
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 30px;
}
.group-weekday2 {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-3 {
  flex: 1 1;
  position: relative;
  line-height: 14px;
}
.group-weekday3 {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-4 {
  flex: 1 1;
  position: relative;
  line-height: 14px;
}
.group-weekday4 {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-5 {
  flex: 1 1;
  position: relative;
  line-height: 14px;
}
.group-weekday5 {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-6 {
  flex: 1 1;
  position: relative;
  line-height: 14px;
}
.group-weekday-parent,
.group-weekday6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-weekday6 {
  flex: 1 1;
  padding: 0 0 var(--padding-8xs);
}
.group-weekday-parent {
  align-self: stretch;
  padding: 0 0 150px;
  grid-gap: var(--gap-4xs);
  gap: var(--gap-4xs);
}
.day,
.view-136 {
  position: absolute;
}
.view-136 {
  top: 7px;
  left: 9px;
  border-radius: 6px;
  background-color: var(--primary-purple-50);
  border: 1px solid rgba(0, 133, 255, 0.2);
  box-sizing: border-box;
  width: 30px;
  height: 35px;
  display: none;
}
.day {
  top: calc(50% - 8px);
  left: calc(50% - 5px);
  line-height: 14px;
}
.bg,
.just-day {
  position: relative;
  display: none;
}
.just-day {
  width: 40px;
  height: 40px;
  z-index: 2;
  text-align: left;
  color: var(--neutrals-cool-gray-600);
  font-family: var(--text-sm-font-normal);
}
.bg {
  height: 30px;
  width: 30px;
  background-color: var(--basic-white);
}
.textlabel-day {
  position: relative;
  line-height: 14px;
  font-weight: 200;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-9xs) var(--padding-6xs);
  opacity: 0.5;
}
.bg1 {
  height: 30px;
  width: 30px;
  position: relative;
  background-color: var(--basic-white);
  display: none;
}
.textlabel-day1 {
  position: relative;
  line-height: 14px;
  font-weight: 200;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-9xs) var(--padding-6xs);
  opacity: 0.5;
}
.textlabel-day2 {
  position: relative;
  line-height: 14px;
  font-weight: 200;
  display: inline-block;
  min-width: 16px;
}
.group-calendarelementsday2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-6xs) var(--padding-6xs);
  opacity: 0.5;
}
.textlabel-day3 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 6px;
}
.group-calendarelementsday3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-xs) var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day4 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 11px;
}
.group-calendarelementsday4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-6xs)
    var(--padding-3xs);
  opacity: 0.8;
}
.textlabel-day5 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 11px;
}
.group-calendarelementsday5 {
  width: 30px;
  border-radius: var(--br-5xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-6xs);
  box-sizing: border-box;
  opacity: 0.8;
}
.textlabel-day6 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 12px;
}
.group-calendarelementsday6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day7 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 12px;
}
.group-calendarelementsday7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-5xs) var(--padding-7xs)
    var(--padding-3xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day8 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 12px;
}
.group-calendarelementsday8 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day9 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 10px;
}
.group-calendarelementsday9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-7xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day10 {
  position: relative;
  line-height: 14px;
  font-weight: 600;
  display: inline-block;
  min-width: 12px;
}
.group-today {
  width: 30px;
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-cool-gray-500);
  border: 1px solid var(--neutrals-cool-gray-500);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-9xs);
  z-index: 1;
}
.textlabel-day11 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 12px;
}
.indicator-icon,
.indicator-icon1 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday10 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day12 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.indicator-icon2,
.indicator-icon3 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday11 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day13 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 11px;
}
.indicator-icon4,
.indicator-icon5,
.indicator-icon6 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs)
    var(--padding-3xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day14 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 16px;
}
.indicator-icon7,
.indicator-icon8 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-6xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day15 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 16px;
}
.indicator-icon9 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-6xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day16 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.indicator-icon10,
.indicator-icon11,
.indicator-icon12 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday15 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day17 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.indicator-icon13,
.indicator-icon14 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday16 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day18 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.indicator-icon15 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday17 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day19 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 15px;
}
.indicator-icon16,
.indicator-icon17 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-6xs) var(--padding-7xs)
    var(--padding-5xs);
  opacity: 0.8;
}
.textlabel-day20 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.group-calendarelementsday19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day21 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.group-calendarelementsday20 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day22 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.indicator-icon18,
.indicator-icon19,
.indicator-icon20 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday21 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-textlabel-day {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 16px;
}
.group-calendarelementsday22 {
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-6xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day23 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 21px;
}
.indicator-icon21,
.indicator-icon22 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday23 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs)
    var(--padding-8xs);
  opacity: 0.8;
}
.textlabel-day24 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 21px;
}
.group-calendarelementsday24 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs)
    var(--padding-8xs);
  opacity: 0.8;
}
.textlabel-day25 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday25 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day26 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.indicator-icon23 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday26 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day27 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday27 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day28 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 20px;
}
.indicator-icon24,
.indicator-icon25 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-8xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day29 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday29 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day30 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.indicator-icon26 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday30 {
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day31 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 9px;
}
.group-calendarelementsday31 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-7xs)
    var(--padding-2xs);
  opacity: 0.5;
  font-family: var(--text-sm-font-normal);
}
.bg2 {
  height: 30px;
  width: 30px;
  position: relative;
  background-color: var(--basic-white);
  display: none;
}
.textlabel-day32 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 11px;
}
.group-calendarelementsdayem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs)
    var(--padding-3xs);
  opacity: 0.5;
  font-family: var(--text-sm-font-normal);
}
.bg3 {
  height: 30px;
  width: 30px;
  position: relative;
  background-color: var(--basic-white);
  display: none;
}
.textlabel-day33 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 12px;
}
.group-calendarelementsdayem1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs);
  opacity: 0.5;
  font-family: var(--text-sm-font-normal);
}
.group-calendar-days-inner,
.group-calendarelementsday-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-calendarelementsday-parent {
  width: 264px;
  flex-wrap: wrap;
  grid-gap: 5px 8.3px;
  gap: 5px 8.3px;
}
.group-calendar-days-inner {
  height: 170px;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
  margin-top: -135px;
  font-family: var(--font-poppins);
}
.view-144 {
  align-self: stretch;
  height: 30px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: rgba(203, 213, 225, 0.1);
  margin-top: -135px;
}
.group-calendar-days {
  align-self: stretch;
  height: 208px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
  box-sizing: border-box;
}
.calendar-simple,
.view-rectangle {
  border-radius: var(--br-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.calendar-simple {
  flex: 1 1;
  box-shadow: var(--mec-boxes);
  background-color: var(--color-gray-100);
  border: 1px solid var(--neutrals-cool-gray-800);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xl) var(--padding-23xl);
  grid-gap: var(--gap-mini);
  gap: var(--gap-mini);
  min-width: 263px;
  z-index: 4;
  text-align: center;
  font-size: var(--text-sm-font-normal-size);
}
.view-rectangle {
  width: 351px;
  height: 305px;
  position: relative;
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  display: flex
}
.textlabel-title2 {
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.all-clear {
  margin: 0;
}
.schedules-open-time {
  margin: 0;
  font-weight: 300;
}
.textlabel-text-txt {
  width: 100%;
}
.textlabel-text8 {
  position: absolute;
  top: 79px;
  left: 0;
  letter-spacing: 0.05em;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 267px;
  height: 40px;
  z-index: 2;
}
.skip-union-icon,
.wrapper-skip-union {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.skip-union-icon {
  overflow: hidden;
  -o-object-fit: contain;
     object-fit: contain;
  transform: scale(2.014);
}
.wrapper-skip-union {
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.skip-note-stack-icon {
  position: absolute;
  top: 14px;
  left: 36px;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.wrapper-skip-union-parent {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.frame-wrapper4 {
  position: absolute;
  height: calc(100% - 0.7px);
  top: 0;
  bottom: 0.7px;
  left: 72px;
  width: 123.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-text-group {
  align-self: stretch;
  height: 119px;
  position: relative;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
}
.textlabel-title-group {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
}
.textlabel-7 {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.05em;
  line-height: 24px;
  font-weight: 800;
  font-family: var(--font-raleway);
  color: var(--basic-white);
  text-align: left;
  display: inline-block;
  min-width: 98px;
}
.skip-bmec1 {
  cursor: pointer;
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  padding: var(--padding-11xs) var(--padding-xl);
  /*background-color: var(--mec-primary-mec-dark-gradient);*/
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
}
.skip-bmec1:hover {
  /* background-color: rgba(135, 38, 255, 0.09); */
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
}
.group-upcomingmeetings,
.view-rectangle1 {
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  max-width: 100%;
}
.group-upcomingmeetings {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-17xl) var(--padding-22xl) var(--padding-9xl);
  grid-gap: 33px;
  gap: 33px;
  min-width: 263px;
}
.view-rectangle1 {
  width: 351px;
  height: 305px;
  position: relative;
  display: none;
}
.textlabel-title3 {
  width: 129px;
  position: relative;
  line-height: 15px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.view-stroke2 {
  height: 29px;
  width: 102px;
  position: absolute;
  margin: 0 !important;
  top: -8px;
  left: -15px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  z-index: 1;
}
.textlabel-text9 {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 300;
  display: inline-block;
  min-width: 73px;
  z-index: 2;
  line-height: 12px;
}
.frame-wrapper5,
.view-stroke-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-stroke-parent {
  flex-direction: row;
  position: relative;
}
.frame-wrapper5 {
  flex-direction: column;
  padding: var(--padding-10xs) 0 0;
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
}
.textlabel-title-container {
  width: 253px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 var(--padding-9xs);
  box-sizing: border-box;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
}
.textlabel-text10 {
  flex: 1 1;
  position: relative;
  z-index: 1;
  width: 18px;
  height: 39px;
  top: 11px;
  left: -25px;
  font-family: var(--font-poppins);
  font-size: 50px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
}
.textlabel-text-wrapper1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 80px;
  /* padding: 0 71px var(--padding-2xs) 72px; */
  text-align: center;
  font-size: 50px;
  font-family: var(--font-poppins);
}
.textlabel-text11 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  color: var(--color-gray-500);
  text-align: center;
  z-index: 1;
}
.view-rectangle2 {
  height: 74px;
  width: 267px;
  position: relative;
  box-shadow: var(--mec-boxes);
  border-radius: var(--br-xl);
  background-color: var(--color-gray-100);
  border: 1px solid var(--neutrals-cool-gray-800);
  box-sizing: border-box;
  display: none;
}
.skip-meeting-icon {
  height: 18px;
  width: 18px;
  position: relative;
  z-index: 2;
}
.textlabel-text12 {
  flex: 1 1;
  position: relative;
  line-height: 20px;
  z-index: 2;
}
.skip-meeting-parent {
  width: 57px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-4xs);
  gap: var(--gap-4xs);
}
.textlabel-text13 {
  height: 10px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-family: var(--font-raleway);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 60px;
  z-index: 2;
}
.frame-parent4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-5xs);
  gap: var(--gap-5xs);
}
.skip-lecture-icon {
  height: 18px;
  width: 18px;
  position: relative;
  z-index: 2;
}
.textlabel-text14 {
  flex: 1 1;
  position: relative;
  line-height: 20px;
  z-index: 2;
}
.skip-lecture-parent {
  width: 54px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-4xs);
  gap: var(--gap-4xs);
}
.textlabel-text15 {
  height: 10px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-family: var(--font-raleway);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 57px;
  z-index: 2;
}
.frame-parent5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-5xs);
  gap: var(--gap-5xs);
}
.skip-task {
  margin: 0;
  height: 18px;
  width: 18px;
  position: relative;
  z-index: 2;
}
.textlabel-text16 {
  flex: 1 1;
  position: relative;
  line-height: 20px;
  z-index: 2;
}
.skip-task-parent {
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-4xs);
  gap: var(--gap-4xs);
}
.textlabel-text17 {
  align-self: stretch;
  height: 10px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-family: var(--font-raleway);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
}
.frame-parent6 {
  width: 49px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-5xs);
  gap: var(--gap-5xs);
}
.view-rectangle-parent {
  align-self: stretch;
  box-shadow: var(--mec-boxes);
  border-radius: var(--br-xl);
  background-color: var(--color-gray-100);
  border: 1px solid var(--neutrals-cool-gray-800);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px var(--padding-5xl) var(--padding-mid) var(--padding-4xl);
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
  z-index: 1;
  font-size: var(--font-size-base);
  font-family: var(--font-poppins);
}
.calendar-simple-parent,
.frame-wrapper3,
.group-activity {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.group-activity {
  flex: 1 1;
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-17xl) var(--padding-22xl) var(--padding-9xl);
  grid-gap: var(--gap-7xl);
  gap: var(--gap-7xl);
  min-width: 263px;
}
.calendar-simple-parent,
.frame-wrapper3 {
  flex-direction: row;
}
.calendar-simple-parent {
  width: 1153px;
  flex-wrap: wrap;
  grid-gap: var(--gap-31xl);
  gap: var(--gap-31xl);
}
.frame-wrapper3 {
  align-self: stretch;
}
.textlabel-8 {
  position: relative;
  font-size: 25px;
  letter-spacing: 0.05em;
  line-height: 24px;
  display: inline-block;
  font-family: var(--font-poppins);
  color: var(--basic-white);
  text-align: center;
  min-width: 15px;
}
.frame-parent3,
.skip-bmec2 {
  display: flex;
  justify-content: flex-start;
}
.skip-bmec2 {
  cursor: pointer;
  border: 2px;
  padding: var(--padding-3xs) var(--padding-sm) var(--padding-3xs) var(--padding-mid);
  border-radius: var(--br-xl);
  background: linear-gradient(90deg, #6200ea 0%, #26c5f3 100%);
  background-repeat: no-repeat;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  z-index: 4;
  position: absolute;
  top: 565px;
  right: 34px;
}
.skip-bmec2:hover,
.skip-bmec4:hover {
  border: 2px;
  box-sizing: border-box;
}
.frame-parent3 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: var(--gap-3xl);
  gap: var(--gap-3xl);
  max-width: 100%;
}
.view-rectangle3 {
  width: 351px;
  height: 398px;
  position: relative;
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.textlabel-title4 {
  position: relative;
  display: inline-block;
  min-width: 98px;
  z-index: 1;
  line-height: 15px;
}
.textlabel-title-frame {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
  box-sizing: border-box;
  min-width: 64px;
}
.view-stroke3 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-200);
  display: none;
}
.textlabel-text18 {
  flex: 1 1;
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 500;
  z-index: 2;
  height: 20px;
  width: 24px;
}
.frame-wrapper6,
.view-stroke-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-stroke-group {
  width: 24px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-9xs) 0 0;
  box-sizing: border-box;
  z-index: 1;
}
.frame-wrapper6 {
  flex: 1 1;
  flex-direction: column;
  min-width: 58px;
  text-align: center;
  font-size: 13px;
  color: var(--color-gray-500);
  font-family: var(--font-poppins);
}
.group-switcher-icon {
  height: 24px;
  width: 48px;
  position: relative;
  z-index: 1;
}
.frame-parent8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-mini);
  gap: var(--gap-mini);
}
.nothing-on-for-tomorrow-yet {
  margin: 0;
}
.schedule-lectures-meetings {
  margin: 0;
  font-weight: 300;
}
.textlabel-text-txt1 {
  width: 100%;
}
.textlabel-text19 {
  position: absolute;
  top: 73px;
  left: 0;
  letter-spacing: 0.05em;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 235px;
  height: 60px;
  z-index: 2;
}
.skip-union-icon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
}
.skip-calendar-month-icon {
  position: absolute;
  top: 8px;
  left: 37.4px;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.skip-union-parent {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.frame-wrapper8 {
  position: absolute;
  top: 0;
  left: 55.6px;
  width: 123.4px;
  height: 118.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-text-parent1 {
  height: 133px;
  flex: 1 1;
  position: relative;
}
.frame-wrapper7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
}
.image-icon {
  width: 20px;
  height: 20px;
  position: relative;
}
.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.textlabel-9 {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.05em;
  line-height: 24px;
  font-weight: 800;
  font-family: var(--font-raleway);
  color: var(--basic-white);
  text-align: left;
  display: inline-block;
  min-width: 94px;
}
.image-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
}
.frame-parent9,
.image-container,
.skip-bmec3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-container {
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
}
.frame-parent9,
.skip-bmec3 {
  align-self: stretch;
}
.skip-bmec3 {
  cursor: pointer;
  /* padding: var(--padding-11xs) var(--padding-36xl) var(--padding-11xs) 61px; */
  /*background-color: var(--mec-primary-mec-dark-gradient);*/

  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  padding: var(--padding-11xs) var(--padding-xl);

  /*border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1E293B, #1E293B) padding-box,
    linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)) border-box;*/
  overflow-x: auto;
  flex-direction: row;
  grid-gap: 5.5px;
  gap: 5.5px;
  z-index: 1;
}
.frame-parent9 {
  flex-direction: column;
  grid-gap: 57px;
  gap: 57px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
  top: 30px;
  position: relative;
}
.group-tomorrow,
.view-rectangle4 {
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  max-width: 100%;
}
.group-tomorrow {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 33px var(--padding-22xl) var(--padding-18xl) var(--padding-23xl);
  grid-gap: 80px;
  gap: 80px;/*var(--gap-9xl);*/
  z-index: 4;
}
.view-rectangle4 {
  width: 351px;
  height: 294px;
  position: relative;
  display: none;
}
.textlabel-title5 {
  align-self: stretch;
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-title-wrapper1 {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
}
.view-stroke4 {
  height: 29px;
  width: 60px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  display: none;
}
.textlabel-text20 {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 300;
  display: inline-block;
  min-width: 31px;
  z-index: 1;
}
.frame-parent10,
.group-add {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.group-add {
  height: 29px;
  width: 60px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  justify-content: flex-start;
  padding: var(--padding-8xs) var(--padding-mini);
  box-sizing: border-box;
  z-index: 1;
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
}
.frame-parent10 {
  align-self: stretch;
  justify-content: space-between;
  padding: 0 0 var(--padding-5xs);
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-xl);
}
.view-rectangle5 {
  border-radius: var(--br-xl);
  background-color: rgba(52, 211, 153, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.skip-mask-icon,
.view-rectangle-group,
.view-rectangle5 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.skip-mask-icon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}
.view-rectangle-group {
  margin: 0 !important;
}
.checkbox-mec-icon {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.checkbox-mec-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}
.textlabel-text21 {
  width: 140px;
  height: 30px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
}
.textlabel-text-wrapper2 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  min-width: 108px;
}
.skip-more-vert-icon {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 2;
}
.group-category,
.skip-more-vert-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.group-category {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini) var(--padding-6xs);
  position: relative;
  grid-gap: var(--gap-mini);
  gap: var(--gap-mini);
  z-index: 1;
}
.view-rectangle6 {
  border-radius: var(--br-xl);
  background-color: rgba(38, 197, 243, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.skip-mask-icon1,
.view-rectangle-container,
.view-rectangle6 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.skip-mask-icon1 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}
.view-rectangle-container {
  margin: 0 !important;
}
.checkbox-mec-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.checkbox-mec-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}
.textlabel-text22 {
  width: 140px;
  height: 30px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
}
.textlabel-text-wrapper3 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  min-width: 108px;
}
.skip-more-vert-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 2;
}
.skip-more-vert-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.group-category-parent,
.group-category1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-mini);
  gap: var(--gap-mini);
}
.group-category1 {
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini) var(--padding-6xs);
  position: relative;
  z-index: 1;
}
.group-category-parent {
  flex-direction: column;
}
.view-rectangle7 {
  border-radius: var(--br-xl);
  background-color: rgba(106, 0, 244, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.skip-mask-icon2,
.view-rectangle-parent1,
.view-rectangle7 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.skip-mask-icon2 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}
.view-rectangle-parent1 {
  margin: 0 !important;
}
.checkbox-mec {
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.checkbox-mec-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.textlabel-text23 {
  width: 140px;
  height: 30px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
}
.textlabel-text-wrapper4 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  min-width: 108px;
}
.skip-more-vert-icon2 {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 2;
}
.skip-more-vert-frame {
  flex-direction: column;
  padding: var(--padding-8xs) 0 0;
}
.group-categories,
.group-category2,
.group-tomorrow-parent,
.skip-more-vert-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-category2 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini) var(--padding-6xs);
  position: relative;
  grid-gap: var(--gap-mini);
  gap: var(--gap-mini);
  z-index: 1;
}
.group-categories,
.group-tomorrow-parent {
  flex-direction: column;
  max-width: 100%;
}
.group-categories {
  align-self: stretch;
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  padding: var(--padding-11xl) var(--padding-22xl) var(--padding-18xl)
    var(--padding-23xl);
  grid-gap: var(--gap-mini);
  gap: var(--gap-mini);
  z-index: 4;
  font-size: var(--font-size-mini);
}
.group-tomorrow-parent {
  width: 351px;
  grid-gap: var(--gap-28xl);
  gap: var(--gap-28xl);
  min-width: 351px;
}
.textlabel-title6 {
  align-self: stretch;
  position: relative;
  line-height: 28px;
}
.skip-world-icon {
  height: 14px;
  width: 14px;
  position: relative;
}
.textlabel-text24 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.05em;
}
.textlabel-text-wrapper5 {
  flex: 1 1;
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
}
.frame-wrapper9,
.skip-world-parent,
.textlabel-text-wrapper5,
.textlabel-title-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.skip-world-parent {
  width: 124px;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  font-size: var(--font-size-xs);
  color: rgba(255, 255, 255, 0.5);
}
.frame-wrapper9,
.textlabel-title-parent1 {
  flex-direction: column;
}
.textlabel-title-parent1 {
  align-self: stretch;
  grid-gap: 2px;
  gap: 2px;
}
.frame-wrapper9 {
  flex: 1 1;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.group-switcher-icon1 {
  width: 48px;
  height: 24px;
  position: relative;
}
.group-switcher-wrapper {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.textlabel-label- {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 24px;
  cursor: pointer;
}
.skip-day {
  /* height: 23px; */
  /* width: 44px; */
  border-radius: var(--br-31xl);
  background-color: var(--neutrals-cool-gray-500);
  border: 1px solid var(--neutrals-cool-gray-500);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs);
}
.textlabel-label-1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 34px;
  cursor: pointer;
}
.button-week {
  height: 23px;
  border-radius: var(--br-31xl);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  opacity: 0.8;
}
.textlabel-label-2 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 39px;
  cursor: pointer;
}
.button-month {
  height: 23px;
  border-radius: var(--br-31xl);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  opacity: 0.8;
}
.textlabel-label-3 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 28px;
  cursor: pointer;
}
.button-year {
  height: 23px;
  border-radius: var(--br-31xl);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  opacity: 0.8;
}
.tabbar-segmented-group-mec {
  border-radius: var(--br-31xl);
  background-color: var(--color-gray-400);
  overflow-x: auto;
  padding: var(--padding-10xs);
}
.frame-parent11,
.frame-parent12,
.tabbar-segmented-group-mec {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent12 {
  grid-gap: 31px;
  gap: 31px;
  margin-left: -142px;
  font-size: var(--font-size-xs);
  font-family: var(--font-poppins);
}
.frame-parent11 {
  align-self: stretch;
  max-width: 100%;
}
.textlabel-10 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line- {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-current-time-icon {
  height: 26px;
  width: 608px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: -13px;
  z-index: 1;
}
.group-1,
.textlabel-11 {
  position: relative;
  display: flex;
}
.group-1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
}
.textlabel-11 {
  width: 68px;
  line-height: 18px;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-12 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-2,
.line-frame {
  box-sizing: border-box;
}
.line-2 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  opacity: 0.1;
}
.line-frame {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-13 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-3 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper1 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-14 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-4 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper2 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-15 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-5 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper3 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-16 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-6 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper4 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-17 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-7 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper5 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-18 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-8 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper6 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-19 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-9 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper7 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-20 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-10 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper8 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-21 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-11 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper9 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-22 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-12 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper10 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.textlabel-23 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-13 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper11 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-,
.group-calendar-hour12 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  position: relative;
}
.group-calendar-hour12 {
  flex-direction: row;
  align-items: flex-end;
  grid-row-gap: 20px;
  row-gap: 20px;
  position: relative;
}
.group- {
  flex-direction: column;
  align-items: flex-start;
  grid-gap: var(--gap-7xl);
  gap: var(--gap-7xl);
  font-size: var(--font-size-sm);
  color: var(--neutrals-cool-gray-500);
  font-family: var(--font-poppins);
}
.calendar-day {
  flex: 1 1;
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  flex-direction: column;
  padding: 32px var(--padding-23xl) var(--padding-22xl);
  box-sizing: border-box;
  grid-gap: var(--gap-3xl);
  gap: var(--gap-3xl);
  min-width: 489px;
  z-index: 4;
}
.calendar-day,
.frame-div,
.frame-parent7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent7 {
  width: 1153px;
  flex-direction: row;
  grid-gap: var(--gap-31xl);
  gap: var(--gap-31xl);
  margin-top: 58px;
}
.frame-div {
  align-self: stretch;
  flex-direction: column;
  grid-gap: var(--gap-7xl);
  gap: var(--gap-7xl);
}
.view-rectangle8 {
  height: 576px;
  width: 1153px;
  position: relative;
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.textlabel-title7 {
  align-self: stretch;
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-title-wrapper2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
}
.group-options-icon {
  height: 29px;
  width: 29px;
  position: relative;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
}
.frame-parent15 {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 var(--padding-4xs);
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
}
.skip-union-icon2 {
  height: 111.5px;
  width: 238.6px;
  position: absolute;
  margin: 0 !important;
  top: -40.5px;
  right: 12.3px;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
}
.make-it-happen {
  margin: 0;
}
.add-a-task {
  margin: 0;
  font-size: var(--font-size-sm);
  font-weight: 300;
}
.textlabel-text25 {
  flex: 1 1;
  position: relative;
  letter-spacing: 0.05em;
  line-height: 24px;
  z-index: 2;
}
.frame-wrapper10,
.skip-union-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.skip-union-group {
  flex: 1 1;
  position: relative;
}
.frame-wrapper10 {
  align-self: stretch;
  padding: 0 var(--padding-xs);
}
.view-rectangle9 {
  height: 45px;
  width: 291px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-600);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  display: none;
}
.textlabel-text26 {
  width: 92%;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: var(--font-raleway);
  font-size: var(--font-size-mini);
  background-color: var(--mec-primary-mec-dark-gradient);
  height: 100%;
  position: relative;
  color: var(--color-gray-700);
  text-align: justify;
  display: flex;
  align-items: center;
  padding: 2;
  z-index: 1;
  justify-content: flex-start;
  margin-left: 4px;
}
.textinput-fields,
.textinput-fields1 {
  border: 1px solid var(--color-gray-1000);
  background-color: var(--color-gray-600);
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  /*align-items: flex-start;*/
  /*justify-content: flex-start;*/
  align-items: center;
  justify-content: space-between;
}
.textinput-fields {
  align-self: stretch;
  padding: var(--padding-mini) var(--padding-9xl);
  z-index: 1;
}
.textinput-fields1 {
  height: 93px;
  width: auto;
  outline: 0;
  box-sizing: border-box;
  padding: var(--padding-mid) var(--padding-9xl);
  resize: none;
}
.textinput-fields1,
.textinput-fields2 {
  align-self: stretch;
  font-family: var(--font-raleway);
  font-weight: 500;
  font-size: var(--font-size-mini);
  color: var(--color-gray-700);
  z-index: 1;
}

.textinput-fields,
.textinput-fields2 {
  grid-gap: 10px;
  gap: 10px;
}


.textinput-fields-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-mini);
  gap: var(--gap-mini);
}
/*.parent {
  align-self: stretch;
  font-family: var(--font-poppins);
  font-weight: 300;
  font-size: var(--font-size-mini);
  color: var(--basic-white);
}*/
.textinput-fields3 {
  align-self: stretch;
  font-family: var(--font-poppins);
  font-weight: 300;
  font-size: var(--font-size-mini);
  color: var(--basic-white);
}
.frame-parent16 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-3xl);
  gap: var(--gap-3xl);
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-gray-500);
}
.textlabel-text27 {
  height: 10px;
  position: absolute;
  margin: 0 !important;
  bottom: 110px;
  left: 28px;
  font-size: var(--font-size-sm);
  line-height: 80px;
  display: flex;
  text-align: justify;
  align-items: center;
  min-width: 70px;
  z-index: 2;
}
.textlabel-24 {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.05em;
  line-height: 24px;
  font-weight: 800;
  font-family: var(--font-raleway);
  color: var(--basic-white);
  display: inline-block;
  min-width: 125px;
  text-align: center;
}
.frame-parent14,
.skip-bmec4 {
  display: flex;
  align-items: flex-start;
}
.skip-bmec4 {
  cursor: pointer;
  border: 1.5px;
  padding: var(--padding-11xs) var(--padding-xl);
  align-self: stretch;
  border-radius: var(--br-xl);
  background: linear-gradient(90deg, #6200ea, #26c5f3);
  background-repeat: no-repeat;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
  z-index: 1;
}
.skip-bmec4:hover {
  border: 1.5px;
}
.frame-parent14 {
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  grid-gap: 65px;
  gap: 65px;
}
.skip-vector-icon1 {
  height: 670px;
  position: relative;
  z-index: 1;
}
.frame-parent13 {
  width: 334px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  grid-gap: var(--gap-23xl);
  gap: var(--gap-23xl);
  min-width: 334px;
  max-width: 100%;
}
.ready-to-tackle-your-first-tas {
  margin: 0;
}
.your-tasks-will {
  margin: 0;
  font-weight: 300;
}
.textlabel-text-txt2 {
  width: 100%;
}
.textlabel-text28 {
  position: absolute;
  top: 268px;
  left: 100px;
  letter-spacing: 0.05em;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 434px;
  height: 50px;
  z-index: 2;
}
.skip-union-icon3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
}
.skip-bar-chart-4-bars-icon {
  position: absolute;
  top: 23px;
  left: 46.3px;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.frame-wrapper11,
.skip-union-container {
  position: absolute;
  height: 100%;
  top: 90px; 
  left: 240px;
}
.skip-union-container {
  width: 100%;
  margin: 0 !important;
  right: 0;
  left: 0;

}
.frame-wrapper11 {
  width: 142.7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  
}
.textlabel-text-parent2 {
  align-self: stretch;
  height: 145.5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.group-mytasks,
.group-mytasks-inner {
  display: flex;
  justify-content: center;
}
.group-mytasks-inner {
  width: 100%;
  height: 311px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
}
.group-mytasks {
  width: 100%;
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  flex-direction: row;
  /* align-items: center; */
  padding: var(--padding-11xl) 43px 48px;
  grid-gap: 60px;
  gap: 60px;
  z-index: 4;
}
.frame-container,
.frame-group {
  display: flex;
  /*align-items: flex-start;
  max-width: 100%;*/
  align-items: center;
}
.frame-group {
  flex: 1 1;
  flex-direction: column;
  /*justify-content: flex-start;*/
  justify-content: center;
  grid-gap: var(--gap-28xl);
  gap: var(--gap-28xl);
}
.frame-container {
  /*width: 1352px;*/
  flex-direction: row;
  /*justify-content: flex-end;*/
  justify-content: center;
  padding: 0 var(--padding-36xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--basic-white);
  font-family: var(--font-raleway);
}
.frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  /*align-items: flex-end;*/
  /*justify-content: flex-start;*/
  align-items: center;
  justify-content: center;
  grid-gap: 43px;
  gap: 43px;
  width: 100%;
  /*max-width: 100%;*/
}
.view-rectangle10 {
  align-self: stretch;
  height: 422px;
  position: relative;
  border-radius: 0 0 var(--br-41xl) var(--br-41xl);
  background-color: var(--mec-primary-rich-black);
  display: none;
}
.textlabel-title8,
.view-vector-icon1 {
  align-self: stretch;
  position: relative;
  z-index: 1;
}
.view-vector-icon1 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.textlabel-title8 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title10,
.textlabel-title11,
.textlabel-title12,
.textlabel-title13,
.textlabel-title9 {
  align-self: stretch;
  position: relative;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    #5591f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.textlabel-title9 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title10,
.textlabel-title11,
.textlabel-title12,
.textlabel-title13 {
  line-height: 24px;
}
.textlabel-title11,
.textlabel-title12,
.textlabel-title13 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
}
.textlabel-title-parent4 {
  align-self: stretch;
  grid-gap: var(--gap-2xs);
  gap: var(--gap-2xs);
}
.textlabel-title-parent2,
.textlabel-title-parent3,
.textlabel-title-parent4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-title-parent3 {
  align-self: stretch;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-base);
}
.textlabel-title-parent2 {
  width: 179px;
  grid-gap: var(--gap-6xl);
  gap: var(--gap-6xl);
}
.textlabel-title14 {
  align-self: stretch;
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-title15,
.textlabel-title16,
.textlabel-title17,
.textlabel-title18 {
  align-self: stretch;
  position: relative;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    #5591f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.textlabel-title15 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title16,
.textlabel-title17,
.textlabel-title18 {
  line-height: 24px;
}
.textlabel-title-parent8 {
  align-self: stretch;
  grid-gap: var(--gap-2xs);
  gap: var(--gap-2xs);
}
.textlabel-title-parent6,
.textlabel-title-parent7,
.textlabel-title-parent8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-title-parent7 {
  align-self: stretch;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-base);
}
.textlabel-title-parent6 {
  width: 179px;
  grid-gap: var(--gap-6xl);
  gap: var(--gap-6xl);
}
.textlabel-title19 {
  width: 179px;
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-title20,
.textlabel-title21,
.textlabel-title22 {
  position: relative;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    #5591f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.textlabel-title20 {
  width: 179px;
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title21,
.textlabel-title22 {
  line-height: 24px;
}
.textlabel-title21 {
  width: 179px;
  display: flex;
  align-items: center;
}
.textlabel-title22 {
  align-self: stretch;
}
.textlabel-title-parent11 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: var(--gap-2xs);
  gap: var(--gap-2xs);
}
.textlabel-title-parent10 {
  align-self: stretch;
  flex-direction: column;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-base);
}
.frame-parent18,
.textlabel-title-parent10,
.textlabel-title-parent9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-title-parent9 {
  width: 213px;
  flex-direction: column;
  grid-gap: var(--gap-6xl);
  gap: var(--gap-6xl);
}
.frame-parent18 {
  flex-direction: row;
  grid-gap: 55px;
  gap: 55px;
  min-width: 681px;
  max-width: 100%;
}
.skip-newlogo-icon {
  height: 37px;
  width: 62.9px;
  position: relative;
  z-index: 1;
}
.skip-wordmark-icon {
  align-self: stretch;
  height: 24px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.skip-wordmark-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 6.2px;
}
.skip-newlogo-parent {
  width: 299.5px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  grid-gap: 12px;
  gap: 12px;
}
.meded {
  font-weight: 300;
}
.cloud {
  font-weight: 200;
}
.textlabel-title23 {
  align-self: stretch;
  position: relative;
  line-height: 25px;
  z-index: 1;
}
.frame-parent19 {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  grid-gap: 30px;
  gap: 30px;
  min-width: 320px;
  text-align: right;
  font-size: var(--font-size-mini);
  color: var(--basic-white);
}
.frame-parent17 {
  width: 1155px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
}
.frame-wrapper12,
.view-vector-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frame-wrapper12 {
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
  box-sizing: border-box;
}
.view-vector-parent {
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 17px;
  gap: 17px;
}
.textlabel-title24,
.view-vector-icon2 {
  align-self: stretch;
  position: relative;
  z-index: 1;
}
.view-vector-icon2 {
  border-radius: var(--br-330xl);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.textlabel-title24 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title-wrapper3 {
  width: 541px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-smi) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.view-elipse {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.skip-social-icon {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  z-index: 2;
}
.view-elipse-parent {
  height: 42px;
  width: 42px;
  position: relative;
}
.view-elipse1 {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.skip-social-icon1 {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  z-index: 2;
}
.view-elipse-group {
  height: 42px;
  width: 42px;
  position: relative;
}
.view-elipse2 {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.image-x-icon {
  position: absolute;
  top: 14px;
  left: 14px;
  width: 14px;
  height: 14px;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
}
.view-elipse-container {
  height: 42px;
  width: 42px;
  position: relative;
}
.view-elipse3 {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.skip-social-icon2 {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
}
.view-elipse-parent1 {
  height: 42px;
  width: 42px;
  position: relative;
}
.view-elipse4 {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.skip-social-icon3 {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
}
.view-elipse-parent2 {
  height: 42px;
  width: 42px;
  position: relative;
}
.frame-parent20,
.frame-parent21,
.frame-wrapper13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.frame-parent21 {
  justify-content: flex-start;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
}
.frame-parent20,
.frame-wrapper13 {
  max-width: 100%;
}
.frame-parent20 {
  width: 1154px;
  justify-content: space-between;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
}
.frame-wrapper13 {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.group-footer,
.my-schedule-first-view-of-new,
.view-vector-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-vector-group {
  align-self: stretch;
  grid-gap: 29px;
  gap: 29px;
  max-width: 100%;
  font-size: var(--font-size-xs);
  color: var(--basic-white);
}
.group-footer,
.my-schedule-first-view-of-new {
  background-color: var(--mec-primary-rich-black);
}
.group-footer {
  align-self: stretch;
  border-radius: 0 0 var(--br-41xl) var(--br-41xl);
  padding: 0 0 var(--padding-11xl);
  box-sizing: border-box;
  grid-gap: var(--gap-31xl);
  gap: var(--gap-31xl);
  max-width: 100%;
  z-index: 4;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--mec-primary-cornflower-blue);
  font-family: var(--font-raleway);
}
.my-schedule-first-view-of-new {
  width: 100%;
  position: relative;
  overflow: hidden;
  grid-gap: 100px;
  gap: 100px;
  line-height: normal;
  letter-spacing: normal;
}
.upcomingmeetings-header {
  flex-direction: row;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}
.mytasks-select-timezone {
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #FFFFFF;
}
.mytasks-select-legend {
  padding: 0 10px;
  margin: 0 -1.4%;
}
.group-priority {
  position: absolute;
  left: 22px;
}
.skip-arrow {
  position: absolute;
  right: 20px;
}
.textlabel-text-pro {
  width: 92%;
}
.textlabel-text-pre {
  width: 86%;
  margin-left: 20px;
}
.mytasks-skip-arrow {
  position: absolute;
  right: 20px;
}

.textlabel-text29 {
  width: 100%;
  height: 15px;
  margin-left: -25px;
  margin-top: 3px;
}
.textlabel-text10-active-day {
  display: grid;
  padding-right: 80px !important;
  height: 39px;
  width: 44px;
  line-height: 18px;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  /* opacity: 70%; */
  top: 338px;
  left: 1113px;
}
.activity-group-switcher {
  display: flex;
  flex-direction: row
}
.activity-chevron-left,
.activity-chevron-right {
  margin: 10px;
}
.calendar-simple-header {
  display: flex;
  flex-direction: row;
  grid-gap: 90px;
  gap: 90px;
}
.mytasks-group-options {
  width: 100%;
  display: flex;
  flex-direction: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
}
.mytasks-view-vector {
  height: 100%;
  width: 2px;
  display: flex;
}
@media only screen and (max-width: 1080px) {
  .navbar-mec-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 0;
    gap: 0;
  }
  .group-right {
    width: 1250px;
  }
  .frame-container,
  .frame-group,
  .group-mytasks,
  .view-vector-icon,
  .my-schedule-highlights,
  .frame-wrapper-3,
  .calendar-simple-parent,
  .group-header {
    width: 100%;
  }
  .skip-substract-icon {
    top: 590px;
  }
  .view-rectangle {
    width: 30%;
    display: flex;
  }
  .calendar-simple {
    display: flex !important;
    grid-gap: 27px;
    gap: 27px;
    padding: 20px 20px;
  }
  .group-header {
    grid-gap: 10%;
    gap: 10%;
    display: flex;
    flex-direction: column;
  }
  .calendar-simple-header {
    grid-gap: 20%;
    gap: 20%;
  }
  .group-calendarelementsday-parent {
    width: 100%;
    grid-gap: 2px 6px;
    gap: 2px 6px;
  }
  .group-upcomingmeetings {
    min-width: 0;
    padding: 20px;
    grid-gap: 65px;
    gap: 65px;
  }
  .textlabel-title-group {
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .upcomingmeetings-header {
    grid-gap: 7px;
    gap: 7px;
  }
  .textlabel-title2 {
    line-height: 25px;
    width: 70%;
  }
  .upcomingmeetings-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
  }
  .textlabel-text-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .textlabel-text8 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .frame-wrapper4 {
    align-items: center;
    justify-content: center;
    left: 50px;
  }
  .wrapper-skip-union-parent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .group-activity {
    padding: 20px;
    grid-gap: 30px;
    gap: 30px;
  }
  .textlabel-title-container {
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
  }
  .view-stroke-parent {
    align-items: center;
    justify-content: center;
    left: -15px;
  }
  .activity-group-switcher {
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .activity-chevron-left {
    left: 15px;
    position: relative;
  }
  .activity-chevron-right {
    right: 15px;
    position: relative;
  }
  .group-tomorrow-parent {
    grid-gap: 40px;
    gap: 40px;
  }
  .group-tomorrow {
    padding: 40px;
    grid-gap: 72px;
    gap: 72px;
  }
  .group-categories {
    padding: 40px;
  }
  .calendar-day {
    width: 100px;
    padding: 40px;
  }
  .frame-parent11 {
    width: 100%;
  }
  .frame-wrapper9 {
    padding: 0;
    width: 100%;
    flex-direction: row;
  }
  .textlabel-title-parent1 {
    width: 80%;
  }
  .textlabel-title6 {
    display: flex;
    flex-direction: column;
    font-size: large;
    line-height: 35px;
  }
  .frame-parent12 {
    width: 60%;
    grid-gap: 35px;
    gap: 35px;
    top: 5px;
    position: relative;
  }
  .group-switcher-wrapper {
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    left: 37px;
    position: relative;
  }
  .tabbar-segmented-group-mec {
    overflow: hidden;
  }
  .group-current-time-icon {
    width: 430px;
  }
  .group-mytasks {
    grid-gap: 60px;
    gap: 60px;
  }
  .frame-parent15 {
    padding: var(--padding-4xs) 0;
  }
  .mytasks-group-options {
    display: flex;
    flex-direction: flex-end;
  }
  .mytasks-view-vector {
    height: 100%;
    width: 2px;
    display: flex;
  }
  /* .skip-vector-icon1 {
    height: 640px;
    width: 2px;
    z-index: 2;
  } */
}
@media screen and (max-width: 720px) {
  .navbar-mec {
    grid-gap: var(--gap-6xl);
    gap: var(--gap-6xl);
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
  .frame-parent1 {
    flex-wrap: wrap;
  }
  .calendar-simple-parent {
    grid-gap: var(--gap-6xl);
    gap: var(--gap-6xl);
  }
  .group-tomorrow-parent,
  .line-wrapper {
    min-width: 100%;
  }
  .group-1 {
    flex-wrap: wrap;
  }
  .line-container {
    min-width: 100%;
  }
  .group-calendar-hour {
    flex-wrap: wrap;
  }
  .line-frame {
    min-width: 100%;
  }
  .group-calendar-hour1 {
    flex-wrap: wrap;
  }
  .line-wrapper1 {
    min-width: 100%;
  }
  .group-calendar-hour2 {
    flex-wrap: wrap;
  }
  .line-wrapper2 {
    min-width: 100%;
  }
  .group-calendar-hour3 {
    flex-wrap: wrap;
  }
  .line-wrapper3 {
    min-width: 100%;
  }
  .group-calendar-hour4 {
    flex-wrap: wrap;
  }
  .line-wrapper4 {
    min-width: 100%;
  }
  .group-calendar-hour5 {
    flex-wrap: wrap;
  }
  .line-wrapper5 {
    min-width: 100%;
  }
  .group-calendar-hour6 {
    flex-wrap: wrap;
  }
  .line-wrapper6 {
    min-width: 100%;
  }
  .group-calendar-hour7 {
    flex-wrap: wrap;
  }
  .line-wrapper7 {
    min-width: 100%;
  }
  .group-calendar-hour8 {
    flex-wrap: wrap;
  }
  .line-wrapper8 {
    min-width: 100%;
  }
  .group-calendar-hour9 {
    flex-wrap: wrap;
  }
  .line-wrapper9 {
    min-width: 100%;
  }
  .group-calendar-hour10 {
    flex-wrap: wrap;
  }
  .line-wrapper10 {
    min-width: 100%;
  }
  .group-calendar-hour11 {
    flex-wrap: wrap;
  }
  .line-wrapper11 {
    min-width: 100%;
  }
  .group-calendar-hour12 {
    flex-wrap: wrap;
  }
  .calendar-day {
    padding: var(--padding-2xl) var(--padding-2xl) var(--padding-8xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .frame-parent7 {
    grid-gap: var(--gap-6xl);
    gap: var(--gap-6xl);
  }
  .group-mytasks-inner {
    min-width: 100%;
  }
  .group-mytasks {
    grid-gap: var(--gap-23xl);
    gap: var(--gap-23xl);
    padding-top: var(--padding-xl);
    padding-bottom: 31px;
    box-sizing: border-box;
  }
  .frame-group {
    grid-gap: var(--gap-4xl);
    gap: var(--gap-4xl);
  }
  .frame-parent {
    grid-gap: var(--gap-2xl);
    gap: var(--gap-2xl);
  }
  .frame-parent18 {
    flex-wrap: wrap;
    grid-gap: 27px;
    gap: 27px;
    min-width: 100%;
  }
  .group-footer {
    grid-gap: var(--gap-6xl);
    gap: var(--gap-6xl);
  }
  .my-schedule-first-view-of-new {
    grid-gap: var(--gap-31xl);
    gap: var(--gap-31xl);
  }
  .mytasks-view-vector {
    height: 100%;
    width: 2px;
    display: flex;
  }
  /* .skip-vector-icon1 {
    height: 640px;
    width: 2px;
    z-index: 2;
  } */
}
@media screen and (max-width: 450px) {
  .textlabel-title,
  .textlabel-title1 {
    font-size: var(--font-size-lgi);
    line-height: 56px;
  }
  .frame-parent2 {
    flex-wrap: wrap;
  }
  .calendar-simple {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .textlabel-title2 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .textlabel-title-group {
    grid-gap: var(--gap-lgi);
    gap: var(--gap-lgi);
  }
  .group-upcomingmeetings {
    grid-gap: 16px;
    gap: 16px;
  }
  .textlabel-title3 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .textlabel-text10 {
    font-size: 30px;
    line-height: 12px;
  }
  .textlabel-text-wrapper1 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .view-rectangle-parent {
    flex-wrap: wrap;
    justify-content: center;
  }
  .textlabel-8 {
    font-size: var(--font-size-xl);
    line-height: 19px;
  }
  .textlabel-title4 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .frame-parent8 {
    flex-wrap: wrap;
  }
  .skip-bmec3 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .frame-parent9 {
    grid-gap: var(--gap-9xl);
    gap: var(--gap-9xl);
  }
  .group-tomorrow {
    padding-left: var(--padding-xl);
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-5xl);
    box-sizing: border-box;
  }
  .textlabel-title5 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .frame-parent10,
  .group-category,
  .group-category1,
  .group-category2 {
    flex-wrap: wrap;
  }
  .group-categories {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .group-tomorrow-parent {
    grid-gap: var(--gap-4xl);
    gap: var(--gap-4xl);
  }
  .textlabel-title6 {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
  .frame-parent12 {
    flex-wrap: wrap;
    grid-gap: var(--gap-mini);
    gap: var(--gap-mini);
  }
  .textlabel-title7 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .textinput-fields2 {
    flex-wrap: wrap;
    grid-gap: var(--gap-lgi);
    gap: var(--gap-lgi);
  }
  .frame-parent14 {
    grid-gap: 18px;
    gap: 18px;
  }
  .frame-parent13,
  .group-mytasks {
    grid-gap: var(--gap-2xl);
    gap: var(--gap-2xl);
  }
  .textlabel-title14,
  .textlabel-title19,
  .textlabel-title8 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .my-schedule-first-view-of-new {
    grid-gap: var(--gap-6xl);
    gap: var(--gap-6xl);
  }
}
@media screen and (min-width: 1200px) {
  .frame-container {
    padding-left: var(--padding-8xl);
    padding-right: var(--padding-8xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .textlabel-text10 {
    font-size: 40px;
    line-height: 16px;
  }
  .group-tomorrow-parent {
    flex: 1 1;
  }
  .frame-parent7 {
    flex-wrap: wrap;
  }
  .frame-parent13,
  .group-mytasks-inner {
    flex: 1 1;
  }
  .group-mytasks {
    flex-wrap: wrap;
    grid-gap: 85px;
    gap: 85px;
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
  }
  .frame-parent18,
  .frame-parent19 {
    flex: 1 1;
  }
  .frame-parent17,
  .frame-parent20 {
    flex-wrap: wrap;
  }
}

.inputWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  font-weight: 700;
  padding: 5px 10px;
  color: #ffffff40;
  transition: all 0.3s ease-in-out;
  cursor: text;
}

.input {
  width: 100%;
  font-size: 16px;
  font-weight: 300 !important;
  color: #ffffff;
  padding: 13px 20px 10px 28px;
  border: none;
  outline: none;
  border-radius: 20px;
  font-family: poppins;
  background-color: #111921;
  transition: all 0.3s ease-in-out;
}

/* Focus styles */
.input:focus {
  outline: none;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff50;
  background-color: transparent;
}

.input:not(:focus):valid {
  border: 0.5px solid #ffffff;
  background-color: transparent;
}

.input:-moz-placeholder-shown {
  border: 0.5px solid #484c50;
  background-color: #1e252c;
  padding-left: 110px; 
}

.input:placeholder-shown {
  border: 0.5px solid #484c50;
  background-color: #1e252c;
  padding-left: 110px; 
}

.input:focus + .label,
.input:not(:focus):valid + .label {
  transform: translateY(-33px) translateX(-3%) scale(0.8);
  color: #ffffff;
  background-color: #1e252c !important;
  background-image: none !important;
  padding: 0 5px;
}

.phoneInput::-moz-placeholder {
  font-family: 'Arial', sans-serif;
  font-size: 13px; 
  color: #B0BEC5; 
}

.phoneInput::placeholder {
  font-family: 'Arial', sans-serif;
  font-size: 13px; 
  color: #B0BEC5; 
}

.input:-moz-placeholder-shown + .label {
  transform: translateY(-33px) translateX(-3%) scale(0.8);
  color: #ffffff40;
  background-color: #1e252c !important;
  background-image: none !important;
  padding: 0 5px;
}

.input:placeholder-shown + .label {
  transform: translateY(-33px) translateX(-3%) scale(0.8);
  color: #ffffff40;
  background-color: #1e252c !important;
  background-image: none !important;
  padding: 0 5px;
}

@keyframes autofill {
  0%,
  100% {
    color: #fff;
    background: transparent;
  }
}

.input:-webkit-autofill {
  -webkit-animation-delay: 1s !important;
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both !important;
}

.input:autofill {
  animation-delay: 1s !important;
  animation-name: autofill !important;
  animation-fill-mode: both !important;
}

/* Checkbox styles */
.checkboxWrapper {
  width: 90%;
  align-self: center;
  display: flex;
  align-items: flex-start;
}

.termsText,
.termsLink {
  font-size: 0.95rem;
}

.termsText {
  font-weight: 400;
}

.termsLink {
  font-weight: 600;
  text-decoration: underline;
}

.checkboxLabel {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 8px;
  background-color: none;
  border: 0.5px solid #ffffff50;
}

/* On mouse-over, add a grey background color */
.checkboxLabel:hover input ~ .checkmark {
  background-color: #72efdd50;
  border: none;
}

/* When the checkbox is checked, add a blue background */
.checkboxLabel input:checked ~ .checkmark {
  background-color: #72efdd;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxLabel input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxLabel .checkmark:after {
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #1e252c;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/*  Create password page styles */
.passwordIconWrapper {
  position: absolute;
  top: 8px;
  right: 10px;
}

.eyeIcon {
  color: #a9a5a5;
  font-size: 1.4rem;
  transition: color 0.3s ease-in-out;
}

.input:focus ~ .eyeIcon {
  color: #ffffff;
}

.validationContainer {
  margin-left: 40px;
}

.validationWrapper {
  margin: 4px 0;
  display: flex;
  align-items: center;
}

.cirleIcon,
.checkIcon,
.dashIcon .validationText,
.notValid,
.isValid {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #ffffff;
}

.notValid {
  color: #f87171;
}

.isValid {
  color: #72efdd;
}

/* Creat country page styles */
.inputWrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(/static/media/skipArrow.992ff3ee.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}

.inputWrapper option {
  background-color: #1e252c;
}

.phoneInputWrapper {
  position: absolute;
  top: 0px;
  left: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  padding: 14px 0 11px 0;
}
.flag {
  width: 30px;
  height: 20px;
  border-radius: 8px;
  overflow: hidden;
}
.phoneInputWrapper span {
  color: #ffffff;
}
.phoneInput {
  padding-left: 140px;
  padding-right: 40px;
}
.divider {
  border: 0.5px solid #ffffff50;
  height: 15px;
  margin: 0 10px;
}
.input.phoneInput {
  padding-left: 110px;
}
.input.phoneInputuser {
  padding-left: 120px;
  font-family: 'Poppins', Arial, Helvetica, sans-serif!important;
  font-size: 15px!important;
  font-weight: 300;
}

.phoneInputWrapper .phoneCode {
  font-family: 'Poppins', Arial, Helvetica, sans-serif!important;
  font-size: 15px;
  font-weight: 300;
}

.phoneIconWrapper {
  position: absolute;
  top: 11px;
  right: 10px;
}

.phoneIcon {
  font-size: 1.3rem;
}

.typingWrapper {
  border: 1px solid #fef3c7;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13px;
}

.typing {
  font-size: 0.6rem;
  color: #fef3c7;
}

.validPhone {
  color: #72efdd;
}

.invalidPhone {
  color: #f87171;
}
.signupHeading{
  transform: translateX(0);
}

.heading3style {
  background-clip: text;
  background-image: linear-gradient(to right, #72efdd, #26c5f3 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 20px;
}

.heading2style {
  font-weight: 100 !important;
}

.headingTransform {
  transform: translateX(50px);
}


/* Media Queries */
@media screen and (min-width: 600px) {
  .headingTransform {
    transform: translateX(0);
  }
}
.terms-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-color: #050d15;
  overflow-x: hidden;
}

.bgEllipsis {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(/static/media/bgEllipsis.292f9f05.svg);
  background-repeat: no-repeat;
  background-position: left;
  z-index: 1;
}

.terms-wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #ffffff15;
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-items: center;
    padding: 120px 10px;
    position: relative;
}

.terms-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    position: relative;
}

.termsBtn {
    top: 9px;
    left: 0;
    z-index: 1;
}

.terms-heading {
    align-self: center !important;
    width: 100%;
    text-align: center;
}

.terms-content {
    width: 100%;
}

.term {
    margin-bottom: 30px;
}

.term:last-child {
    margin-bottom: 0;
}

.term .title {
    font-weight: 600 !important;
    font-size: 1.1rem;
    margin: 5px 0;
}

.term .desc {
    font-weight: 400 !important;
    font-size: .9rem;
    margin: 5px 0;
    line-height: 22px;
}

@media screen and (min-width: 400px) {
    .terms-wrapper {
        padding: 120px 30px;
    }
}

@media screen and (min-width: 600px) {
    .terms-wrapper {
        width: calc(100% - 50px);
        align-self: flex-end;
        padding: 120px 30px;
    }
    .term .title {
      font-size: 1.2rem;
    }
    .term .desc {
      font-size: 1rem;
    }
}


@media screen and (min-width: 1024px) {
    .bgEllipsis{
        left: 100px;
    }
    .terms-wrapper {
        padding: 120px 50px 50px;
    }
}

@media screen and (min-width: 1300px) {
    .terms-wrapper {
        padding: 120px 100px 50px;
    }
}

@media screen and (min-width: 1400px) {
    .terms-wrapper {
        padding: 120px 100px 50px;
    }
}

@media screen and (min-width: 1700px) {
    .terms-wrapper {
        padding: 120px 100px 50px;
    }
}
.desc-text {
  font-weight: 600 !important;
  margin-top: -20px !important;
}

.formcontent-wrapper,
.mdjourney-container,
.roles-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0px 5px;
  gap: 0px 5px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  font-family: "Raleway", sans-serif;
  z-index: 1;
  margin-top: 30px;
}

.suggestions-dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #0b0d33;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
}

.suggestion-item.highlighted {
  /* border: 2px solid;
  border-image: linear-gradient(90deg, #5591F5 0%, #6200EA 25%, #5591F5 50%);
  border-image-slice: 1; */
  background-color: #282e61; 
}

.suggestion-item {
  padding: 12px;
  cursor: pointer;
  color: #FFFFFF;
  border-width: 100%;
}
.item-text{
  margin-left: 30px;
}
.suggestion-item-line-bottom{
  border-bottom: 1px solid black;
}





.input {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 13px 20px 10px 28px;
  border: none;
  outline: none;
  border-radius: 20px;
  background-color: #111921;
  transition: all 0.3s ease-in-out;
}

.inputWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.suggestion-item:hover {height: "100%";

  /* border: 2px solid;
  border-image: linear-gradient(90deg, #5591F5 0%, #6200EA 25%, #5591F5 50%);
  border-image-slice: 1; */
  background-color: #282e61; 
}


.vertical-container {
  width: 100%;
  max-width: 280px;
 background-color: rgba(5, 13, 21, 0.5);
  border-radius: 20px;
  margin-bottom: 10px;
  transition: 0.3s all ease-in-out;
  position: relative;
}

.selected-container {
  background-color: #ffffff05;
  border: 2px solid grey;
  transition: 0.3s all ease-in-out;
}

.vertical-wrapper {
  max-width: 279px;
  background-color: #050d1560;
  border-radius: 20px;
  display: flex;
  align-items: center;
  transition: 0.3s all ease-in-out;
  position: relative;
}

.selected-wrapper {
  background-color: #050d1500;
  background-image: linear-gradient(to bottom, #050d1500, #050d1590);
  transition: 0.3s all ease-in-out;
}

.vertical-label,
.mdJourney-label,
.roles-label {
  font-weight: 700;
  font-size: 1rem;
  color: rgba(255,255,255,.6);
  transition: 0.3s all ease-in-out;
  width: 100%;
  padding: 14px 10px 14px 70px;
  z-index: 2;
  word-wrap: break-word;
  padding-right: 45px;
}

.goal-label {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 10px 14px 0;
  z-index: 2;
}

.vertical-icon,
.selected-icon,
.mdJourney-icon,
.roles-icon {
  transition: 0.3s all ease-in-out;
  position: absolute;
  left: 30px;
  z-index: 1;
}

.selected-label,
.mdJourney-selected-label,
.roles-selected-label,
.dropdown:focus {
  color: #ffffff;
  transition: 0.3s all ease-in-out;
}
.vertical-icon img{
  width: 16px;
  height: 20px;
}

.mdJourney-icon img,
.roles-icon img {
  width: 22px;
  height: 20px;
}

.selected-icon img,
.mdJourney-selected-icon img,
.roles-selected-icon img,
.dropdown:focus img,
.selected-goal-icon img {
  filter: grayscale(100%) brightness(1000%);
  transition: 0.3s all ease-in-out;
}

.radioBtn {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
  z-index: 2;
}

.mdjourney-container,
.roles-container {
  flex-direction: column;
}

.mdjourney-container span,
.roles-container span {
  position: absolute;
  left: 24px;
}

.mdJourney-wrapper,
.roles-wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 413px;
  background-color: #050d1550;
  border: 1px solid #ffffff00;
  border-radius: 24px;
  margin: 10px;
  transition: all 0.3s ease;
  position: relative;
}

.mdJourney-selected-wrapper,
.roles-selected-wrapper {
  border: 1px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff60;
  background-color: transparent;
  transition: all 0.3s ease;
}

.institution-container,
.goal-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.institution-wrapper,
.textarea-wrapper {
  width: 100%;
  max-width: 413px;
  position: relative;
  resize: none;
}

.dropdown,
.textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(/static/media/skipArrow.992ff3ee.svg);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 20px;
  width: 100%;
  background-color: #050d1570;
  border: 0.5px solid #ffffff00;
  border-radius: 24px;
  padding: 14px 30px 14px 50px;
  outline: none;
  color: #ffffff80;
  font-weight: 700;
  font-size: 1rem;
  transition: all 0.3s ease;
}


.textInput::-moz-placeholder {
  color: #FFFFFF;
  font-size: 16px;

  
}


.textInput::placeholder {
  color: #FFFFFF;
  font-size: 16px;

  
}

.institutionIcon {
  display: block;
  width: 23px;
  height: 20px;
  position: absolute;
  top: 27px;
  left: 20px;
  transition: all 0.3s ease;
}

.institution-icon {
  width: 100%;
  height: auto;
  margin-top: 5px;
}

.dropdown:focus,
.textarea:focus {
  outline: none;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff50;
  background-color: transparent;
  color: #ffffff;
}

.option {
  background-color: #ffffff;
  color: #050d15;
}

.textInput,
.textarea {
  margin-top: 20px;
  background-image: none;
  /* padding: 14px 24px; */
}

.contentContainerStyle {
  padding: 150px 0;
}

.goal-container {
  flex-direction: row;
  flex-wrap: wrap;
}
.goal-container  .MuiAccordion-root{
  width: 100%;
  flex-basis: 100%;
  max-width: 600px;
}

.goal-wrapper {
  width: 100%;
  background-color: #050d1570;
  border: 1px solid #ffffff00;
  border-radius: 24px;
  margin: 15px 0;
  color: #ffffff80;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

.selected-goal-wrapper {
  background: transparent;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff50;
  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
}

.goal-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  transform: translateX(20px);
}

.goal-icon {
  margin: 0 20px;
  display: block;
  z-index: 1;
}

.textarea {
  position: relative;
  padding: 20px 20px;
  display: flex;
  resize: none;
}

.textarea::-webkit-scrollbar {
  display: none;
}

.text-counter {
  color: #ffffff70;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: right;
  margin: 10px 30px;
}

.text-counter p {
  margin: 0;
  padding: 0;
}

.msg-container {
  display: flex;
  align-items: center;
}

.msg {
  -webkit-background-clip: text;
          background-clip: text;
  background-image: linear-gradient(to right, #72efdd, #26c5f3, #5591f5, #6a00f4, #b429f9);
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 900 !important;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 3px;
  padding: 100px 15px 0;
}

.gradientText {
  -webkit-background-clip: text;
          background-clip: text;
  background-image: linear-gradient(to right, #72efdd, #26c5f3, #5591f5, #6a00f4, #b429f9);
  color: #9b9ea1;
  font-size: 2.5rem;
  font-weight: 100 !important;
  -webkit-text-stroke-width: 3px;
}

.gradientText2 {
  background-image: linear-gradient(to right, #72efdd, #26c5f3, #5591f5);
}

.msg2 {
  background-image: linear-gradient(to right, #72efdd, #26c5f3, #5591f5);
  padding: 0;
}

.h3Style {
  background-clip: text;
  background-image: linear-gradient(to right, #72efdd, #26c5f3 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.progress-wrapper {
  align-self: center;
  width: 100%;
  height: 10px;
  max-width: 1000px;
  background-color: #a4a4b0;
  border-radius: 5px;
  position: relative;
  box-shadow: 5px 2px 14px #ffffff50;
}

.progress {
  position: absolute;
  top: -48px;
  left: 0;
  right: 0;
  transform: translate(-10px, -50%);
  background-image: url(/static/media/progressBg.72260830.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 52px;
  height: 30px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #6a00f4;
  margin: 50px 0 30px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.5) 100%);
}

.progress-bar::-moz-progress-bar {
  background: linear-gradient(to right, #72efdd, #26c5f3, #5591f5, #6a00f4, #b429f9);
}

.progress-bar::-webkit-progress-bar {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.5) 100%);
}

.progress-bar::-webkit-progress-value {
  background: linear-gradient(to right, #72efdd, #26c5f3, #5591f5, #6a00f4, #b429f9);
  border-radius: 10px;
}

.fact {
  align-self: center;
  background-image: url(/static/media/bgBlur.01ebae62.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 700px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  margin-top: 80px;
}

.fact p {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}

.bgImgStyle {
  position: absolute;
  width: 100%;
  left: 0;
  top: 60px;
  height: 40vh;
}

/* customReactSelect.css */
.custom-select__control {
  border-radius: 35px !important;
  padding: 7px !important;
  background-color: #0515147A !important;
  margin: 1px !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.custom-select__control:hover {
  border-color: transparent !important;
}

.custom-select__value-container {
  padding: 0 !important;
}

.custom-select__placeholder {
  color: white !important;
  margin-left: 60px !important;
}

.custom-select__input {
  color: white !important;
  margin-left: 50px !important;
}

.custom-select__single-value {
  margin-left: 50px !important;
  color: white !important;
}

.custom-select__menu {
  padding: 7px !important;
  background-color: #0515147A !important;
  margin: 1px !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.custom-select__option {
  background-color: #0515147A !important;
  color: white !important;
  padding: 7px !important;
  margin: 1px !important;
}

.custom-select__option--is-selected {
  background-color: #051514 !important;
}
.goal-container .MuiAccordion-root.Mui-expanded {
  margin: 0px 0px;
}
.goal-container .MuiAccordionSummary-root.Mui-expanded{
  min-height: 40px;
}
.goal-container .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0;
}
.goal-container  .MuiAccordionDetails-root{
  padding: 8px 16px 8px;
}
.goal-icon img{
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
     object-fit: contain;
}
.goal-icon{
  align-self: flex-start;
}
.back-btn{
  left: 0;
  background-color: rgba(255, 255, 255, 0.11);
}
.content-wrapper{
  padding-left: 0;
  padding-right: 0;
}
/* Media Queries */
@media screen and (min-width: 600px) {

  .formcontent-wrapper,
  .mdjourney-container,
  .roles-container {
    justify-content: space-between;
  }

  .desc-text {
    font-size: 1.5rem !important;
  }

  .bgImgStyle {
    top: 50px;
    height: 60vh;
  }

  .msg,
  .gradientText {
    font-size: 3rem;
  }
}

@media screen and (min-width: 650px) {
  .goal-wrapper {
    max-width: 600px;
  }
}
.MuiAccordion-root:before{
  display:none
}
@media screen and (min-width: 1024px) {
  .bgImgStyle {
    width: auto;
    height: auto;
    top: 10px;
    left: -150px;
  }

  .msg {
    font-size: 4.2rem;
    padding: 0 15px;
  }

  .gradientText {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1600px) {
  .bgImgStyle {
    top: 10px;
    left: -90px;
    width: auto;
  }
}
.bar-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 160px;
    max-width: 1000px;
    align-self: center;
    padding: 0 10px
}
.progressBarLine{
    position: absolute;
    top: 90px;
}
.progress-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.progress-circle,
.progress-circle-role,
.progress-circle-vertical,
.progress-circle-journey,
.progress-circle-institution,
.progress-circle-immediateGoal,
.progress-circle-futureGoal,
.progress-circle-active{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: '#26313F';
    background-image:linear-gradient(90deg, #26313F, #26313F);
    z-index: 2;    
    position: relative;  
}

.progress-circle-active{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #37CFEF, #27C5F4);
}

.progress-circle-role{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #37CFEF, #27C5F4);
}

.progress-circle-vertical{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #37CFEF, #27C5F4);
}
.progress-circle-journey{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #49A0F5, #4E9AF5);
}
.progress-circle-institution{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #6141F5, #6332F5);
}
.progress-circle-immediateGoal{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #8811F7, #9015F7);
}
.progress-circle-futureGoal{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #C429DF, #CB28D3);
}
.progress-line,
.progress-line-role,
.progress-line-vertical,
.progress-line-journey,
.progress-line-institution,
.progress-line-immediateGoal,
.progress-line-futureGoal
.progress-line-active{
    height: 6px;
    flex-grow: 1;
    background-image:linear-gradient(90deg, #26313F, #26313F);
    z-index: 1;  
    margin: 0 -1px;
    position: relative;  
}

.progress-line-active{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #2AC8F2, #4B9DF5);
}

.progress-line-role{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #2AC8F2, #4B9DF5);
}
.progress-line-vertical{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #2AC8F2, #4B9DF5);
}
.progress-line-journey{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #4B9DF5, #6338F5);
}
.progress-line-institution{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #6239F5, #8C13F7);
}
.progress-line-immediateGoal{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #8C13F7, #C828D9);
}
.progress-line-start, .progress-line-end {
    width: auto;
    background-color: #26313F;
    height: 6px;
    align-self: center;
    position: relative;  
    z-index: 1;    
}
.progressBarLine .progress-line-start, .progressBarLine .progress-line-end {  
    flex-basis: 100%;
}

.progress-line-start {
    border-radius: 5px 0 0 5px;
    background-image: linear-gradient(90deg, #72EFDE, #33CDF0);
 }
 .progressBarLine .progress-line-start.lastLine{
    border-radius: 0 5px 5px 0;
    border: 0px solid rgba(51, 65, 85, .5);
    background-color: rgba(51, 65, 85, .5);
 }
 .progressBarLine .progress-line-start.lastLine.progress-line-active{
    background-color: #72EFDE;
    background-image: linear-gradient(90deg, #72EFDE, #33CDF0);
 }
 .progress-line-end{
     background-image:linear-gradient(90deg, #C828D9, #DD0AF3);
 }
 .progress-circle.circleBlue{
    border: 6px solid #334155;
    width: auto;
    min-width: 20px;
 }
 .progress-circle-active.circleBlue{
    width: auto;
    min-width: 20px;
 }
 .progress-circle-active.circleBlue{
    border: 0px solid #72EFDD;
    background-color: #72EFDD;
    background-image: linear-gradient(90deg, #72EFDD, #72EFDE);
 }

 .progressBarLine .progress-line-start {
    border-radius: 0;
    border: 0px solid rgba(51, 65, 85, .5);
    background-color: rgba(51, 65, 85, .5);
    background-image: linear-gradient(90deg, #334155, #334159);
  }
  .progressBarLine .progress-line-start:first-of-type{
    border-radius: 5px 0 0 5px;
 }
 .progress-line-start.progress-circle-active.circleBlue::before{
  width: 100%;
  height: 6px;
  background-color: rgba(114, 239, 221, 1);
content: "";
 }
 .progress-line-start.progress-circle-active.circleBlue:first-of-type::before{
    border-radius: 5px 0 0 5px;
 }
 .progress-line-start.next-btn-visible.circleBlue{
    width: 100%;
    height: 6px;
    background-color: rgba(114, 239, 221, 1);
  content: "";
   }
span.progress-circle.next-btn-visible.circleBlue{
  
   border: 6px solid rgba(114, 239, 221, 1);
   
   }
   div.progress-circle.next-btn-visible.circleBlue{
  
    border: 0px solid rgba(114, 239, 221, 1);
    background-image: linear-gradient(90deg, #72EFDD, #72EFDE);
    }
 .progress-circle.circleBlue svg{
    display: none;
 }
 .progress-circle-active.circleBlue svg{
    width: 10px;
    height: 10px;
 }
 .progress-circle.circleBlue.checkOn svg{
    width: 10px;
    height: 10px;
    display: inline;
}
span.progress-circle.circleBlue.checkOn{
 
    border: 0px solid rgba(114, 239, 221, 1);
  background-color: rgba(114, 239, 221, 1);
  background-image: linear-gradient(90deg, #72EFDD, #72EFDE);
}

 @media screen and (min-width: 320px) {
    .bar-container {
        padding: 0 20px;
    }
 }

 @media screen and (min-width: 600px) {
    .bar-container {
        padding: 0 30px;
    }
 }


 @media screen and (min-width: 1024px) {
    .bar-container {
        padding: 0;
    }
 }
/* Video Player Container */
.video-player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Button Container */
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  /* Individual Buttons */
  .button-container .mr-3 {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .button-container .mr-3:hover {
    background-color: #0056b3;
  }
  
  .button-container .mr-3:focus {
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.7);
  }
  
  /* Player Container */
  .video-react-player {
    width: 100%;
    max-width: 720px;
    height: auto;
    margin-bottom: 20px;
    padding-top: 0px !important;
  }
  
  /* State Display */
  .state-display {
    font-family: "Courier New", Courier, monospace;
    background-color: #2d2d2d;
    color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    max-width: 90%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  
  /* Custom Styling for Specific Components */
  .video-react-control-bar {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .video-react-play-control {
    color: #ffffff;
  }
  
  .video-react-progress-control {
    background-color: #888888;
  }
  
  .video-react-fullscreen-control {
    color: #ffffff;
  }
  
  .video-react-current-time-display,
  .video-react-duration-display {
    color: #ffffff;
  }
  
